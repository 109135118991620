/* ----------------------------------------

Table of Contents:
- Common
  - animations
  - base
  - .document
  - .fake-svg-icon
  - .page-header
  - .site
- Components
  - .account-nav
  - .alert
  - .badge
  - .block-header
  - .breadcrumb
  - .btn
  - .card
  - .card-table
  - .filters-button
  - forms
  - .input-check
  - .input-check-color
  - .input-number
  - .input-radio
  - .input-radio-color
  - .input-radio-label
  - .layout-switcher
  - .modal
  - .nouislider
  - .pagination
  - .post-card
  - .product-card
  - .product-image
  - .rating
  - .select2
  - .share-links
  - .social-links
  - .tags
  - .tooltip
  - .typography
- Filters
  - .filter
  - .filter-categories
  - .filter-categories-alt
  - .filter-color
  - .filter-list
  - .filter-price
- Header
  - .account-menu
  - .departments
  - .dropcart
  - .indicator
  - .megamenu
  - .menu
  - .mobile-header
  - .mobile-links
  - .mobilemenu
  - .nav-links
  - .nav-panel
  - .search
  - .search--location--header
  - .search--location--indicator
  - .search--location--mobile-header
  - .site-header
  - .suggestions
  - .topbar
  - .topbar-dropdown
  - .topbar-link
- Footer
  - .footer-contacts
  - .footer-links
  - .footer-newsletter
  - .site-footer
  - .totop
- Pages
  - .about-us
  - .checkout
  - .contact-us
  - .faq
  - .not-found
- Shop
  - .address-card
  - .addresses-list
  - .cart
  - .cart-table
  - .category-card
  - .compare-table
  - .dashboard
  - .order-header
  - .order-list
  - .order-success
  - .product
  - .product-gallery
  - .product-tabs
  - .products-list
  - .products-view
  - .profile-card
  - .quickview
  - .review
  - .reviews-list
  - .reviews-view
  - .shop-layout
  - .spec
  - .view-options
  - .wishlist
- Blog
  - .comment
  - .comments-list
  - .post
  - .post-author
  - .post-header
  - .posts-list
  - .posts-view
  - .related-posts
- Blocks
  - .block
  - .block-banner
  - .block-brands
  - .block-categories
  - .block-empty
  - .block-features
  - .block-finder
  - .block-map
  - .block-posts
  - .block-product-columns
  - .block-products
  - .block-products-carousel
  - .block-sidebar
  - .block-slideshow
- Widgets
  - .widget
  - .widget-aboutus
  - .widget-categories
  - .widget-comments
  - .widget-filters
  - .widget-newsletter
  - .widget-posts
  - .widget-products
  - .widget-search
  - .widget-tags

---------------------------------------- */
/* ----------------------------------------
// Common
// ---------------------------------------- */
/*
// animations
*/
@keyframes preloader-animation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

/*
// base
*/
html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
  font-family: "Roboto", "sans-serif";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background: #fff;
  color: #3d464d;
  backface-visibility: hidden;
  overflow-y: scroll;
}

[dir=ltr] body {
  text-align: left;
  direction: ltr;
}

[dir=rtl] body {
  text-align: right;
  direction: rtl;
}

svg {
  display: block;
}

a {
  color: #3366cc;
}

a:hover {
  color: #3366cc;
  text-decoration: none;
}

label {
  margin-bottom: .25rem;
}

h1 {
  font-size: 36px;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  font-weight: 700;
}

h3 {
  font-size: 28px;
  font-weight: 700;
}

h4 {
  font-size: 24px;
  font-weight: 700;
}

h5 {
  font-size: 20px;
  font-weight: 700;
}

h6 {
  font-size: 16px;
  font-weight: 700;
}

/*
// .document
*/
.document {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 85px 200px 120px;
}

.document__header {
  text-align: center;
  margin-bottom: 80px;
}

.document__subtitle {
  margin-top: 12px;
  font-size: 14px;
  line-height: 22px;
  color: #999;
}

@media (max-width: 1199px) {
  .document {
    padding: 75px 110px 110px;
  }
  .document__header {
    margin-bottom: 60px;
  }
}

@media (max-width: 991px) {
  .document {
    padding: 45px 35px 60px;
  }
  .document__header {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .document {
    padding: 35px 30px 50px;
  }
  .document__header {
    margin-bottom: 30px;
  }
  .document__title {
    font-size: 30px;
    margin-bottom: 0;
  }
  .document__subtitle {
    margin-top: 6px;
  }
}

@media (max-width: 575px) {
  .document {
    padding: 26px 20px 40px;
  }
  [dir=ltr] .document__header {
    text-align: left;
  }
  [dir=rtl] .document__header {
    text-align: right;
  }
}

/*
// .fake-svg-icon
*/
/* this needs only to avoid slow performance on Microsoft Edge while rendering huge amount of svg*/
.fake-svg-icon {
  font-family: 'stroyka' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
  vertical-align: top;
}

.fake-svg-icon--wishlist-16 {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.fake-svg-icon--wishlist-16::before {
  content: "\e904";
}

.fake-svg-icon--compare-16 {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.fake-svg-icon--compare-16::before {
  content: "\e901";
}

/*
// .page-header
*/
.page-header__container {
  padding-bottom: 32px;
}

.page-header__breadcrumb {
  padding-top: 16px;
}

.page-header__title {
  padding-top: 6px;
}

.page-header__title h1 {
  margin-bottom: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .page-header__title {
    padding-top: 4px;
  }
  .page-header__title h1 {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .page-header__container {
    padding-bottom: 24px;
  }
  .page-header__title {
    padding-top: 2px;
  }
  .page-header__title h1 {
    margin-bottom: 0;
    font-size: 30px;
  }
}

/*
// .site
*/
.site {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.site__header {
  flex-shrink: 0;
}

.site__body {
  flex-grow: 1;
}

.site__footer {
  flex-shrink: 0;
}

/* ----------------------------------------
// Components
// ---------------------------------------- */
/*
// .account-nav
*/
.account-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.account-nav__item a {
  display: block;
  color: #6c757d;
  font-size: 15px;
}

.account-nav__item a:hover {
  background-color: #f7f7f7;
}

@media (min-width: 992px) {
  .account-nav {
    border: 2px solid #f0f0f0;
    border-radius: 2px;
  }
  .account-nav ul {
    padding-bottom: 18px;
  }
  .account-nav__title {
    font-size: 20px;
    padding: 24px 26px 0;
    margin-bottom: 1rem;
  }
  .account-nav__item a {
    padding: 5px 26px;
  }
  [dir=ltr] .account-nav__item a {
    margin-left: -2px;
    border-left: 2px solid #f0f0f0;
  }
  [dir=rtl] .account-nav__item a {
    margin-right: -2px;
    border-right: 2px solid #f0f0f0;
  }
  .account-nav__item--active a {
    color: #3d464d;
    font-weight: 500;
  }
  [dir=ltr] .account-nav__item--active a {
    border-left: 2px solid #3366cc;
  }
  [dir=rtl] .account-nav__item--active a {
    border-right: 2px solid #3366cc;
  }
}

@media (max-width: 991px) {
  .account-nav__title {
    display: none;
  }
  .account-nav {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .account-nav ul {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
    border-bottom: 2px solid #f0f0f0;
  }
  .account-nav__item a {
    padding: 8px 16px;
    margin-bottom: -2px;
    border-bottom: 2px solid #f0f0f0;
  }
  .account-nav__item--active a {
    color: #3d464d;
    font-weight: 500;
    border-color: #3366cc;
  }
}

/*
// .alert
*/
.alert {
  border: none;
  border-radius: 2px;
  font-size: 15px;
  line-height: 20px;
  padding: .75rem 1rem;
}

.alert a {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  color: currentColor;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1.5px;
  padding: 0 3px;
}

.alert a:hover {
  background: rgba(255, 255, 255, 0.85);
}

.alert a:active {
  background: rgba(255, 255, 255, 0.5);
}

.alert-primary {
  background: #b2d9ff;
  color: #36414d;
}

.alert-secondary {
  background: #d9d9d9;
  color: #4d4d4d;
}

.alert-info {
  background: #b8e2e5;
  color: #2d3e40;
}

.alert-success {
  background: #bde5a1;
  color: #34402d;
}

.alert-danger {
  background: #ffb8b8;
  color: #473333;
}

.alert-warning {
  background: #f2e291;
  color: #4d472e;
}

.alert-lg {
  font-size: 16px;
  padding: 1.125rem 1.375rem;
}

[dir=ltr] .alert-dismissible {
  padding-right: 3rem;
}

[dir=rtl] .alert-dismissible {
  padding-left: 3rem;
}

.alert-dismissible .close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;
  transition: all .2s;
  fill: rgba(0, 0, 0, 0.3);
  background: transparent;
}

[dir=ltr] .alert-dismissible .close {
  left: auto;
  right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 1.5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 1.5px;
}

[dir=rtl] .alert-dismissible .close {
  right: auto;
  left: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 1.5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 1.5px;
}

.alert-dismissible .close:focus {
  outline: none;
}

.alert-dismissible .close:not(:disabled):not(.disabled):focus,
.alert-dismissible .close:not(:disabled):not(.disabled):hover {
  background: rgba(0, 0, 0, 0.08);
  fill: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

/*
// .badge
*/
.badge {
  border-radius: 1.5px;
  font-weight: 500;
  padding: .25em .5em;
}

/*
// .block-header
*/
.block-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.block-header__title {
  margin-bottom: 0;
  font-size: 20px;
}

.block-header__divider {
  flex-grow: 1;
  height: 2px;
  background: #ebebeb;
}

.block-header__groups-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

[dir=ltr] .block-header__groups-list li + li {
  margin-left: 3px;
}

[dir=rtl] .block-header__groups-list li + li {
  margin-right: 3px;
}

.block-header__group {
  background: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  display: block;
  color: inherit;
  padding: 2px 11px 1px;
  border-radius: 12px;
  box-shadow: none;
}

.block-header__group:focus {
  outline: none;
}

.block-header__group:focus, .block-header__group:hover {
  box-shadow: 0 0 0 2px #ebebeb inset;
  color: inherit;
}

.block-header__group--active, .block-header__group--active:focus, .block-header__group--active:hover {
  cursor: default;
  box-shadow: 0 0 0 2px #3366cc inset;
}

.block-header__arrows-list {
  display: flex;
}

.block-header__arrow {
  width: 27px;
  height: 29px;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: #999;
  border-radius: 1.5px;
  background: #f2f2f2;
  transition: all .15s;
}

.block-header__arrow:focus {
  outline: none;
}

.block-header__arrow:focus, .block-header__arrow:hover {
  background: #3366cc;
  fill: #fff;
}

.block-header__arrow:active {
  transition-duration: 0s;
  background: #547ed4;
  fill: #fff;
}

[dir=ltr] .block-header__arrow + .block-header__arrow {
  margin-left: 3px;
}

[dir=rtl] .block-header__arrow + .block-header__arrow {
  margin-right: 3px;
}

[dir=ltr] .block-header__arrow svg {
  transform: scaleX(1);
}

[dir=rtl] .block-header__arrow svg {
  transform: scaleX(-1);
}

.block-header__arrow--left svg {
  position: relative;
}

[dir=ltr] .block-header__arrow--left svg {
  left: -1px;
}

[dir=rtl] .block-header__arrow--left svg {
  right: -1px;
}

.block-header__arrow--right svg {
  position: relative;
}

[dir=ltr] .block-header__arrow--right svg {
  left: 1px;
}

[dir=rtl] .block-header__arrow--right svg {
  right: 1px;
}

[dir=ltr] .block-header__title + .block-header__divider {
  margin-left: 16px;
}

[dir=rtl] .block-header__title + .block-header__divider {
  margin-right: 16px;
}

[dir=ltr] .block-header__divider + .block-header__groups-list {
  margin-left: 12px;
}

[dir=rtl] .block-header__divider + .block-header__groups-list {
  margin-right: 12px;
}

[dir=ltr] .block-header__groups-list + .block-header__arrows-list {
  margin-left: 10px;
}

[dir=rtl] .block-header__groups-list + .block-header__arrows-list {
  margin-right: 10px;
}

[dir=ltr] .block-header__divider + .block-header__arrows-list {
  margin-left: 16px;
}

[dir=rtl] .block-header__divider + .block-header__arrows-list {
  margin-right: 16px;
}

@media (max-width: 767px) {
  .block-header {
    display: flex;
    flex-wrap: wrap;
  }
  .block-header__groups-list {
    padding: 8px 0;
    margin-top: 8px;
    margin-bottom: -8px;
    order: 100;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  [dir=ltr] .block-header__divider + .block-header__groups-list {
    margin-left: auto;
    margin-right: auto;
  }
  [dir=rtl] .block-header__divider + .block-header__groups-list {
    margin-right: auto;
    margin-left: auto;
  }
  [dir=ltr] .block-header__divider + .block-header__groups-list + .block-header__arrows-list {
    margin-left: 16px;
  }
  [dir=rtl] .block-header__divider + .block-header__groups-list + .block-header__arrows-list {
    margin-right: 16px;
  }
}

/*
// .breadcrumb
*/
.breadcrumb {
  background: transparent;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
}

.breadcrumb-item {
  position: relative;
}

[dir=ltr] .breadcrumb-item {
  padding-left: 0;
  padding-right: 30px;
}

[dir=rtl] .breadcrumb-item {
  padding-right: 0;
  padding-left: 30px;
}

.breadcrumb-item a {
  color: inherit;
  transition: color .15s;
}

.breadcrumb-item a:hover {
  color: #3366cc;
}

[dir=ltr] .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
  padding-right: 30px;
}

[dir=rtl] .breadcrumb-item + .breadcrumb-item {
  padding-right: 0;
  padding-left: 30px;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}

.breadcrumb-item.active {
  color: #999;
}

.breadcrumb-arrow {
  fill: #d1d1d1;
  position: absolute;
  top: 5px;
}

[dir=ltr] .breadcrumb-arrow {
  right: 11px;
  transform: scaleX(1);
}

[dir=rtl] .breadcrumb-arrow {
  left: 11px;
  transform: scaleX(-1);
}

/*
// .btn
*/
.btn {
  border-radius: 2px;
  transition: all 0.1s ease-in-out;
  font-size: 1rem;
  height: calc(2.25rem + 2px);
  line-height: 1.5;
  padding: .375rem 1.25rem;
  font-weight: 500;
}

.btn.btn-svg-icon {
  width: calc(2.25rem + 2px);
}

.btn-lg {
  font-size: 1.125rem;
  height: calc(2.75rem + 2px);
  padding: .8125rem 2rem;
  line-height: 1;
  font-weight: 500;
}

.btn-lg.btn-svg-icon {
  width: calc(2.75rem + 2px);
}

.btn-xl {
  font-size: 1.375rem;
  height: calc(3.625rem + 2px);
  padding: 1.125rem 3rem;
  line-height: 1;
  font-weight: 500;
}

.btn-xl.btn-svg-icon {
  width: calc(3.625rem + 2px);
}

.btn-sm {
  font-size: .875rem;
  height: calc(1.875rem + 2px);
  line-height: 1.25;
  padding: .375rem 1rem;
  font-weight: 500;
}

.btn-sm.btn-svg-icon {
  width: calc(1.875rem + 2px);
}

.btn-xs {
  font-size: .8125rem;
  height: calc(1.5rem + 2px);
  line-height: 1.25;
  padding: .25rem .5625rem;
  font-weight: 500;
}

.btn-xs.btn-svg-icon {
  width: calc(1.5rem + 2px);
}

.btn:disabled,
.btn.disabled {
  cursor: default;
}

.btn-svg-icon {
  padding: 0;
  position: relative;
}

.btn-svg-icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  [dir=ltr] .btn-svg-icon svg {
    transform: translate(-50%, -50%);
  }
  [dir=rtl] .btn-svg-icon svg {
    transform: translate(50%, -50%);
  }
}

/* this needs only to avoid slow performance on Microsoft Edge while rendering huge amount of svg*/
.btn-svg-icon--fake-svg .fake-svg-icon {
  transition: all 0.1s ease-in-out;
  display: none;
}

@supports (-ms-ime-align: auto) {
  .btn-svg-icon--fake-svg svg {
    display: none;
  }
  .btn-svg-icon--fake-svg .fake-svg-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    align-items: center;
    justify-content: center;
  }
}

.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
  border-color: #3d464d;
  background: #3d464d;
  color: #fff;
  fill: #fff;
}

.btn-primary.focus .fake-svg-icon, .btn-primary:focus .fake-svg-icon, .btn-primary:hover .fake-svg-icon {
  color: #fff;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  border-color: #546069;
  background: #546069;
  color: #fff;
  fill: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active .fake-svg-icon, .btn-primary:not(:disabled):not(.disabled).active .fake-svg-icon {
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
  border-color: #3366cc;
  background: #3366cc;
  color: #fff;
  fill: #fff;
}

.btn-primary .fake-svg-icon, .btn-primary.disabled .fake-svg-icon, .btn-primary:disabled .fake-svg-icon {
  color: #fff;
}

.btn-primary.btn-loading:not(:disabled):not(.disabled), .btn-primary.btn-loading:not(:disabled):not(.disabled):active, .btn-primary.btn-loading:not(:disabled):not(.disabled).active {
  cursor: default;
  border-color: #3366cc;
  background: #3366cc;
}

.btn-secondary.focus, .btn-secondary:focus, .btn-secondary:hover {
  border-color: #3d464d;
  background: #3d464d;
  color: #fff;
  fill: #fff;
}

.btn-secondary.focus .fake-svg-icon, .btn-secondary:focus .fake-svg-icon, .btn-secondary:hover .fake-svg-icon {
  color: #fff;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  border-color: #546069;
  background: #546069;
  color: #fff;
  fill: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active .fake-svg-icon, .btn-secondary:not(:disabled):not(.disabled).active .fake-svg-icon {
  color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.btn-secondary, .btn-secondary.disabled, .btn-secondary:disabled {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #3d464d;
  fill: #3d464d;
}

.btn-secondary .fake-svg-icon, .btn-secondary.disabled .fake-svg-icon, .btn-secondary:disabled .fake-svg-icon {
  color: #3d464d;
}

.btn-secondary.btn-loading:not(:disabled):not(.disabled), .btn-secondary.btn-loading:not(:disabled):not(.disabled):active, .btn-secondary.btn-loading:not(:disabled):not(.disabled).active {
  cursor: default;
  border-color: #f0f0f0;
  background: #f0f0f0;
}

.btn-light.focus, .btn-light:focus, .btn-light:hover {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #3d464d;
  fill: #3d464d;
}

.btn-light.focus .fake-svg-icon, .btn-light:focus .fake-svg-icon, .btn-light:hover .fake-svg-icon {
  color: #3d464d;
}

.btn-light.focus, .btn-light:focus {
  box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active {
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #3d464d;
  fill: #3d464d;
}

.btn-light:not(:disabled):not(.disabled):active .fake-svg-icon, .btn-light:not(:disabled):not(.disabled).active .fake-svg-icon {
  color: #3d464d;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.btn-light, .btn-light.disabled, .btn-light:disabled {
  border-color: transparent;
  background: transparent;
  color: #3d464d;
  fill: #ccc;
}

.btn-light .fake-svg-icon, .btn-light.disabled .fake-svg-icon, .btn-light:disabled .fake-svg-icon {
  color: #ccc;
}

.btn-light.btn-loading:not(:disabled):not(.disabled), .btn-light.btn-loading:not(:disabled):not(.disabled):active, .btn-light.btn-loading:not(:disabled):not(.disabled).active {
  cursor: default;
  border-color: transparent;
  background: transparent;
}

/* loading state */
.btn-loading {
  position: relative;
  /* hide button content */
  /* nl size (default) */
  /* xl size */
  /* lg size */
  /* sm size */
  /* xs size */
}

.btn-loading, .btn-loading:hover, .btn-loading.focus, .btn-loading:focus, .btn-loading:not(:disabled):not(.disabled).active, .btn-loading:not(:disabled):not(.disabled):active, .btn-loading.disabled, .btn-loading:disabled {
  color: transparent;
  fill: transparent;
}

.btn-loading:after {
  display: block;
  content: '';
  position: absolute;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  transition: border-color .1s;
  animation-name: preloader-animation;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.btn-loading:after {
  left: calc(50% - 11px);
  top: calc(50% - 11px);
  width: 22px;
  height: 22px;
  border-radius: 11px;
}

.btn-loading.btn-xl:after {
  left: calc(50% - 21px);
  top: calc(50% - 21px);
  width: 42px;
  height: 42px;
  border-radius: 21px;
}

.btn-loading.btn-lg:after {
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  border-radius: 14px;
}

.btn-loading.btn-sm:after {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.btn-loading.btn-xs:after {
  left: calc(50% - 7px);
  top: calc(50% - 7px);
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

/*
// .card
*/
.card {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
}

.card-body {
  padding: 2rem;
}

.card-header,
.card-footer {
  padding: 1.5rem 2rem;
  border-top: none;
  border-bottom: none;
  background-color: transparent;
}

.card-header h1, .card-header h2, .card-header h3, .card-header h4, .card-header h5, .card-header h6,
.card-footer h1,
.card-footer h2,
.card-footer h3,
.card-footer h4,
.card-footer h5,
.card-footer h6 {
  margin-bottom: 0;
}

.card-title {
  margin-bottom: 1.75rem;
}

.card-title h1, .card-title h2, .card-title h3, .card-title h4, .card-title h5, .card-title h6 {
  margin-bottom: 0;
}

.card-divider {
  height: 2px;
  background: #f0f0f0;
}

.card-body .card-title:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .card-header,
  .card-footer {
    padding: 1.25rem 1.5rem;
  }
  .card-title {
    font-size: 26px;
    margin-bottom: 1.5rem;
  }
  .card-body {
    padding: 1.5rem;
  }
}

@media (max-width: 767px) {
  .card-header,
  .card-footer {
    padding: 1rem 1.375rem;
  }
  .card-title {
    font-size: 26px;
    margin-bottom: 1.5rem;
  }
  .card-body {
    padding: 1.375rem;
  }
}

/*
// .card-table
*/
.card-table {
  font-size: 15px;
}

.card-table a:not(.btn):hover {
  text-decoration: underline;
}

.card-table table {
  width: 100%;
  min-width: 500px;
}

[dir=ltr] .card-table table {
  text-align: left;
}

[dir=rtl] .card-table table {
  text-align: right;
}

.card-table thead th {
  border-bottom: 2px solid #f0f0f0;
  text-transform: uppercase;
  color: #6c757d;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 12px;
}

.card-table tbody th,
.card-table tbody td,
.card-table tfoot th,
.card-table tfoot td {
  padding: 12px 12px;
}

.card-table tfoot th,
.card-table tfoot td {
  padding-top: 16px;
  padding-bottom: 16px;
}

.card-table tbody + tbody tr th,
.card-table tbody + tbody tr td,
.card-table tbody + tfoot tr th,
.card-table tbody + tfoot tr td,
.card-table tbody tr + tr th,
.card-table tbody tr + tr td {
  border-top: 1px solid #f0f0f0;
}

.card-table tbody tr:hover > *,
.card-table tfoot tr:hover > * {
  background-color: #f7f7f7;
}

[dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
  padding-left: 2rem;
}

[dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
  padding-right: 2rem;
}

[dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
  padding-right: 2rem;
}

[dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
  padding-left: 2rem;
}

.card-table .card-table__body--merge-rows th,
.card-table .card-table__body--merge-rows td {
  padding-top: 3px;
  padding-bottom: 3px;
}

.card-table .card-table__body--merge-rows tr:first-child th,
.card-table .card-table__body--merge-rows tr:first-child td {
  padding-top: 12px;
}

.card-table .card-table__body--merge-rows tr:last-child th,
.card-table .card-table__body--merge-rows tr:last-child td {
  padding-bottom: 12px;
}

.card-table .card-table__body--merge-rows tr + tr th,
.card-table .card-table__body--merge-rows tr + tr td {
  border-top: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .card-table {
    font-size: 14px;
  }
  [dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
    padding-left: 1.5rem;
  }
  [dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
    padding-left: 1.5rem;
  }
}

@media (max-width: 767px) {
  .card-table {
    font-size: 14px;
  }
  [dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
    padding-left: 1.375rem;
  }
  [dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
    padding-right: 1.375rem;
  }
  [dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
    padding-right: 1.375rem;
  }
  [dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
    padding-left: 1.375rem;
  }
}

/*
// .filters-button
*/
.filters-button {
  display: flex;
  align-items: center;
  padding: 0 9px;
  height: 32px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: inherit;
}

.filters-button:focus {
  outline: none;
}

.filters-button:hover {
  background: #f5f5f5;
  border-color: #e5e5e5;
}

.filters-button__icon {
  fill: #3d464d;
}

[dir=ltr] .filters-button__icon {
  margin-right: 8px;
}

[dir=rtl] .filters-button__icon {
  margin-left: 8px;
}

.filters-button__counter {
  display: block;
  height: 20px;
  font-size: 13px;
  font-weight: 500;
  padding: 2px 5px 0;
  border-radius: 2px;
  line-height: 16px;
  background: #3366cc;
  color: #fff;
}

[dir=ltr] .filters-button__counter {
  margin-left: 12px;
  margin-right: -4px;
}

[dir=rtl] .filters-button__counter {
  margin-right: 12px;
  margin-left: -4px;
}

/*
// forms
*/
.form-group {
  margin-bottom: 1.5rem;
}

[dir=ltr] .form-check {
  padding-left: 1.5rem;
}

[dir=rtl] .form-check {
  padding-right: 1.5rem;
}

.form-check-input {
  position: absolute;
  margin-top: .1875rem;
}

[dir=ltr] .form-check-input {
  margin-left: -1.5rem;
}

[dir=rtl] .form-check-input {
  margin-right: -1.5rem;
}

@media (max-width: 767px) {
  .form-group {
    margin-bottom: 1.125rem;
  }
}

.form-control {
  border-radius: 2px;
  line-height: 1.25;
}

.form-control-sm {
  height: calc(1.875rem + 2px);
  line-height: 1.25;
  padding: .3125rem .5rem;
}

.form-control-lg {
  height: calc(2.75rem + 2px);
  line-height: 1.25;
}

.form-control:valid,
.form-control.is-valid,
.form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.form-control::-ms-expand {
  display: none;
}

select.form-control,
select.form-control:valid,
select.form-control.is-valid,
select.form-control:invalid,
select.form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='%234d4d4d' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 5px 10px;
}

[dir=ltr] select.form-control, [dir=ltr]
select.form-control:valid, [dir=ltr]
select.form-control.is-valid, [dir=ltr]
select.form-control:invalid, [dir=ltr]
select.form-control.is-invalid {
  background-position: right 0.75rem center;
  padding-right: calc(.75rem * 2 + 5px);
}

[dir=rtl] select.form-control, [dir=rtl]
select.form-control:valid, [dir=rtl]
select.form-control.is-valid, [dir=rtl]
select.form-control:invalid, [dir=rtl]
select.form-control.is-invalid {
  background-position: left 0.75rem center;
  padding-left: calc(.75rem * 2 + 5px);
}

[dir=ltr] select.form-control-sm, [dir=ltr]
select.form-control-sm:valid, [dir=ltr]
select.form-control-sm.is-valid, [dir=ltr]
select.form-control-sm:invalid, [dir=ltr]
select.form-control-sm.is-invalid {
  background-position: right 0.5rem center;
  padding-right: calc(.5rem * 2 + 5px);
}

[dir=rtl] select.form-control-sm, [dir=rtl]
select.form-control-sm:valid, [dir=rtl]
select.form-control-sm.is-valid, [dir=rtl]
select.form-control-sm:invalid, [dir=rtl]
select.form-control-sm.is-invalid {
  background-position: left 0.5rem center;
  padding-left: calc(.5rem * 2 + 5px);
}

[dir=ltr] select.form-control-lg, [dir=ltr]
select.form-control-lg:valid, [dir=ltr]
select.form-control-lg.is-valid, [dir=ltr]
select.form-control-lg:invalid, [dir=ltr]
select.form-control-lg.is-invalid {
  background-position: right 1rem center;
  padding-right: calc(1rem * 2 + 5px);
}

[dir=rtl] select.form-control-lg, [dir=rtl]
select.form-control-lg:valid, [dir=rtl]
select.form-control-lg.is-valid, [dir=rtl]
select.form-control-lg:invalid, [dir=rtl]
select.form-control-lg.is-invalid {
  background-position: left 1rem center;
  padding-left: calc(1rem * 2 + 5px);
}

/*
// .input-check
*/
.input-check {
  display: inline-block;
}

.input-check__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

.input-check__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.input-check__box {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 1.2px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  transition: background 0.15s, box-shadow 0.15s;
}

.input-check__icon {
  pointer-events: none;
  position: absolute;
  left: 4px;
  top: 5px;
  fill: #fff;
  transform: scale(0);
  transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s;
}

.input-check__input:checked ~ .input-check__box {
  background: #3366cc;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
}

.input-check__input:checked ~ .input-check__icon {
  fill: #fff;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1);
}

.input-check__input:disabled ~ .input-check__box {
  background: #f2f2f2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.input-check__input:disabled ~ .input-check__icon {
  fill: rgba(0, 0, 0, 0.2);
}

/*
// .input-check-color
*/
.input-check-color {
  display: inline-block;
}

.input-check-color__body {
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 1.2px;
  overflow: hidden;
  cursor: inherit;
  margin: 0;
}

.input-check-color__input {
  position: absolute;
  visibility: hidden;
}

.input-check-color__box {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 1.2px;
  background: currentColor;
  cursor: inherit;
}

.input-check-color__icon {
  position: absolute;
  left: 5px;
  top: 6px;
  fill: #fff;
  transform: scale(0);
  transition: transform 0.15s;
}

.input-check-color__input:checked ~ .input-check-color__icon {
  transform: scale(1);
}

.input-check-color__input:disabled {
  cursor: default;
}

.input-check-color__input:disabled ~ .input-check-color__box {
  position: relative;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
  opacity: .5;
  cursor: default;
}

.input-check-color__input:disabled ~ .input-check-color__stick {
  pointer-events: none;
  display: block;
  position: absolute;
  width: 2px;
  border-radius: 1px;
  height: 34px;
  left: calc(50% - 1px);
  top: -6px;
  background: rgba(102, 102, 102, 0.9);
  transform: rotateZ(45deg);
  transform-origin: center center;
}

.input-check-color--light .input-check-color__icon {
  fill: #3d464d;
}

.input-check-color--white .input-check-color__box {
  box-shadow: 0 0 0 2px #d9d9d9 inset;
}

.input-check-color--white .input-check-color__icon {
  fill: #bfbfbf;
}

/*
// .input-number
*/
.input-number {
  display: block;
  width: 100%;
  position: relative;
}

.input-number__input {
  -moz-appearance: textfield;
  display: block;
  width: 100%;
  min-width: 88px;
  padding: 0 24px 1px;
  text-align: center;
}

.input-number__input::-webkit-inner-spin-button, .input-number__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-number__sub,
.input-number__add {
  position: absolute;
  height: calc(100% - 2px);
  width: 24px;
  top: 1px;
  cursor: pointer;
  user-select: none;
  opacity: .3;
  transition: opacity .18s;
}

.input-number__sub:before, .input-number__sub:after,
.input-number__add:before,
.input-number__add:after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: currentColor;
}

.input-number__sub:before,
.input-number__add:before {
  width: 8px;
  height: 2px;
}

.input-number__sub:hover,
.input-number__add:hover {
  opacity: 1;
}

.input-number__sub:active,
.input-number__add:active {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.05);
}

[dir=ltr] .input-number__sub {
  left: 1px;
}

[dir=rtl] .input-number__sub {
  right: 1px;
}

[dir=ltr] .input-number__add {
  right: 1px;
}

[dir=rtl] .input-number__add {
  left: 1px;
}

.input-number__add:after {
  width: 2px;
  height: 8px;
}

/*
// .input-radio
*/
.input-radio {
  display: inline-block;
}

.input-radio__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

.input-radio__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.input-radio__circle {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.17) inset;
  transition: background 0.2s, box-shadow 0.2s;
}

.input-radio__circle::after {
  display: block;
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #fff;
  transform: scale(0);
  transition: background 0.2s, transform 0.2s, visibility 0s 0.2s;
  visibility: hidden;
}

.input-radio__input:checked ~ .input-radio__circle {
  background: #3366cc;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset;
}

.input-radio__input:checked ~ .input-radio__circle::after {
  background: #fff;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1);
}

.input-radio__input:disabled ~ .input-radio__circle {
  background: #f2f2f2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
}

.input-radio__input:disabled ~ .input-radio__circle::after {
  background: rgba(0, 0, 0, 0.2);
}

/*
// .input-radio-color
*/
.input-radio-color {
  display: block;
}

.input-radio-color__list {
  padding: 1px 0;
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
}

.input-radio-color__item {
  width: 30px;
  height: 30px;
  display: block;
  margin: 2px;
  cursor: pointer;
}

.input-radio-color__item input {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}

.input-radio-color__item span {
  position: relative;
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 15px;
  background: currentColor;
  transform: scale(0.73333);
  transition: transform .2s;
}

.input-radio-color__item span:before, .input-radio-color__item span:after {
  display: block;
  position: absolute;
  content: '';
  border-radius: 1000px;
}

.input-radio-color__item span:before {
  left: 3px;
  top: 3px;
  width: 24px;
  height: 24px;
  background: #fff;
  transform: scale(0);
  transition: transform .2s;
}

.input-radio-color__item span:after {
  left: 8px;
  top: 8px;
  width: 14px;
  height: 14px;
  background: currentColor;
  transform: scale(0);
  transition: transform .2s;
}

.input-radio-color__item:hover input:enabled ~ span {
  transform: scale(0.86667);
}

.input-radio-color__item input:enabled:checked ~ span,
.input-radio-color__item input:enabled:checked ~ span:before,
.input-radio-color__item input:enabled:checked ~ span:after {
  transform: scale(1);
}

.input-radio-color__item--disabled {
  cursor: default;
}

.input-radio-color__item--disabled span {
  transform: scale(0.75);
}

.input-radio-color__item--disabled span:before {
  transform: scale(1.33) rotateZ(45deg);
  left: 14px;
  top: -1px;
  width: 2px;
  height: calc(100% + 2px);
  background: rgba(0, 0, 0, 0.3);
}

.input-radio-color__item--white span {
  box-shadow: inset 0 0 0 2px #ccc;
}

.input-radio-color__item--white span:after {
  box-shadow: inset 0 0 0 2px #ccc;
}

/*
// .input-radio-label
*/
.input-radio-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.input-radio-label__list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}

.input-radio-label__list input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.input-radio-label__list label {
  display: flex;
  margin: 3px;
  user-select: none;
}

.input-radio-label__list span {
  cursor: pointer;
  display: block;
  height: 32px;
  padding: .3125rem 1rem;
  border-radius: 2px;
  background: #f0f0f0;
  color: #3d464d;
  transition: background-color .2s, color .2s;
}

.input-radio-label__list input:not(:checked):not(:disabled) ~ span:hover {
  background: #e0e0e0;
  color: #3d464d;
}

.input-radio-label__list input:checked ~ span {
  cursor: default;
  background: #3366cc;
  color: #fff;
  transition-duration: .08s, .08s;
}

.input-radio-label__list input:disabled ~ span {
  cursor: default;
  opacity: .5;
}

/*
// .layout-switcher
*/
.layout-switcher__list {
  display: flex;
}

.layout-switcher__button {
  border: 1px solid #e5e5e5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  height: 32px;
  border-radius: 0;
  background: #fff;
  fill: #a1abb3;
}

.layout-switcher__button:focus {
  outline: none;
}

.layout-switcher__button:hover {
  border-color: #e5e5e5;
  background: #f2f2f2;
  fill: #3d464d;
}

[dir=ltr] .layout-switcher__button:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

[dir=rtl] .layout-switcher__button:first-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

[dir=ltr] .layout-switcher__button:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

[dir=rtl] .layout-switcher__button:last-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

[dir=ltr] .layout-switcher__button + .layout-switcher__button {
  margin-left: -1px;
}

[dir=rtl] .layout-switcher__button + .layout-switcher__button {
  margin-right: -1px;
}

.layout-switcher__button--active,
.layout-switcher__button--active:hover {
  cursor: default;
  border-color: #3366cc;
  background: #3366cc;
  fill: #fff;
  z-index: 1;
}

/*
// .modal
*/
.modal {
  -webkit-overflow-scrolling: touch;
}

.modal-content {
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.15);
}

/*
// .nouislider
*/
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

.noUi-target {
  height: 16px;
}

.noUi-base {
  padding-top: 5px;
}

.noUi-connects {
  background: #e0e0e0;
  border-radius: 3px;
  height: 6px;
}

.noUi-connect {
  border-radius: 2px;
  background: #3366cc;
}

.noUi-horizontal .noUi-handle {
  cursor: ew-resize;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #3366cc;
  box-shadow: 0 0 0 0 rgba(51, 102, 204, 0);
  transition: box-shadow .2s;
}

.noUi-horizontal .noUi-handle:after {
  top: 0;
  display: block;
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  margin: 5px;
  border-radius: 3px;
  background: #fff;
  transition: transform .2s;
}

.noUi-horizontal .noUi-handle:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(51, 102, 204, 0.3);
}

.noUi-horizontal .noUi-handle:focus:after {
  transform: scale(0);
}

.noUi-horizontal .noUi-handle {
  top: 0px;
}

[dir=ltr] .noUi-horizontal .noUi-handle {
  left: auto;
  right: -8px;
}

[dir=rtl] .noUi-horizontal .noUi-handle {
  right: auto;
  left: -8px;
}

/*
// .pagination
*/
.pagination {
  margin-bottom: 0;
}

.page-link {
  font-weight: 500;
  border-radius: 2px;
  border: none;
  line-height: 1;
  margin: 0 2px;
  padding: 9px 10px 8px;
  height: 33px;
  background: transparent;
  color: #3d464d;
  cursor: pointer;
  user-select: none;
}

.page-link:hover,
.page-link:focus {
  box-shadow: none;
  background: #ebebeb;
  color: #3d464d;
}

.page-link--with-arrow {
  width: 29px;
  position: relative;
}

.page-link--with-arrow .page-link__arrow {
  position: absolute;
  fill: currentColor;
  top: 10px;
}

[dir=ltr] .page-link--with-arrow .page-link__arrow {
  transform: scaleX(1);
}

[dir=rtl] .page-link--with-arrow .page-link__arrow {
  transform: scaleX(-1);
}

[dir=ltr] .page-link--with-arrow .page-link__arrow--left {
  left: 10px;
}

[dir=rtl] .page-link--with-arrow .page-link__arrow--left {
  right: 10px;
}

[dir=ltr] .page-link--with-arrow .page-link__arrow--right {
  left: 11px;
}

[dir=rtl] .page-link--with-arrow .page-link__arrow--right {
  right: 11px;
}

.page-item:first-child .page-link {
  border-radius: 2px;
}

[dir=ltr] .page-item:first-child .page-link {
  margin-left: 0;
}

[dir=rtl] .page-item:first-child .page-link {
  margin-right: 0;
}

.page-item:last-child .page-link {
  border-radius: 2px;
}

[dir=ltr] .page-item:last-child .page-link {
  margin-right: 0;
}

[dir=rtl] .page-item:last-child .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  background: #3366cc;
  color: #fff;
  cursor: default;
  z-index: 2;
}

.page-item.disabled .page-link {
  background: transparent;
  color: #b3b3b3;
}

/*
// .post-card
*/
.post-card__image {
  position: relative;
  overflow: hidden;
  border-radius: 1.5px;
}

.post-card__image img {
  max-width: 100%;
}

.post-card__image ::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity .6s;
  z-index: 1;
}

.post-card__image:hover ::before {
  opacity: 1;
}

.post-card__category {
  font-size: 14px;
  color: #adadad;
}

.post-card__category a {
  color: inherit;
  transition: .15s;
}

.post-card__category a:hover {
  color: #3366cc;
}

.post-card__date {
  font-size: 14px;
  color: #adadad;
}

.post-card__name {
  font-size: 20px;
  line-height: 1.125;
  font-weight: 500;
}

.post-card__name a {
  color: inherit;
  transition: .15s;
}

.post-card__name a:hover {
  color: #3366cc;
}

.post-card__content {
  font-size: 15px;
  line-height: 24px;
}

.post-card--layout--related .post-card__name {
  width: 100%;
  font-size: 17px;
  margin-top: 16px;
  line-height: 24px;
}

.post-card--layout--related .post-card__name a {
  transition: all .2s;
}

.post-card--layout--related .post-card__name a:hover {
  color: #3366cc;
}

.post-card--layout--related .post-card__date {
  margin-top: 8px;
  font-size: 15px;
}

@media (max-width: 767px) {
  .post-card--layout--related .post-card__name {
    margin-top: 12px;
    font-size: 15px;
    line-height: 20px;
  }
  .post-card--layout--related .post-card__date {
    margin-top: 4px;
    font-size: 14px;
  }
}

.post-card--layout--grid .post-card__info {
  display: flex;
  flex-wrap: wrap;
}

.post-card--layout--grid .post-card__image {
  margin-bottom: 14px;
}

.post-card--layout--grid .post-card__category {
  display: none;
}

.post-card--layout--grid .post-card__date {
  order: -1;
}

.post-card--layout--grid .post-card__name {
  width: 100%;
  font-size: 18px;
  margin-top: 4px;
  line-height: 22px;
}

.post-card--layout--grid .post-card__content {
  width: 100%;
  margin-top: 12px;
}

.post-card--layout--grid .post-card__read-more {
  width: 100%;
  display: none;
}

.post-card--layout--grid.post-card--size--lg .post-card__image {
  margin-bottom: 0;
  border-radius: 3px;
}

.post-card--layout--grid.post-card--size--lg .post-card__info {
  padding: 28px 36px 12px;
}

.post-card--layout--grid.post-card--size--lg .post-card__category {
  font-size: 15px;
  order: -2;
  display: block;
}

.post-card--layout--grid.post-card--size--lg .post-card__date {
  font-size: 15px;
  position: relative;
}

[dir=ltr] .post-card--layout--grid.post-card--size--lg .post-card__date {
  padding-left: 22px;
}

[dir=rtl] .post-card--layout--grid.post-card--size--lg .post-card__date {
  padding-right: 22px;
}

.post-card--layout--grid.post-card--size--lg .post-card__date::before {
  top: 9px;
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: currentColor;
}

[dir=ltr] .post-card--layout--grid.post-card--size--lg .post-card__date::before {
  left: 9px;
}

[dir=rtl] .post-card--layout--grid.post-card--size--lg .post-card__date::before {
  right: 9px;
}

.post-card--layout--grid.post-card--size--lg .post-card__name {
  margin-top: 8px;
  font-size: 24px;
  line-height: 32px;
}

.post-card--layout--grid.post-card--size--lg .post-card__content {
  margin-top: 20px;
  font-size: 16px;
}

.post-card--layout--grid.post-card--size--lg .post-card__read-more {
  display: block;
  margin-top: 18px;
}

@media (max-width: 767px) {
  .post-card--layout--grid.post-card--size--lg .post-card__info {
    padding: 20px 20px 12px;
  }
  .post-card--layout--grid.post-card--size--lg .post-card__name {
    font-size: 22px;
    line-height: 26px;
  }
  .post-card--layout--grid.post-card--size--lg .post-card__content {
    margin-top: 12px;
  }
}

@media (max-width: 479px) {
  .post-card--layout--grid.post-card--size--lg .post-card__info {
    padding: 12px 12px 12px;
  }
  .post-card--layout--grid.post-card--size--lg .post-card__name {
    font-size: 20px;
    line-height: 22px;
  }
  .post-card--layout--grid.post-card--size--lg .post-card__content {
    margin-top: 10px;
    font-size: 15px;
  }
}

@media (max-width: 379px) {
  .post-card--layout--grid.post-card--size--lg .post-card__name {
    margin-top: 4px;
  }
  .post-card--layout--grid.post-card--size--lg .post-card__info {
    padding: 10px 0;
  }
}

.post-card--layout--list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.post-card--layout--list.post-card--size--nl .post-card__info {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}

.post-card--layout--list.post-card--size--nl .post-card__image {
  width: 350px;
  flex-shrink: 0;
}

[dir=ltr] .post-card--layout--list.post-card--size--nl .post-card__image {
  margin-right: 30px;
}

[dir=rtl] .post-card--layout--list.post-card--size--nl .post-card__image {
  margin-left: 30px;
}

.post-card--layout--list.post-card--size--nl .post-card__category {
  font-size: 15px;
  order: -2;
  display: block;
}

.post-card--layout--list.post-card--size--nl .post-card__date {
  order: -1;
  font-size: 15px;
  position: relative;
}

[dir=ltr] .post-card--layout--list.post-card--size--nl .post-card__date {
  padding-left: 22px;
}

[dir=rtl] .post-card--layout--list.post-card--size--nl .post-card__date {
  padding-right: 22px;
}

.post-card--layout--list.post-card--size--nl .post-card__date::before {
  top: 9px;
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: currentColor;
}

[dir=ltr] .post-card--layout--list.post-card--size--nl .post-card__date::before {
  left: 9px;
}

[dir=rtl] .post-card--layout--list.post-card--size--nl .post-card__date::before {
  right: 9px;
}

.post-card--layout--list.post-card--size--nl .post-card__name {
  margin-top: 6px;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
}

.post-card--layout--list.post-card--size--nl .post-card__content {
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
}

.post-card--layout--list.post-card--size--nl .post-card__read-more {
  display: block;
  margin-top: 14px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .post-card--layout--list.post-card--size--nl .post-card__info {
    padding-top: 0;
  }
  .post-card--layout--list.post-card--size--nl .post-card__image {
    width: 260px;
    flex-shrink: 0;
  }
  [dir=ltr] .post-card--layout--list.post-card--size--nl .post-card__image {
    margin-right: 22px;
  }
  [dir=rtl] .post-card--layout--list.post-card--size--nl .post-card__image {
    margin-left: 22px;
  }
  .post-card--layout--list.post-card--size--nl .post-card__content {
    margin-top: 8px;
    font-size: 16px;
    overflow: hidden;
    height: 48px;
  }
  .post-card--layout--list.post-card--size--nl .post-card__read-more {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .post-card--layout--list.post-card--size--nl {
    flex-direction: column;
  }
  .post-card--layout--list.post-card--size--nl .post-card__image {
    width: auto;
  }
  [dir=ltr] .post-card--layout--list.post-card--size--nl .post-card__image {
    margin-right: 0;
  }
  [dir=rtl] .post-card--layout--list.post-card--size--nl .post-card__image {
    margin-left: 0;
  }
  .post-card--layout--list.post-card--size--nl .post-card__content {
    margin-top: 10px;
  }
}

.post-card--layout--list.post-card--size--sm .post-card__image {
  width: 240px;
  flex-shrink: 0;
}

[dir=ltr] .post-card--layout--list.post-card--size--sm .post-card__image {
  margin-right: 22px;
}

[dir=rtl] .post-card--layout--list.post-card--size--sm .post-card__image {
  margin-left: 22px;
}

.post-card--layout--list.post-card--size--sm .post-card__name {
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
  order: -1;
  height: 40px;
  overflow: hidden;
}

.post-card--layout--list.post-card--size--sm .post-card__date {
  margin-top: 6px;
}

.post-card--layout--list.post-card--size--sm .post-card__category {
  display: none;
}

.post-card--layout--list.post-card--size--sm .post-card__content {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
  height: 66px;
  overflow: hidden;
}

.post-card--layout--list.post-card--size--sm .post-card__read-more {
  display: none;
}

/*
// .product-card
*/
.product-card {
  position: relative;
  padding: 1px;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.product-card::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  border-radius: 2px;
  box-shadow: 0 0 0 1px #ededed inset;
  transition: box-shadow .2s;
}

.product-card:hover::before {
  transition-duration: 0s;
  box-shadow: 0 0 0 2px #e5e5e5 inset;
  z-index: 2;
}

.product-card:hover .product-card__quickview {
  transition-duration: 0s, 0s;
  background: #e5e5e5;
}

.product-card:hover .product-card__quickview:not(.product-card__quickview--preload) {
  color: #3d464d;
}

.product-card:hover .product-card__quickview:not(.product-card__quickview--preload):hover {
  transition-duration: .2s, .2s;
  background: #3d464d;
  color: #fff;
}

.product-card__image {
  padding: 18px;
  flex-shrink: 0;
}

.product-card__quickview {
  z-index: 2;
  position: absolute;
  border: none;
  font-size: 10px;
  text-transform: uppercase;
  fill: currentColor;
  padding: 7px;
  cursor: pointer;
  top: 0;
  background: transparent;
  color: #ccc;
  transition: background-color .2s, color .2s;
  /* this needs only to avoid slow performance on Microsoft Edge while rendering huge amount of svg */
}

[dir=ltr] .product-card__quickview {
  right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 0;
}

[dir=rtl] .product-card__quickview {
  left: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}

.product-card__quickview .fake-svg-icon {
  display: none;
}

@supports (-ms-ime-align: auto) {
  .product-card__quickview svg {
    display: none;
  }
  .product-card__quickview .fake-svg-icon {
    display: block;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
  .product-card__quickview .fake-svg-icon::before {
    content: "\e900";
  }
}

.product-card__quickview:focus {
  outline: none;
}

.product-card__quickview--preload {
  color: transparent;
}

.product-card__quickview--preload:after {
  display: block;
  content: '';
  position: absolute;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  transition: border-color .1s;
  animation-name: preloader-animation;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.product-card__info {
  padding: 2px 22px 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.product-card__name {
  font-size: 15px;
  line-height: 19px;
}

.product-card__name a {
  color: inherit;
  transition: .15s;
}

.product-card__name a:hover {
  color: #3366cc;
}

.product-card__rating {
  margin-top: 3px;
  display: flex;
  flex-wrap: wrap;
}

.product-card__rating-stars,
.product-card__rating-legend {
  margin-top: 3px;
}

[dir=ltr] .product-card__rating-stars {
  margin-right: 8px;
}

[dir=rtl] .product-card__rating-stars {
  margin-left: 8px;
}

.product-card__rating-legend {
  font-size: 13px;
  line-height: 1;
  color: #b3b3b3;
  padding-top: 1px;
  white-space: nowrap;
}

.product-card__prices {
  margin-top: 14px;
  line-height: 1;
  font-weight: 700;
  color: #3d464d;
}

.product-card__new-price {
  color: #ff2626;
}

.product-card__old-price {
  color: #b3b3b3;
  font-weight: 400;
  font-size: 13px;
  text-decoration: line-through;
}

.product-card__buttons {
  display: flex;
  margin-top: 24px;
}

[dir=ltr] .product-card__buttons > *:first-child {
  margin-right: auto;
}

[dir=rtl] .product-card__buttons > *:first-child {
  margin-left: auto;
}

[dir=ltr] .product-card__buttons > * + * {
  margin-left: 4px;
}

[dir=rtl] .product-card__buttons > * + * {
  margin-right: 4px;
}

.product-card__addtocart--list {
  display: none;
}

.product-card__features-list {
  list-style: none;
  margin: 10px 0 6px;
  padding: 0;
  color: #6c757d;
  font-size: 13px;
  line-height: 15px;
}

.product-card__features-list li {
  padding: 3px 0 3px;
  position: relative;
}

[dir=ltr] .product-card__features-list li {
  padding-left: 12px;
}

[dir=rtl] .product-card__features-list li {
  padding-right: 12px;
}

.product-card__features-list li::before {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  border: 1px solid currentColor;
}

[dir=ltr] .product-card__features-list li::before {
  left: 0;
}

[dir=rtl] .product-card__features-list li::before {
  right: 0;
}

.product-card__badges-list {
  top: 18px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}

[dir=ltr] .product-card__badges-list {
  left: 18px;
}

[dir=rtl] .product-card__badges-list {
  right: 18px;
}

.product-card__badge {
  font-size: 11px;
  border-radius: 1.5px;
  letter-spacing: .02em;
  line-height: 1;
  padding: 5px 8px 4px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;
}

[dir=ltr] .product-card__badge {
  margin-right: 4px;
}

[dir=rtl] .product-card__badge {
  margin-left: 4px;
}

.product-card__badge--sale {
  background: #ff3333;
  color: #fff;
}

.product-card__badge--new {
  background: #3377ff;
  color: #fff;
}

.product-card__badge--hot {
  background: #614ba6;
  color: #fff;
}

.product-card--layout--horizontal {
  flex-direction: row;
}

.product-card--layout--horizontal .product-card__image {
  width: 120px;
  padding: 16px;
}

.product-card--layout--horizontal .product-card__badges-list {
  top: 10px;
}

[dir=ltr] .product-card--layout--horizontal .product-card__badges-list {
  left: 10px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__badges-list {
  right: 10px;
}

.product-card--layout--horizontal .product-card__badge {
  padding: 3px 5px 2px;
}

.product-card--layout--horizontal .product-card__info {
  padding-top: 14px;
  padding-bottom: 42px;
  width: 100%;
}

[dir=ltr] .product-card--layout--horizontal .product-card__info {
  padding-left: 0;
  padding-right: 30px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__info {
  padding-right: 0;
  padding-left: 30px;
}

.product-card--layout--horizontal .product-card__name {
  overflow: hidden;
  max-height: 38px;
}

.product-card--layout--horizontal .product-card__rating {
  white-space: nowrap;
}

.product-card--layout--horizontal .product-card__description {
  display: none;
}

.product-card--layout--horizontal .product-card__availability {
  display: none;
}

.product-card--layout--horizontal .product-card__buttons {
  display: none;
}

.product-card--layout--horizontal .product-card__actions {
  position: absolute;
  bottom: 18px;
}

[dir=ltr] .product-card--layout--horizontal .product-card__actions {
  left: 120px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__actions {
  right: 120px;
}

.product-card--layout--horizontal .product-card__features-list {
  display: none;
}

.product-card--layout--grid .product-card__image {
  padding: 18px 18px 20px;
}

.product-card--layout--grid .product-card__info {
  padding: 0 24px 0;
}

.product-card--layout--grid .product-card__actions {
  padding: 0 24px 24px;
}

.product-card--layout--grid .product-card__description {
  display: none;
}

.product-card--layout--grid .product-card__availability {
  display: none;
}

.product-card--layout--grid .product-card__features-list {
  display: none;
}

.product-card--layout--grid.product-card--size--sm .product-card__image {
  padding: 15px;
}

.product-card--layout--grid.product-card--size--sm .product-card__badges-list {
  top: 16px;
}

[dir=ltr] .product-card--layout--grid.product-card--size--sm .product-card__badges-list {
  left: 16px;
}

[dir=rtl] .product-card--layout--grid.product-card--size--sm .product-card__badges-list {
  right: 16px;
}

.product-card--layout--grid.product-card--size--sm .product-card__info {
  padding: 0 15px 0;
}

.product-card--layout--grid.product-card--size--sm .product-card__actions {
  padding: 0 15px 15px;
}

.product-card--layout--grid.product-card--size--sm .product-card__buttons .btn {
  font-size: .8125rem;
  height: calc(1.5rem + 2px);
  line-height: 1.25;
  padding: .25rem .5625rem;
  font-weight: 500;
}

.product-card--layout--grid.product-card--size--sm .product-card__buttons .btn.btn-svg-icon {
  width: calc(1.5rem + 2px);
}

@media (hover: hover) {
  .product-card--layout--grid.product-card--size--sm--hidden-actions .product-card__buttons {
    display: none;
  }
  .product-card--layout--grid.product-card--size--sm--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .product-card--layout--grid.product-card--size--sm--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product-card--layout--grid.product-card--size--sm--hidden-actions .product-card__buttons {
    display: none;
  }
  .product-card--layout--grid.product-card--size--sm--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .product-card--layout--grid.product-card--size--sm--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

.product-card--layout--grid.product-card--size--nl .product-card__buttons .btn {
  font-size: .875rem;
  height: calc(1.875rem + 2px);
  line-height: 1.25;
  padding: .375rem 1rem;
  font-weight: 500;
}

.product-card--layout--grid.product-card--size--nl .product-card__buttons .btn.btn-svg-icon {
  width: calc(1.875rem + 2px);
}

@media (hover: hover) {
  .product-card--layout--grid.product-card--size--nl--hidden-actions .product-card__buttons {
    display: none;
  }
  .product-card--layout--grid.product-card--size--nl--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .product-card--layout--grid.product-card--size--nl--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product-card--layout--grid.product-card--size--nl--hidden-actions .product-card__buttons {
    display: none;
  }
  .product-card--layout--grid.product-card--size--nl--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .product-card--layout--grid.product-card--size--nl--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

.product-card--layout--grid.product-card--size--lg .product-card__image {
  padding: 25px;
}

.product-card--layout--grid.product-card--size--lg .product-card__info {
  padding: 0 30px 0;
  flex-grow: 0;
}

.product-card--layout--grid.product-card--size--lg .product-card__name {
  font-size: 17px;
  line-height: 23px;
}

.product-card--layout--grid.product-card--size--lg .product-card__actions {
  padding: 0 30px 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.product-card--layout--grid.product-card--size--lg .product-card__rating {
  margin-top: 7px;
}

.product-card--layout--grid.product-card--size--lg .product-card__prices {
  margin-bottom: auto;
  font-size: 20px;
}

.product-card--layout--list {
  flex-direction: row;
}

.product-card--layout--list .product-card__image {
  flex-shrink: 0;
  padding: 24px;
  width: 210px;
}

.product-card--layout--list .product-card__info {
  padding: 20px 0;
}

[dir=ltr] .product-card--layout--list .product-card__info {
  padding-left: 0;
  padding-right: 20px;
}

[dir=rtl] .product-card--layout--list .product-card__info {
  padding-right: 0;
  padding-left: 20px;
}

.product-card--layout--list .product-card__name {
  font-size: 16px;
  line-height: 20px;
  flex-grow: 0;
}

.product-card--layout--list .product-card__rating {
  margin-top: 4px;
}

.product-card--layout--list .product-card__description {
  color: #6c757d;
  font-size: 15px;
  line-height: 22px;
  margin-top: 12px;
}

.product-card--layout--list .product-card__actions {
  flex-shrink: 0;
  width: 190px;
  padding: 16px 20px;
}

[dir=ltr] .product-card--layout--list .product-card__actions {
  border-left: 1px solid #ebebeb;
}

[dir=rtl] .product-card--layout--list .product-card__actions {
  border-right: 1px solid #ebebeb;
}

.product-card--layout--list .product-card__prices {
  margin-top: 16px;
  font-size: 18px;
}

.product-card--layout--list .product-card__old-price {
  font-size: 14px;
}

.product-card--layout--list .product-card__buttons {
  flex-wrap: wrap;
}

.product-card--layout--list .product-card__addtocart {
  display: none;
}

.product-card--layout--list .product-card__addtocart--list {
  display: block;
}

.product-card--layout--list .product-card__addtocart {
  width: 100%;
}

[dir=ltr] .product-card--layout--list .product-card__addtocart + * {
  margin-left: 0;
}

[dir=rtl] .product-card--layout--list .product-card__addtocart + * {
  margin-right: 0;
}

.product-card--layout--list .product-card__addtocart ~ * {
  margin-top: 8px;
}

.product-card--layout--list .product-card__addtocart,
.product-card--layout--list .product-card__wishlist,
.product-card--layout--list .product-card__compare {
  font-size: .875rem;
  height: calc(1.875rem + 2px);
  line-height: 1.25;
  padding: .375rem 1rem;
  font-weight: 500;
}

.product-card--layout--list .product-card__addtocart.btn-svg-icon,
.product-card--layout--list .product-card__wishlist.btn-svg-icon,
.product-card--layout--list .product-card__compare.btn-svg-icon {
  width: calc(1.875rem + 2px);
}

.product-card--layout--list .product-card__availability {
  color: #999;
  font-size: 14px;
}

[dir=ltr] .product-card--layout--list .product-card__availability {
  padding-right: 10px;
}

[dir=rtl] .product-card--layout--list .product-card__availability {
  padding-left: 10px;
}

.product-card--layout--list .product-card__features-list {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
}

.product-card--layout--list .product-card__features-list li {
  padding: 3px 0;
}

[dir=ltr] .product-card--layout--list .product-card__features-list li {
  padding-left: 12px;
  padding-right: 0;
}

[dir=rtl] .product-card--layout--list .product-card__features-list li {
  padding-right: 12px;
  padding-left: 0;
}

.product-card--layout--list .product-card__features-list li::before {
  top: 8px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-card--layout--list .product-card__image {
    width: 180px;
  }
  [dir=ltr] .product-card--layout--list .product-card__image {
    padding-left: 20px;
    padding-right: 20px;
  }
  [dir=rtl] .product-card--layout--list .product-card__image {
    padding-right: 20px;
    padding-left: 20px;
  }
  [dir=ltr] .product-card--layout--list .product-card__info {
    padding-left: 4px;
    padding-right: 20px;
  }
  [dir=rtl] .product-card--layout--list .product-card__info {
    padding-right: 4px;
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .product-card--layout--list {
    flex-direction: column;
  }
  .product-card--layout--list .product-card__image {
    width: 250px;
    max-width: 100%;
    padding: 20px;
    margin: 0 auto;
  }
  .product-card--layout--list .product-card__info {
    border-top: 1px solid #ebebeb;
    padding: 20px;
  }
  [dir=ltr] .product-card--layout--list .product-card__info {
    padding-left: 20px;
    padding-right: 20px;
  }
  [dir=rtl] .product-card--layout--list .product-card__info {
    padding-right: 20px;
    padding-left: 20px;
  }
  .product-card--layout--list .product-card__actions {
    width: auto;
    border-top: 1px solid #ebebeb;
    padding: 20px;
  }
  [dir=ltr] .product-card--layout--list .product-card__actions {
    border-left: none;
  }
  [dir=rtl] .product-card--layout--list .product-card__actions {
    border-right: none;
  }
  .product-card--layout--list .product-card__buttons {
    flex-wrap: nowrap;
  }
  .product-card--layout--list .product-card__buttons .btn {
    font-size: 1rem;
    height: calc(2.25rem + 2px);
    line-height: 1.5;
    padding: .375rem 1.25rem;
    font-weight: 500;
  }
  .product-card--layout--list .product-card__buttons .btn.btn-svg-icon {
    width: calc(2.25rem + 2px);
  }
  .product-card--layout--list .product-card__addtocart--list {
    width: auto;
  }
  [dir=ltr] .product-card--layout--list .product-card__addtocart--list {
    margin-right: auto;
  }
  [dir=rtl] .product-card--layout--list .product-card__addtocart--list {
    margin-left: auto;
  }
}

/*
// .product-image
*/
.product-image__body {
  display: block;
  position: relative;
  padding-bottom: 100%;
  width: 100%;
}

.product-image__img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product-image__img,
  .owl-carousel .owl-item .product-image__img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.owl-carousel .owl-item .product-image--location--gallery .product-image__img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/*
// .rating
*/
.rating__body {
  display: flex;
}

[dir=ltr] .rating__star + .rating__star {
  margin-left: 3px;
}

[dir=rtl] .rating__star + .rating__star {
  margin-right: 3px;
}

[dir=ltr] .rating__star:first-child + .rating__star--only-edge {
  margin-left: 0;
}

[dir=rtl] .rating__star:first-child + .rating__star--only-edge {
  margin-right: 0;
}

.rating__fill {
  color: #ccc;
  fill: currentColor;
}

.rating__stroke {
  color: rgba(0, 0, 0, 0.08);
  fill: currentColor;
}

.rating__star--active .rating__fill {
  color: #ffd333;
}

.rating__star--active .rating__stroke {
  color: rgba(0, 0, 0, 0.08);
}

.rating__star--only-edge {
  position: relative;
  display: none;
}

@supports (-ms-ime-align: auto) {
  svg.rating__star {
    display: none;
  }
  .rating__star--only-edge {
    display: block;
  }
  .rating__fill .fake-svg-icon,
  .rating__stroke .fake-svg-icon {
    display: block;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 12px;
    font-size: 12px;
  }
  .rating__fill .fake-svg-icon::before {
    content: '\e902';
  }
  .rating__stroke {
    position: absolute;
    top: 0;
  }
  [dir=ltr] .rating__stroke {
    left: 0;
  }
  [dir=rtl] .rating__stroke {
    right: 0;
  }
  .rating__stroke .fake-svg-icon::before {
    content: '\e903';
  }
}

/*
// .select2
*/
.form-control + .select2-container {
  width: 100%;
}

.form-control + .select2-container .select2-selection__arrow {
  display: none;
}

.form-control + .select2-container .select2-selection--single {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-clip: border-box;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='%234d4d4d' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 5px 10px;
  transition: border-color .15s ease-in-out, background .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control + .select2-container .select2-selection--single:focus {
  outline: none;
}

.form-control + .select2-container .select2-selection__rendered {
  box-sizing: border-box;
}

.form-control + .select2-container .select2-selection--single {
  background-color: #fff;
  border-color: #ced4da;
  box-shadow: none;
}

.form-control + .select2-container .select2-selection__rendered {
  color: #495057;
}

.form-control + .select2-container--disabled .select2-selection--single {
  background-color: #e9ecef;
}

.form-control + .select2-container--open .select2-selection--single {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control + .select2-container .select2-selection--single {
  height: 38px;
}

[dir=ltr] .form-control + .select2-container .select2-selection--single {
  background-position: right 12px center;
}

[dir=rtl] .form-control + .select2-container .select2-selection--single {
  background-position: left 12px center;
}

.form-control + .select2-container .select2-selection__rendered {
  line-height: 21px;
  padding: calc(15px / 2) 12px;
  font-size: 16px;
}

[dir=ltr] .form-control + .select2-container .select2-selection__rendered {
  padding-right: 31px;
}

[dir=rtl] .form-control + .select2-container .select2-selection__rendered {
  padding-left: 31px;
}

.form-control-lg + .select2-container .select2-selection--single {
  height: 46px;
}

[dir=ltr] .form-control-lg + .select2-container .select2-selection--single {
  background-position: right 16px center;
}

[dir=rtl] .form-control-lg + .select2-container .select2-selection--single {
  background-position: left 16px center;
}

.form-control-lg + .select2-container .select2-selection__rendered {
  line-height: 25px;
  padding: calc(19px / 2) 16px;
  font-size: 20px;
}

[dir=ltr] .form-control-lg + .select2-container .select2-selection__rendered {
  padding-right: 39px;
}

[dir=rtl] .form-control-lg + .select2-container .select2-selection__rendered {
  padding-left: 39px;
}

.form-control-sm + .select2-container .select2-selection--single {
  height: 32px;
}

[dir=ltr] .form-control-sm + .select2-container .select2-selection--single {
  background-position: right 8px center;
}

[dir=rtl] .form-control-sm + .select2-container .select2-selection--single {
  background-position: left 8px center;
}

.form-control-sm + .select2-container .select2-selection__rendered {
  line-height: 17px;
  padding: calc(13px / 2) 8px;
  font-size: 14px;
}

[dir=ltr] .form-control-sm + .select2-container .select2-selection__rendered {
  padding-right: 23px;
}

[dir=rtl] .form-control-sm + .select2-container .select2-selection__rendered {
  padding-left: 23px;
}

.select2-dropdown {
  background: #fff;
  border: none;
  border-radius: 0;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1);
}

.select2-search--dropdown {
  padding: 0;
  border-bottom: 1px solid #e5e5e5;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  padding: 6px 10px;
  font-size: 15px;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #f2f2f2;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #d9ecff;
  color: #36414d;
}

.select2-results__option {
  padding: 4px 10px;
  font-size: 15px;
}

.select2-results__options:before, .select2-results__options:after {
  display: list-item;
  content: '';
  height: 6px;
}

/*
// .share-links
*/
.share-links__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
}

[dir=ltr] .share-links__item + .share-links__item {
  margin-left: 4px;
}

[dir=rtl] .share-links__item + .share-links__item {
  margin-right: 4px;
}

.share-links__item a {
  border-radius: 1.5px;
  display: block;
  padding: 2px 6px 1px 6px;
}

.share-links__item a, .share-links__item a:hover {
  color: inherit;
  text-decoration: none;
}

.share-links__item--type--like a {
  background: #4267b2;
}

.share-links__item--type--like a:hover {
  background: #3b5c9f;
}

.share-links__item--type--tweet a {
  background: #1b95e0;
}

.share-links__item--type--tweet a:hover {
  background: #1886c9;
}

.share-links__item--type--pin a {
  background: #e63939;
}

.share-links__item--type--pin a:hover {
  background: #e32222;
}

.share-links__item--type--counter a {
  color: #737373;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}

.share-links__item--type--counter a:hover {
  color: #666666;
}

/*
// .social-links
*/
.social-links__list {
  list-style: none;
  padding: 0;
  margin: 0 0 -7px -7px;
  display: flex;
  flex-wrap: wrap;
}

.social-links__item {
  flex-shrink: 0;
  margin-left: 7px;
  margin-bottom: 7px;
}

.social-links__link {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  transition: all .2s;
  background: #000;
}

.social-links__link, .social-links__link:hover {
  color: #fff;
}

.social-links__link:hover {
  opacity: .8;
}

.social-links--shape--circle .social-links__link {
  border-radius: 17px;
}

.social-links--shape--rounded .social-links__link {
  border-radius: 2px;
}

.social-links__link--type--facebook {
  background: #3c5a99;
}

.social-links__link--type--twitter {
  background: #00a2e8;
}

.social-links__link--type--youtube {
  background: #e52e2e;
}

.social-links__link--type--instagram {
  background: #815dc7;
}

.social-links__link--type--rss {
  background: #ffc338;
}

.social-links__link--type--rss, .social-links__link--type--rss:hover {
  color: #3d464d;
}

/*
// .tags
*/
.tags__list {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  margin: -2px;
}

.tags__list a {
  display: block;
  background: transparent;
  border: 1px solid #e5e5e5;
  color: #3d464d;
  border-radius: 1.5px;
  padding: 3px 8px 2px;
  margin: 2px;
  transition: all .15s;
}

.tags__list a:focus,
.tags__list a:hover {
  background: #3366cc;
  border: 1px solid #3366cc;
  color: #fff;
}

.tags--lg .tags__list {
  margin: -3px;
}

.tags--lg .tags__list a {
  padding: 6px 10px 5px;
  margin: 3px;
}

/*
// .tooltip
*/
.tooltip {
  pointer-events: none;
  transition-duration: 0s;
  font-family: inherit;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background: #3d464d;
  color: #fff;
  border-radius: 1.5px;
  position: relative;
  padding: .1875rem .625rem .125rem;
}

.tooltip .arrow {
  width: 8px;
  height: 8px;
}

.tooltip .arrow::before {
  width: 8px;
  height: 8px;
  border-radius: 1px;
  border: none;
  background: #3d464d;
  transform: rotateZ(45deg) scale(0.7619);
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: 4px 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-auto[x-placement^=top] .tooltip-inner,
.bs-tooltip-top .arrow,
.bs-tooltip-top .tooltip-inner {
  bottom: 3px;
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: 4px 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-auto[x-placement^=bottom] .tooltip-inner,
.bs-tooltip-bottom .arrow,
.bs-tooltip-bottom .tooltip-inner {
  top: 3px;
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 4px;
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-auto[x-placement^=right] .tooltip-inner,
.bs-tooltip-right .arrow,
.bs-tooltip-right .tooltip-inner {
  left: 3px;
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 4px;
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-auto[x-placement^=left] .tooltip-inner,
.bs-tooltip-left .arrow,
.bs-tooltip-left .tooltip-inner {
  right: 3px;
}

/*
// .typography
*/
.typography {
  line-height: 1.625;
}

.typography a:hover {
  text-decoration: underline;
}

.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  margin-top: 1.75em;
  margin-bottom: .75em;
}

.typography p {
  margin-bottom: 1.5em;
}

.typography ol, .typography ul {
  margin: 1.7em 0;
}

.typography li {
  margin-bottom: .7em;
}

.typography > *:first-child {
  margin-top: 0;
}

.typography > *:last-child {
  margin-bottom: 0;
}

.typography a > img {
  transition: opacity .2s;
}

.typography a:hover > img {
  opacity: .8;
}

.typography img {
  max-width: 100%;
}

.typography figure {
  margin: 2.5em 0;
}

.typography figcaption {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.typography hr {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  border: none;
  background: #ccc;
  margin: 2.5em auto;
}

.typography hr::after, .typography hr::before {
  position: absolute;
  content: '';
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
}

[dir=ltr] .typography hr::after {
  left: -30px;
}

[dir=rtl] .typography hr::after {
  right: -30px;
}

[dir=ltr] .typography hr::before {
  right: -30px;
}

[dir=rtl] .typography hr::before {
  left: -30px;
}

.typography blockquote {
  margin: 3em 2.5em 2.5em;
  text-align: center;
  font-size: 18px;
  font-style: italic;
}

.typography blockquote cite {
  margin-top: -.5em;
  color: #999;
  display: block;
  font-size: 15px;
  font-style: normal;
}

.typography blockquote p {
  margin-bottom: 1.25em;
}

.typography blockquote > *:first-child {
  margin-top: 0;
}

.typography blockquote > *:last-child {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
    margin-top: 1.25em;
    margin-bottom: .5em;
  }
}

@media (max-width: 767px) {
  .typography ol, .typography ul {
    margin: 1.5em 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .typography blockquote {
    margin: 2.5em 1.5em 2em;
  }
}

@media (max-width: 575px) {
  .typography blockquote {
    font-size: 16px;
    margin: 2.25em 0;
  }
  [dir=ltr] .typography blockquote {
    text-align: left;
  }
  [dir=rtl] .typography blockquote {
    text-align: right;
  }
}

@media (min-width: 992px) {
  .typography--expanded figure {
    text-align: center;
    position: relative;
    width: calc(100% + 190px);
    margin: 3em 0;
  }
  [dir=ltr] .typography--expanded figure {
    left: -95px;
  }
  [dir=rtl] .typography--expanded figure {
    right: -95px;
  }
  .typography--expanded figure img {
    overflow: hidden;
    border-radius: 3px;
  }
}

/* ----------------------------------------
// Filters
// ---------------------------------------- */
/*
// .filter
*/
.filter__title {
  display: block;
  border: none;
  padding: 6px 10px;
  cursor: pointer;
  width: calc(100% + 20px);
  position: relative;
  color: inherit;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  border-radius: 2px;
  margin: 0 -10px;
  transition: background-color .2s, fill .2s;
  background: transparent;
  fill: #ccc;
}

[dir=ltr] .filter__title {
  text-align: left;
}

[dir=rtl] .filter__title {
  text-align: right;
}

.filter__title:focus, .filter__title:hover {
  transition-duration: .1s, .1s;
  background: #f2f2f2;
  fill: #b3b3b3;
}

.filter__title:focus {
  outline: none;
}

.filter__arrow {
  position: absolute;
  top: 11px;
  transition: transform 0.2s;
}

[dir=ltr] .filter__arrow {
  right: 10px;
}

[dir=rtl] .filter__arrow {
  left: 10px;
}

.filter__body {
  margin: 0 -20px;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.filter__container {
  padding: 10px 20px 16px;
}

.filter--opened .filter__body {
  transition-delay: 0s, 0s;
  visibility: visible;
  height: auto;
  opacity: 1;
}

.filter--opened .filter__arrow {
  transform: rotateZ(180deg);
}

/*
// .filter-categories
*/
.filter-categories__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 18px;
}

.filter-categories__list a {
  color: inherit;
  transition: color .15s;
}

.filter-categories__list a:hover {
  color: #3366cc;
}

.filter-categories__item {
  padding: 6px 0;
  display: flex;
}

.filter-categories__item > a {
  flex-grow: 1;
}

[dir=ltr] .filter-categories__item > a {
  padding-right: 10px;
}

[dir=rtl] .filter-categories__item > a {
  padding-left: 10px;
}

.filter-categories__item--parent {
  position: relative;
}

[dir=ltr] .filter-categories__item--parent {
  padding-left: 14px;
}

[dir=rtl] .filter-categories__item--parent {
  padding-right: 14px;
}

.filter-categories__item--current {
  font-weight: 700;
}

.filter-categories__item--child {
  position: relative;
  font-size: 15px;
}

[dir=ltr] .filter-categories__item--child {
  padding-left: 14px;
}

[dir=rtl] .filter-categories__item--child {
  padding-right: 14px;
}

.filter-categories__arrow {
  fill: #ccc;
  position: absolute;
  top: 10px;
}

[dir=ltr] .filter-categories__arrow {
  left: 0;
  transform: scaleX(1);
}

[dir=rtl] .filter-categories__arrow {
  right: 0;
  transform: scaleX(-1);
}

.filter-categories__counter {
  font-size: 12px;
  line-height: 12px;
  padding-top: 4px;
  font-weight: 400;
  color: #999;
}

/*
// .filter-categories-alt
*/
.filter-categories-alt__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 18px;
}

.filter-categories-alt__list a {
  color: inherit;
  transition: color .15s;
}

.filter-categories-alt__list a:hover {
  color: #3366cc;
}

.filter-categories-alt__expander {
  margin: -3px 0;
  width: 24px;
  height: 24px;
  position: relative;
  border: none;
  border-radius: 1.5px;
  cursor: pointer;
  padding: 0;
  background: transparent;
  color: #ccc;
  transition: all .2s;
}

[dir=ltr] .filter-categories-alt__expander {
  float: right;
  margin-right: -6px;
  margin-left: 8px;
}

[dir=rtl] .filter-categories-alt__expander {
  float: left;
  margin-left: -6px;
  margin-right: 8px;
}

.filter-categories-alt__expander:before, .filter-categories-alt__expander:after {
  display: block;
  position: absolute;
  content: '';
  background: currentColor;
  width: 8px;
  height: 2px;
  top: calc(50% - 1px);
}

[dir=ltr] .filter-categories-alt__expander:before, [dir=ltr] .filter-categories-alt__expander:after {
  left: calc(50% - 4px);
}

[dir=rtl] .filter-categories-alt__expander:before, [dir=rtl] .filter-categories-alt__expander:after {
  right: calc(50% - 4px);
}

.filter-categories-alt__expander:after {
  transform: rotateZ(90deg);
  transition: transform 0.2s;
}

.filter-categories-alt__expander:focus, .filter-categories-alt__expander:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #a6a6a6;
}

.filter-categories-alt__expander:focus {
  outline: none;
}

.filter-categories-alt__children {
  overflow: hidden;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.filter-categories-alt__children:before, .filter-categories-alt__children:after {
  display: block;
  content: '';
  clear: both;
}

.filter-categories-alt__list--level--1 > .filter-categories-alt__item {
  padding: 6px 0;
}

.filter-categories-alt__list--level--1 > .filter-categories-alt__item > .filter-categories-alt__children {
  margin: 0 -20px;
}

.filter-categories-alt__list--level--1 > .filter-categories-alt__item > .filter-categories-alt__children:before {
  height: 12px;
}

.filter-categories-alt__list--level--2 {
  list-style: none;
  padding: 10px 20px;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  border: 0 solid #ebebeb;
  border-top-width: 1px;
  border-bottom-width: 1px;
  background: #f7f7f7;
}

.filter-categories-alt__list--level--2 > .filter-categories-alt__item {
  padding: 4px 0;
}

.filter-categories-alt__list--level--2 .filter-categories-alt__children {
  width: calc(100% + 40px);
  margin: 0 -20px;
  padding: 0 20px;
}

.filter-categories-alt__list--level--2 .filter-categories-alt__children:before {
  height: 5px;
}

.filter-categories-alt__list--level--3,
.filter-categories-alt__list--level--3 .filter-categories-alt__list {
  font-size: 14px;
  line-height: 17px;
}

[dir=ltr] .filter-categories-alt__list--level--3, [dir=ltr]
.filter-categories-alt__list--level--3 .filter-categories-alt__list {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  padding-left: 14px;
  margin-left: 6px;
}

[dir=rtl] .filter-categories-alt__list--level--3, [dir=rtl]
.filter-categories-alt__list--level--3 .filter-categories-alt__list {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  padding-right: 14px;
  margin-right: 6px;
}

.filter-categories-alt__list--level--3 > .filter-categories-alt__item,
.filter-categories-alt__list--level--3 .filter-categories-alt__list > .filter-categories-alt__item {
  padding: 2px 0;
}

.filter-categories-alt__list--level--3 .filter-categories-alt__children:after,
.filter-categories-alt__list--level--3 .filter-categories-alt__list .filter-categories-alt__children:after {
  height: 3px;
}

.filter-categories-alt__item--open > .filter-categories-alt__expander:after {
  transform: none;
}

.filter-categories-alt__item--open > .filter-categories-alt__children {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
}

.filter-categories-alt__item--current > a {
  font-weight: 700;
}

/*
// .filter-color
*/
.filter-color__list {
  padding: 2px 0;
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.filter-color__item {
  cursor: pointer;
  margin: 4px;
}

.filter-color__check {
  display: block;
}

/*
// .filter-list
*/
.filter-list__list {
  margin: -8px 0;
}

.filter-list__item {
  padding: 1px 0;
  cursor: pointer;
  display: flex;
  margin: 8px 0;
}

.filter-list__input {
  flex-shrink: 0;
}

[dir=ltr] .filter-list__input {
  margin-right: 7px;
}

[dir=rtl] .filter-list__input {
  margin-left: 7px;
}

.filter-list__title {
  padding: 1px 0;
  font-size: 14px;
  line-height: 16px;
  flex-grow: 1;
}

.filter-list__counter {
  padding-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #999;
}

[dir=ltr] .filter-list__counter {
  margin-left: 12px;
}

[dir=rtl] .filter-list__counter {
  margin-right: 12px;
}

.filter-list__item--disabled {
  cursor: default;
}

.filter-list__item--disabled .filter-list__title {
  color: #999;
}

/*
// .filter-price
*/
.filter-price__slider {
  margin-top: 6px;
}

.filter-price__title {
  margin-top: 16px;
  font-size: 14px;
  color: #999;
}

/* ----------------------------------------
// Header
// ---------------------------------------- */
/*
// .account-menu
*/
.account-menu {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
  background: #fff;
  color: #3d464d;
  width: 280px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .account-menu {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  }
}

.account-menu__form {
  padding: 0 30px 32px;
}

.account-menu__form .form-group {
  margin-bottom: 1rem;
}

.account-menu__form-title {
  text-align: center;
  padding: 30px 0 26px;
  font-weight: 700;
}

.account-menu__form-button {
  margin-top: 32px;
  text-align: center;
}

.account-menu__form-button .btn {
  padding-left: 28px;
  padding-right: 28px;
}

.account-menu__form-link {
  font-size: 14px;
  text-align: center;
}

.account-menu__form-link a {
  color: #6c757d;
  transition: color .1s;
}

.account-menu__form-link a:hover {
  color: inherit;
}

.account-menu__form-forgot {
  position: relative;
}

.account-menu__form-forgot-link {
  position: absolute;
  top: 5px;
  bottom: 5px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  background: transparent;
  color: #3d464d;
  display: flex;
  align-items: center;
  padding: 0 7px;
  transition: background .1s, color .1s;
}

[dir=ltr] .account-menu__form-forgot-link {
  right: 5px;
}

[dir=rtl] .account-menu__form-forgot-link {
  left: 5px;
}

.account-menu__form-forgot-link:hover {
  background: #f0f0f0;
  color: #3d464d;
}

.account-menu__form-forgot-link:active {
  background: #e5e5e5;
  color: #3d464d;
}

.account-menu__divider {
  height: 1px;
  background: #ebebeb;
}

.account-menu__user {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  color: inherit;
}

.account-menu__user:hover {
  color: inherit;
  background: #f2f2f2;
}

.account-menu__user-avatar {
  width: 44px;
  flex-shrink: 0;
}

[dir=ltr] .account-menu__user-avatar {
  margin-right: 14px;
}

[dir=rtl] .account-menu__user-avatar {
  margin-left: 14px;
}

.account-menu__user-avatar img {
  border-radius: 100%;
  max-width: 100%;
}

.account-menu__user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.account-menu__user-name {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.account-menu__user-email {
  font-size: 14px;
  line-height: 18px;
  color: #999;
  margin-top: 1px;
}

.account-menu__links {
  list-style: none;
  padding: 12px 0;
  margin: 0;
}

.account-menu__links a {
  display: block;
  color: inherit;
  font-size: 15px;
  padding: 5px 20px;
  font-weight: 500;
}

.account-menu__links a:hover {
  background: #f2f2f2;
}

/*
// .departments
*/
.departments {
  width: 255px;
  height: 42px;
  color: #3d464d;
  position: relative;
}

.departments__button {
  border: none;
  background: transparent;
  color: inherit;
  font-size: 15px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: default;
  font-weight: 500;
  padding: 0;
}

[dir=ltr] .departments__button {
  padding-right: 32px;
  padding-left: 47px;
  text-align: left;
}

[dir=rtl] .departments__button {
  padding-left: 32px;
  padding-right: 47px;
  text-align: right;
}

.departments__button:focus {
  outline: none;
}

.departments__button-icon {
  position: absolute;
  top: calc(50% - 7px);
  fill: rgba(0, 0, 0, 0.3);
  transition: fill .2s;
}

[dir=ltr] .departments__button-icon {
  left: 16px;
}

[dir=rtl] .departments__button-icon {
  right: 16px;
}

.departments__button-arrow {
  position: absolute;
  top: calc(50% - 3px);
  fill: rgba(0, 0, 0, 0.3);
  transition: fill .2s, transform .2s;
}

[dir=ltr] .departments__button-arrow {
  right: 13px;
}

[dir=rtl] .departments__button-arrow {
  left: 13px;
}

.departments__body {
  width: 100%;
  padding-top: 42px;
  position: absolute;
  background: #fff;
  box-shadow: 0 0 0 2px #3366cc;
  border-radius: 2px;
  top: 0;
}

.departments__links {
  position: relative;
  list-style: none;
  padding: 0 0 14px;
  margin: 0;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.departments__item-link {
  font-weight: 400;
  position: relative;
  display: block;
  padding: 10px 16px;
  color: inherit;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .02em;
}

.departments__item-link:hover {
  color: inherit;
}

.departments__item--hover .departments__item-link {
  background: rgba(0, 0, 0, 0.05);
}

.departments__item-arrow {
  top: calc(50% - 5px);
  position: absolute;
  fill: rgba(0, 0, 0, 0.3);
}

[dir=ltr] .departments__item-arrow {
  right: 14px;
  transform: scaleX(1);
}

[dir=rtl] .departments__item-arrow {
  left: 14px;
  transform: scaleX(-1);
}

.departments__submenus-container {
  position: absolute;
  height: calc(100% - 48px);
  top: 48px;
  width: 100%;
}

.departments__submenu {
  position: absolute;
  top: 0;
  visibility: hidden;
  display: none;
  z-index: 100;
}

[dir=ltr] .departments__submenu {
  left: 100%;
}

[dir=rtl] .departments__submenu {
  right: 100%;
}

.departments__submenu--type--megamenu {
  min-height: 100%;
  flex-grow: 1;
}

.departments__submenu--open {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.departments__submenu--size--sm {
  width: 237px;
}

.departments__submenu--size--nl {
  width: 443px;
}

.departments__submenu--size--lg {
  width: 649px;
}

.departments__submenu--size--xl {
  width: 855px;
}

.departments__links-wrapper {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.25s ease-in-out, opacity 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.departments__links-wrapper:before {
  display: block;
  content: '';
  height: 6px;
  flex-shrink: 0;
}

.departments:not(.departments--fixed) .departments__button {
  cursor: pointer;
}

.departments:not(.departments--fixed) .departments__button:hover .departments__button-icon,
.departments:not(.departments--fixed) .departments__button:hover .departments__button-arrow {
  fill: currentColor;
}

.departments--open .departments__links-wrapper {
  overflow: visible;
  height: auto;
  opacity: 1;
}

.departments--open .departments__button-arrow {
  transform: rotateZ(180deg);
}

.departments--transition .departments__links,
.departments--transition .departments__links-wrapper {
  overflow: hidden;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .departments {
    width: 210px;
  }
  .departments__submenu--size--sm {
    width: 204px;
  }
  .departments__submenu--size--nl {
    width: 376px;
  }
  .departments__submenu--size--lg {
    width: 548px;
  }
  .departments__submenu--size--xl {
    width: 720px;
  }
}

/*
// .dropcart
*/
.dropcart {
  font-size: 15px;
  font-weight: 400;
  color: #3d464d;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.dropcart__empty {
  padding: 42px 30px;
  text-align: center;
}

.dropcart__products-list {
  padding: 20px 16px;
}

.dropcart__product {
  display: flex;
}

.dropcart__product + .dropcart__product {
  margin-top: 24px;
}

.dropcart__product-image {
  width: 70px;
  flex-shrink: 0;
}

.dropcart__product-name {
  margin-top: -2px;
  line-height: 18px;
  font-size: 15px;
}

.dropcart__product-name a {
  color: inherit;
  transition: .15s;
}

.dropcart__product-name a:hover {
  color: #3366cc;
}

.dropcart__product-info {
  margin: 0;
  flex-grow: 1;
}

[dir=ltr] .dropcart__product-info {
  padding-right: 10px;
  padding-left: 16px;
}

[dir=rtl] .dropcart__product-info {
  padding-left: 10px;
  padding-right: 16px;
}

.dropcart__product-options {
  list-style: none;
  margin: 3px 0 0;
  padding: 0;
  font-size: 13px;
  line-height: 16px;
  color: #999;
}

.dropcart__product-meta {
  margin-top: 5px;
  font-size: 13px;
}

.dropcart__product-price {
  font-size: 14px;
  font-weight: 700;
}

.dropcart__product-remove {
  margin-top: -6px;
  flex-shrink: 0;
}

[dir=ltr] .dropcart__product-remove {
  margin-right: -6px;
}

[dir=rtl] .dropcart__product-remove {
  margin-left: -6px;
}

.dropcart__totals {
  border-top: 1px solid #ebebeb;
  line-height: 24px;
  padding: 16px 20px 0;
}

[dir=ltr] .dropcart__totals th {
  padding-right: 16px;
}

[dir=rtl] .dropcart__totals th {
  padding-left: 16px;
}

[dir=ltr] .dropcart__totals td {
  text-align: right;
}

[dir=rtl] .dropcart__totals td {
  text-align: left;
}

.dropcart__totals table {
  width: 100%;
}

.dropcart__buttons {
  display: flex;
  margin-top: 4px;
  padding: 20px;
}

.dropcart__buttons > * {
  flex-grow: 1;
}

[dir=ltr] .dropcart__buttons > :first-child {
  margin-right: 8px;
}

[dir=rtl] .dropcart__buttons > :first-child {
  margin-left: 8px;
}

.dropcart--style--dropdown {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
  background: #fff;
  width: 320px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dropcart--style--dropdown {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  }
}

.dropcart--style--offcanvas {
  visibility: hidden;
  transition: visibility 0s 0.3s;
}

.dropcart--style--offcanvas .dropcart__backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #3d464d;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
}

.dropcart--style--offcanvas .dropcart__body {
  position: fixed;
  top: 0;
  z-index: 1010;
  height: 100%;
  max-height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-width: 340px;
  transition: transform 0.3s;
}

[dir=ltr] .dropcart--style--offcanvas .dropcart__body {
  margin-left: 30px;
  right: 0;
  transform: translateX(calc(100% + 30px));
}

[dir=rtl] .dropcart--style--offcanvas .dropcart__body {
  margin-right: 30px;
  left: 0;
  transform: translateX(calc(-100% + -30px));
}

.dropcart--style--offcanvas .dropcart__header {
  height: 55px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid #ebebeb;
}

.dropcart--style--offcanvas .dropcart__title {
  font-size: 16px;
  font-weight: 700;
  margin: 0 20px;
  flex-grow: 1;
}

.dropcart--style--offcanvas .dropcart__close {
  border: none;
  padding: 0;
  margin: 0;
  width: 54px;
  height: 54px;
  background: transparent;
  fill: #999;
  transition: fill .2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropcart--style--offcanvas .dropcart__close:focus {
  outline: none;
}

.dropcart--style--offcanvas .dropcart__close:focus, .dropcart--style--offcanvas .dropcart__close:hover {
  fill: #3d464d;
}

.dropcart--style--offcanvas .dropcart__products-list {
  flex-shrink: 1;
  flex-grow: 1;
}

.dropcart--style--offcanvas .dropcart__totals,
.dropcart--style--offcanvas .dropcart__buttons {
  flex-shrink: 0;
}

.dropcart--style--offcanvas.dropcart--open {
  visibility: visible;
  transition-delay: 0s;
}

.dropcart--style--offcanvas.dropcart--open .dropcart__backdrop {
  opacity: .9;
}

.dropcart--style--offcanvas.dropcart--open .dropcart__body {
  transform: translateX(0);
}

@media (min-height: 600px) {
  .dropcart--style--offcanvas .dropcart__products-list {
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }
}

@media (max-height: 599px) {
  .dropcart--style--offcanvas .dropcart__header {
    top: 0;
    position: sticky;
    background: #fff;
    z-index: 1;
  }
  .dropcart--style--offcanvas .dropcart__body {
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }
}

/*
// .indicator
*/
.indicator {
  display: flex;
  color: none;
}

.indicator__button {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: inherit;
  fill: currentColor;
}

.indicator__button:focus {
  outline: none;
}

.indicator__button:hover {
  color: inherit;
}

.indicator__area {
  background: transparent;
  transition: background-color .2s;
  border-radius: 2px;
  height: 42px;
  padding: 0 14px;
  display: flex;
  align-items: center;
}

.indicator__value {
  height: 15px;
  font-size: 10px;
  padding: 0 5px;
  border-radius: 1000px;
  position: relative;
  top: -11px;
  background: #fff;
  color: #3d464d;
  font-weight: 700;
}

[dir=ltr] .indicator__value {
  margin-left: -5px;
  margin-right: -8px;
}

[dir=rtl] .indicator__value {
  margin-right: -5px;
  margin-left: -8px;
}

.indicator__dropdown {
  display: none;
  top: 100%;
  position: absolute;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

[dir=ltr] .indicator__dropdown {
  right: 0;
}

[dir=rtl] .indicator__dropdown {
  left: 0;
}

.indicator__icon {
  transition: transform .2s, opacity .2s;
}

.indicator__icon--open {
  position: absolute;
}

.indicator--display .indicator__dropdown {
  display: flex;
}

.indicator--open .indicator__button,
.indicator--hover .indicator__button {
  color: none;
}

.indicator--open .indicator__area,
.indicator--hover .indicator__area {
  background: rgba(255, 255, 255, 0.15);
  transition-duration: .1s;
}

.indicator--open .indicator__value,
.indicator--hover .indicator__value {
  background: #fff;
  color: #3d464d;
}

.indicator--open .indicator__dropdown {
  z-index: 1;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}

.indicator--trigger--click:not(.indicator--open) .indicator__icon--open {
  transform: scale(0);
  opacity: 0;
}

.indicator--trigger--click.indicator--open .indicator__icon:not(.indicator__icon--open) {
  transform: scale(0);
  opacity: 0;
}

.indicator--mobile .indicator__area {
  height: 36px;
  padding: 0 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .indicator__area {
    padding: 0 12px;
  }
}

/*
// .megamenu
*/
.megamenu {
  background: #fff;
  color: #3d464d;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
  min-height: 100%;
  width: 100%;
  line-height: 1.5;
  letter-spacing: .01em;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .megamenu {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  }
}

.megamenu__body {
  padding: 26px 30px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100%;
}

[dir=ltr] .megamenu__body {
  background-position: right bottom;
}

[dir=rtl] .megamenu__body {
  background-position: left bottom;
}

[dir=ltr] .megamenu--departments {
  padding-right: 31px;
}

[dir=rtl] .megamenu--departments {
  padding-left: 31px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  [dir=ltr] .megamenu--departments {
    padding-right: 32px;
  }
  [dir=rtl] .megamenu--departments {
    padding-left: 32px;
  }
}

.megamenu__links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.megamenu__links a {
  color: inherit;
  transition: color .15s;
}

.megamenu__links a:hover {
  color: #3366cc;
}

.megamenu__links--level--0 > .megamenu__item + .megamenu__item--with-submenu,
.megamenu__links--level--0 > .megamenu__item--with-submenu + .megamenu__item {
  margin-top: 20px;
}

.megamenu__links--level--0 > .megamenu__item {
  padding: 1px 0;
}

.megamenu__links--level--0 > .megamenu__item > a {
  font-weight: 500;
}

.megamenu__links--level--0 > .megamenu__item > a + .megamenu__links {
  padding-top: 10px;
  margin-top: 3px;
  border-top: 1px solid #e5e5e5;
}

.megamenu__links--level--1 > .megamenu__item > a {
  font-size: 14px;
  color: #5c6b73;
  transition: color .15s;
}

.megamenu__links--level--1 > .megamenu__item > a:hover {
  color: #3366cc;
}

/*
// .menu
*/
.menu {
  color: #3d464d;
  background: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 190px;
  min-width: 190px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .02em;
  font-weight: 500;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menu {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  }
}

.menu__list {
  list-style: none;
  padding: 10px 0;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  flex-grow: 1;
  margin: 0;
}

.menu__item {
  position: relative;
}

.menu__item-submenu-offset {
  position: absolute;
  top: -10px;
}

.menu__item-link {
  background: transparent;
  border: none;
  width: 100%;
  font-weight: inherit;
  display: block;
  padding-top: 8px;
  padding-bottom: 6px;
  color: inherit;
}

[dir=ltr] .menu__item-link {
  text-align: left;
  padding-right: 24px;
  padding-left: 16px;
}

[dir=rtl] .menu__item-link {
  text-align: right;
  padding-left: 24px;
  padding-right: 16px;
}

.menu__item-link:hover {
  color: inherit;
}

.menu__item-link:focus {
  outline: none;
}

.menu__item-icon {
  position: absolute;
  top: 3px;
}

[dir=ltr] .menu__item-icon {
  left: 15px;
}

[dir=rtl] .menu__item-icon {
  right: 15px;
}

.menu__item-arrow {
  position: absolute;
  top: calc(50% - 5px);
  fill: #b3b3b3;
}

[dir=ltr] .menu__item-arrow {
  right: 12px;
  transform: scaleX(1);
}

[dir=rtl] .menu__item-arrow {
  left: 12px;
  transform: scaleX(-1);
}

.menu__item--hover .menu__item-link {
  background: #f2f2f2;
}

.menu__item--hover > .menu__submenu {
  opacity: 1;
  visibility: visible;
  transform: rotateY(0deg);
}

.menu__submenu {
  position: absolute;
  visibility: hidden;
  transform: rotateY(45deg);
  opacity: 0;
  transition: transform .2s, opacity .2s;
  display: none;
  z-index: 1;
}

[dir=ltr] .menu__submenu {
  left: 100%;
  transform-origin: left;
}

[dir=rtl] .menu__submenu {
  right: 100%;
  transform-origin: right;
}

[dir=ltr] .menu__submenu--reverse {
  left: auto;
  right: 100%;
  transform-origin: right;
}

[dir=rtl] .menu__submenu--reverse {
  right: auto;
  left: 100%;
  transform-origin: left;
}

.menu__submenu--display {
  display: flex;
}

.menu__submenu--open {
  visibility: visible;
  opacity: 1;
  transform: rotateY(0deg);
}

[dir=ltr] .menu--with-icons .menu__item-link {
  padding-left: 50px;
}

[dir=rtl] .menu--with-icons .menu__item-link {
  padding-right: 50px;
}

.menu--layout--topbar {
  width: 150px;
  min-width: 150px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menu--layout--topbar {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  }
}

.menu--layout--topbar .menu__list {
  padding: 6px 0;
}

.menu--layout--topbar .menu__item-link {
  display: block;
  padding-top: 8px;
  padding-bottom: 6px;
  color: inherit;
}

[dir=ltr] .menu--layout--topbar .menu__item-link {
  padding-right: 21px;
  padding-left: 13px;
}

[dir=rtl] .menu--layout--topbar .menu__item-link {
  padding-left: 21px;
  padding-right: 13px;
}

.menu--layout--topbar.menu--with-icons .menu__item-icon {
  position: absolute;
  top: 3px;
}

[dir=ltr] .menu--layout--topbar.menu--with-icons .menu__item-icon {
  left: 12px;
}

[dir=rtl] .menu--layout--topbar.menu--with-icons .menu__item-icon {
  right: 12px;
}

[dir=ltr] .menu--layout--topbar.menu--with-icons .menu__item-link {
  padding-left: 45px;
}

[dir=rtl] .menu--layout--topbar.menu--with-icons .menu__item-link {
  padding-right: 45px;
}

/*
// .mobile-header
*/
.mobile-header {
  height: 54px;
}

.mobile-header__panel {
  z-index: 10;
  position: relative;
  background: #3366cc;
  box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04);
  color: #fff;
}

.mobile-header--stuck .mobile-header__panel {
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04), 0 1px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-150%);
  transition: transform .3s ease-in-out;
  will-change: transform;
}

.mobile-header--stuck.mobile-header--show .mobile-header__panel {
  transform: none;
}

.mobile-header__body {
  display: flex;
  align-items: center;
  height: 54px;
}

.mobile-header__menu-button {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 2px;
  background: transparent;
  fill: #fff;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: fill .2s, background .2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-header__menu-button:focus {
  outline: none;
}

.mobile-header__menu-button:focus, .mobile-header__menu-button:hover {
  background: rgba(255, 255, 255, 0.15);
  fill: #fff;
}

.mobile-header__indicators {
  display: flex;
}

[dir=ltr] .mobile-header__indicators {
  margin-left: auto;
}

[dir=rtl] .mobile-header__indicators {
  margin-right: auto;
}

.mobile-header__logo {
  margin: 0;
  color: inherit;
  fill: currentColor;
}

[dir=ltr] .mobile-header__logo {
  margin-right: 16px;
  margin-left: 12px;
}

[dir=rtl] .mobile-header__logo {
  margin-left: 16px;
  margin-right: 12px;
}

.mobile-header__logo:hover {
  color: inherit;
}

.mobile-header__logo svg {
  display: block;
}

@media (min-width: 768px) and (max-width: 991px) {
  .mobile-header__search {
    flex-grow: 1;
    height: 36px;
  }
  [dir=ltr] .mobile-header__search {
    margin-right: 8px;
  }
  [dir=rtl] .mobile-header__search {
    margin-left: 8px;
  }
}

@media (max-width: 767px) {
  .mobile-header__search {
    top: 0;
    width: 100%;
    position: absolute;
    background: #fff;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
    z-index: 1;
    transform: translateY(-54px);
    visibility: hidden;
    opacity: 0;
    transition: transform .3s ease-in-out, visibility 0s .3s, opacity .3s;
    display: flex;
    pointer-events: none;
  }
  [dir=ltr] .mobile-header__search {
    left: 0;
  }
  [dir=rtl] .mobile-header__search {
    right: 0;
  }
  .mobile-header__search--open {
    pointer-events: auto;
    transition-delay: 0s, 0s;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
}

/*
// .mobile-links
*/
.mobile-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-links__item {
  border-bottom: 1px solid #ebebeb;
}

.mobile-links__item-title {
  display: flex;
  align-items: stretch;
  cursor: pointer;
}

.mobile-links__item-link {
  flex-grow: 1;
  color: #3d464d;
}

.mobile-links__item-link, .mobile-links__item-link:hover {
  color: inherit;
}

.mobile-links__item-toggle {
  border: none;
  background: transparent;
  padding: 0;
  width: 55px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: inherit;
  fill: #999;
  transition: fill .2s;
}

[dir=ltr] .mobile-links__item-toggle {
  border-left: 1px solid #ebebeb;
}

[dir=rtl] .mobile-links__item-toggle {
  border-right: 1px solid #ebebeb;
}

.mobile-links__item-toggle:focus {
  outline: none;
}

.mobile-links__item-toggle:focus, .mobile-links__item-toggle:hover {
  cursor: inherit;
}

.mobile-links__item-arrow {
  transition: transform 0.3s;
}

.mobile-links__item-sub-links {
  visibility: hidden;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s, visibility 0s 0.3s;
}

.mobile-links__item--open > .mobile-links__item-sub-links {
  visibility: visible;
  height: auto;
  opacity: 1;
  transition-delay: 0s, 0s, 0s;
}

.mobile-links__item--open > .mobile-links__item-title .mobile-links__item-arrow {
  transform: rotateZ(180deg) translateY(1px);
}

.mobile-links--level--0 > .mobile-links__item {
  border-bottom: 1px solid #ebebeb;
}

.mobile-links--level--0 > .mobile-links__item > .mobile-links__item-title {
  height: 48px;
}

.mobile-links--level--0 > .mobile-links__item > .mobile-links__item-title .mobile-links__item-link {
  padding: 12px 20px;
}

.mobile-links:not(.mobile-links--level--0) {
  border-top: 1px solid #ebebeb;
}

.mobile-links--level--1 {
  background: #f7f7f7;
  font-size: 14px;
  line-height: 14px;
}

.mobile-links--level--1 .mobile-links__item .mobile-links__item-link {
  padding: 13px 20px;
}

.mobile-links--level--1 .mobile-links {
  background: #fff;
}

[dir=ltr] .mobile-links--level--1 .mobile-links {
  padding-left: 20px;
}

[dir=rtl] .mobile-links--level--1 .mobile-links {
  padding-right: 20px;
}

.mobile-links--level--1 .mobile-links > li {
  background: #f7f7f7;
}

[dir=ltr] .mobile-links--level--1 .mobile-links > li {
  border-left: 1px solid #ebebeb;
}

[dir=rtl] .mobile-links--level--1 .mobile-links > li {
  border-right: 1px solid #ebebeb;
}

.mobile-links--level--1 .mobile-links__item:last-child {
  border-bottom: none;
}

/*
// .mobilemenu
*/
.mobilemenu {
  visibility: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: visibility 0s 0.3s;
}

[dir=ltr] .mobilemenu {
  left: 0;
}

[dir=rtl] .mobilemenu {
  right: 0;
}

.mobilemenu__backdrop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #3d464d;
  opacity: 0;
  transition: opacity 0.3s;
}

[dir=ltr] .mobilemenu__backdrop {
  left: 0;
}

[dir=rtl] .mobilemenu__backdrop {
  right: 0;
}

.mobilemenu__body {
  position: absolute;
  top: 0;
  width: 290px;
  height: 100%;
  background: #fff;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
}

[dir=ltr] .mobilemenu__body {
  left: 0;
  transform: translateX(calc(-100% * 1 - 30px * 1));
}

[dir=rtl] .mobilemenu__body {
  right: 0;
  transform: translateX(calc(-100% * -1 - 30px * -1));
}

.mobilemenu__header {
  height: 55px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}

.mobilemenu__title {
  padding: 0 20px;
  font-weight: 700;
  flex-grow: 1;
}

.mobilemenu__close {
  border: none;
  background: transparent;
  height: 54px;
  width: 55px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: #999;
  transition: fill .2s;
}

[dir=ltr] .mobilemenu__close {
  border-left: 1px solid #ebebeb;
}

[dir=rtl] .mobilemenu__close {
  border-right: 1px solid #ebebeb;
}

.mobilemenu__close:focus {
  outline: none;
}

.mobilemenu__close:focus, .mobilemenu__close:hover {
  fill: #3d464d;
}

.mobilemenu__content {
  flex-grow: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.mobilemenu--open {
  visibility: visible;
  transition-delay: 0s;
}

.mobilemenu--open .mobilemenu__backdrop {
  opacity: .9;
}

.mobilemenu--open .mobilemenu__body {
  transform: translateX(0);
}

/*
// .nav-links
*/
.nav-links {
  height: 100%;
}

.nav-links__list {
  height: inherit;
  z-index: 10;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links__item {
  height: 100%;
}

.nav-links__item-link {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 15px;
  line-height: 1;
  letter-spacing: .02em;
  font-weight: 400;
  color: inherit;
}

.nav-links__item-link:hover {
  color: inherit;
}

.nav-links__item-body {
  display: flex;
  align-items: center;
  background: transparent;
  transition: background-color .2s;
  padding: 0 14px;
  height: 42px;
  border-radius: 2px;
  background: transparent;
  box-shadow: none;
}

.nav-links__item--has-submenu .nav-links__item-body {
  position: relative;
}

[dir=ltr] .nav-links__item--has-submenu .nav-links__item-body {
  padding-right: 27px;
}

[dir=rtl] .nav-links__item--has-submenu .nav-links__item-body {
  padding-left: 27px;
}

.nav-links__item--has-submenu .nav-links__item-arrow {
  top: calc(50% - 3px);
  position: absolute;
  fill: rgba(0, 0, 0, 0.25);
}

[dir=ltr] .nav-links__item--has-submenu .nav-links__item-arrow {
  right: 12px;
}

[dir=rtl] .nav-links__item--has-submenu .nav-links__item-arrow {
  left: 12px;
}

.nav-links__item--hover .nav-links__item-link {
  color: inherit;
}

.nav-links__item--hover .nav-links__item-body {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: none;
  transition-duration: .1s;
}

.nav-links__item--hover .nav-links__item-arrow {
  fill: rgba(0, 0, 0, 0.25);
}

.nav-links__submenu {
  top: 100%;
  position: absolute;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(45deg);
  opacity: 0;
  transition: transform .2s, opacity .2s;
  z-index: 100;
  display: none;
}

[dir=ltr] .nav-links__submenu--type--megamenu {
  left: 0;
}

[dir=rtl] .nav-links__submenu--type--megamenu {
  right: 0;
}

.nav-links__submenu--display {
  display: flex;
}

.nav-links__submenu--open {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}

.nav-links__submenu--size--xs {
  width: 16.66667%;
}

.nav-links__submenu--size--sm {
  width: 33.33333%;
}

.nav-links__submenu--size--nl {
  width: 50%;
}

.nav-links__submenu--size--md {
  width: 66.66667%;
}

.nav-links__submenu--size--lg {
  width: 83.33333%;
}

.nav-links__submenu--size--xl {
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .nav-links__item-body {
    padding: 0 9px;
  }
  [dir=ltr] .nav-links__item--has-submenu .nav-links__item-body {
    padding-right: 20px;
  }
  [dir=rtl] .nav-links__item--has-submenu .nav-links__item-body {
    padding-left: 20px;
  }
  [dir=ltr] .nav-links__item--has-submenu .nav-links__item-arrow {
    right: 7px;
  }
  [dir=rtl] .nav-links__item--has-submenu .nav-links__item-arrow {
    left: 7px;
  }
}

/*
// .nav-panel
*/
.nav-panel {
  height: 54px;
  background: #3366cc;
  box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04);
  color: #fff;
}

.nav-panel--stuck {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04), 0 1px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-150%);
  transition: transform .3s ease-in-out;
}

.nav-panel--stuck.nav-panel--show {
  transform: none;
}

.nav-panel__container {
  height: 100%;
}

.nav-panel__row {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.nav-panel__logo svg {
  display: block;
  fill: #fff;
}

.nav-panel__departments {
  flex-shrink: 0;
}

[dir=ltr] .nav-panel__logo + .nav-panel__nav-links, [dir=ltr]
.nav-panel__departments + .nav-panel__nav-links {
  margin-left: 18px;
}

[dir=rtl] .nav-panel__logo + .nav-panel__nav-links, [dir=rtl]
.nav-panel__departments + .nav-panel__nav-links {
  margin-right: 18px;
}

[dir=ltr] .nav-panel__nav-links {
  margin-right: 4px;
}

[dir=rtl] .nav-panel__nav-links {
  margin-left: 4px;
}

.nav-panel__indicators {
  display: flex;
  height: 100%;
}

[dir=ltr] .nav-panel__indicators {
  margin-left: auto;
}

[dir=rtl] .nav-panel__indicators {
  margin-right: auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
  [dir=ltr] .nav-panel__logo + .nav-panel__nav-links, [dir=ltr]
  .nav-panel__departments + .nav-panel__nav-links {
    margin-left: 14px;
  }
  [dir=rtl] .nav-panel__logo + .nav-panel__nav-links, [dir=rtl]
  .nav-panel__departments + .nav-panel__nav-links {
    margin-right: 14px;
  }
}

/*
// .search
*/
/*
// .search--location--header
*/
.search--location--header {
  display: block;
  height: 40px;
}

.search--location--header .search__body {
  position: relative;
  z-index: 2;
}

.search--location--header .search__form {
  height: 40px;
  display: flex;
}

.search--location--header .search__border {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  transition: box-shadow .15s, background .15s;
}

.search--location--header .search__categories {
  margin: 2px;
  padding: 0 14px;
  appearance: none;
  border: none;
  background-color: transparent;
  color: #3d464d;
  font-size: 14px;
  transition: background-color .2s;
  max-width: 30%;
  cursor: pointer;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='%234d4d4d' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 5px 10px;
}

[dir=ltr] .search--location--header .search__categories {
  border-right: 2px solid #e5e5e5;
  padding-right: 31px;
  margin-right: 0;
  background-position: center right 13px;
}

[dir=rtl] .search--location--header .search__categories {
  border-left: 2px solid #e5e5e5;
  padding-left: 31px;
  margin-left: 0;
  background-position: center left 13px;
}

.search--location--header .search__categories:hover {
  background-color: #f5f5f5;
}

.search--location--header .search__categories:focus {
  outline: none;
}

.search--location--header .search__input {
  border: none;
  padding: 0 17px;
  background: transparent;
  width: 1px;
  flex-grow: 1;
  font-size: 15px;
  transition: color .15s;
  color: #3d464d;
}

.search--location--header .search__input::placeholder {
  color: #999;
}

.search--location--header .search__input ~ .search__border {
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.search--location--header .search__input:hover {
  color: #3d464d;
}

.search--location--header .search__input:hover::placeholder {
  color: #999;
}

.search--location--header .search__input:hover ~ .search__border {
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.search--location--header .search__input:hover ~ .search__button {
  fill: #bfbfbf;
}

.search--location--header .search__input:hover ~ .search__button:hover {
  fill: #3366cc;
}

.search--location--header .search__input:focus,
.search--location--header.search--has-suggestions.search--suggestions-open .search__input {
  outline: none;
  color: #3d464d;
}

.search--location--header .search__input:focus::placeholder,
.search--location--header.search--has-suggestions.search--suggestions-open .search__input::placeholder {
  color: #999;
}

.search--location--header .search__input:focus ~ .search__border,
.search--location--header.search--has-suggestions.search--suggestions-open .search__input ~ .search__border {
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.search--location--header .search__input:focus ~ .search__button,
.search--location--header.search--has-suggestions.search--suggestions-open .search__input ~ .search__button {
  fill: #bfbfbf;
}

.search--location--header .search__input:focus ~ .search__button:hover,
.search--location--header.search--has-suggestions.search--suggestions-open .search__input ~ .search__button:hover {
  fill: #3366cc;
}

.search--location--header .search__button {
  border: none;
  padding: 0;
  background: transparent;
  flex-grow: 0;
  width: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #bfbfbf;
  transition: fill .15s;
}

.search--location--header .search__button:hover, .search--location--header .search__button:focus {
  outline: none;
  fill: #3366cc;
}

.search--location--header .search__border {
  z-index: -1;
}

.search--location--header .search__body {
  display: flex;
  flex-direction: column;
}

.search--location--header .search__categories {
  margin: 0;
}

[dir=ltr] .search--location--header .search__categories {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-right: 1px solid #e5e5e5;
}

[dir=rtl] .search--location--header .search__categories {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-left: 1px solid #e5e5e5;
}

.search--location--header .search__categories:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.search--location--header .search__suggestions {
  left: 0;
  top: 0;
  width: calc(100%);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 6px;
  padding-bottom: 10px;
  display: none;
}

.search--location--header.search--has-suggestions.search--suggestions-open .search__suggestions {
  display: block;
}

/*
// .search--location--indicator
*/
.search--location--indicator .search__body {
  color: #3d464d;
  background: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
  width: 340px;
}

.search--location--indicator .search__form {
  display: flex;
  height: 50px;
}

.search--location--indicator .search__suggestions {
  border-top: 1px solid #ebebeb;
  padding: 6px 0;
  display: none;
}

.search--location--indicator .search__input,
.search--location--indicator .search__button {
  border: none;
  padding: 0;
  background: transparent;
}

.search--location--indicator .search__input:focus,
.search--location--indicator .search__button:focus {
  outline: none;
}

.search--location--indicator .search__input {
  padding: 0;
  flex-grow: 1;
  flex-basis: 0;
  width: 0;
}

[dir=ltr] .search--location--indicator .search__input {
  padding-left: 18px;
}

[dir=rtl] .search--location--indicator .search__input {
  padding-right: 18px;
}

.search--location--indicator .search__button {
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  fill: #bfbfbf;
  transition: fill .2s;
}

.search--location--indicator .search__button:hover {
  fill: #3d464d;
}

.search--location--indicator.search--has-suggestions.search--suggestions-open .search__suggestions {
  display: block;
}

/*
// .search--location--mobile-header
*/
.search--location--mobile-header .search__suggestions {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 6px 0;
  display: none;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.mobile-header:not(.mobile-header--stuck) .search--location--mobile-header.search--has-suggestions.search--suggestions-open .search__suggestions,
.mobile-header--stuck.mobile-header--show .search--location--mobile-header.search--has-suggestions.search--suggestions-open .search__suggestions {
  display: block;
}

@media (min-width: 768px) and (max-width: 991px) {
  .search--location--mobile-header .search__body {
    position: relative;
    z-index: 2;
  }
  .search--location--mobile-header .search__form {
    height: 36px;
    display: flex;
  }
  .search--location--mobile-header .search__input {
    position: relative;
    z-index: 1;
    flex-basis: 0;
    width: 0;
    flex-grow: 1;
    background: transparent;
    border: none;
    padding: 0 12px;
    margin: 0;
    font-family: inherit;
    font-size: 15px;
    color: #fff;
  }
  .search--location--mobile-header .search__input::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  .search--location--mobile-header .search__input:hover {
    color: #fff;
  }
  .search--location--mobile-header .search__input:hover::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  .search--location--mobile-header.search--has-suggestions.search--suggestions-open .search__input,
  .search--location--mobile-header .search__input:focus {
    color: #3d464d;
    outline: none;
  }
  .search--location--mobile-header.search--has-suggestions.search--suggestions-open .search__input::placeholder,
  .search--location--mobile-header .search__input:focus::placeholder {
    color: #999;
  }
  .search--location--mobile-header .search__border {
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: none;
    transition: all .2s;
    border-radius: 2px;
    z-index: -1;
  }
  [dir=ltr] .search--location--mobile-header .search__border {
    left: 0;
  }
  [dir=rtl] .search--location--mobile-header .search__border {
    right: 0;
  }
  .search--location--mobile-header .search__button {
    position: relative;
    z-index: 1;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 36px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.4);
    transition: fill .2s;
  }
  .search--location--mobile-header .search__button:focus {
    outline: none;
  }
  .search--location--mobile-header .search__button:focus, .search--location--mobile-header .search__button:hover {
    fill: #3d464d;
  }
  .search--location--mobile-header .search__button--type--close {
    display: none;
  }
  .search--location--mobile-header .search__input:hover ~ .search__button {
    fill: rgba(0, 0, 0, 0.4);
  }
  .search--location--mobile-header .search__input:hover ~ .search__button:focus, .search--location--mobile-header .search__input:hover ~ .search__button:hover {
    fill: #3d464d;
  }
  .search--location--mobile-header .search__input:hover ~ .search__border {
    background: rgba(255, 255, 255, 0.6);
    box-shadow: none;
  }
  .search--location--mobile-header.search--has-suggestions.search--suggestions-open .search__button,
  .search--location--mobile-header .search__input:focus ~ .search__button {
    fill: #b3b3b3;
  }
  .search--location--mobile-header.search--has-suggestions.search--suggestions-open .search__button:focus, .search--location--mobile-header.search--has-suggestions.search--suggestions-open .search__button:hover,
  .search--location--mobile-header .search__input:focus ~ .search__button:focus,
  .search--location--mobile-header .search__input:focus ~ .search__button:hover {
    fill: #3d464d;
  }
  .search--location--mobile-header.search--has-suggestions.search--suggestions-open .search__border,
  .search--location--mobile-header .search__input:focus ~ .search__border {
    background: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 767px) {
  .search--location--mobile-header .search__body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .search--location--mobile-header .search__form {
    display: flex;
    height: 54px;
    flex-shrink: 0;
  }
  .search--location--mobile-header .search__input {
    flex-grow: 1;
    flex-basis: 0;
    width: 0;
    background: transparent;
    border: none;
    padding: 0;
  }
  [dir=ltr] .search--location--mobile-header .search__input {
    padding-left: 15px;
  }
  [dir=rtl] .search--location--mobile-header .search__input {
    padding-right: 15px;
  }
  .search--location--mobile-header .search__input:focus {
    outline: none;
  }
  .search--location--mobile-header .search__input::placeholder {
    color: #999;
  }
  .search--location--mobile-header .search__button {
    flex-shrink: 0;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    cursor: pointer;
    fill: #b3b3b3;
  }
  .search--location--mobile-header .search__button:focus {
    outline: none;
  }
  .search--location--mobile-header .search__button:focus, .search--location--mobile-header .search__button:hover {
    fill: #3d464d;
  }
  [dir=ltr] .search--location--mobile-header .search__button--type--close {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .search--location--mobile-header .search__button--type--close {
    border-right: 1px solid #ebebeb;
  }
}

/*
// .site-header
*/
.site-header {
  position: relative;
  z-index: 100;
  background: #3366cc;
  color: #fff;
}

.site-header__middle {
  display: flex;
  align-items: center;
  height: 104px;
}

.site-header__logo {
  flex-shrink: 0;
  width: 255px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

[dir=ltr] .site-header__logo {
  margin-right: 30px;
}

[dir=rtl] .site-header__logo {
  margin-left: 30px;
}

.site-header__logo a {
  color: inherit;
}

.site-header__logo svg {
  fill: currentColor;
}

.site-header__search {
  flex-grow: 1;
}

.site-header__phone {
  line-height: 1;
  flex-shrink: 0;
}

[dir=ltr] .site-header__phone {
  text-align: right;
  margin-left: 80px;
}

[dir=rtl] .site-header__phone {
  text-align: left;
  margin-right: 80px;
}

.site-header__phone-title {
  font-size: 14px;
  color: #99bbff;
  margin-bottom: 6px;
}

.site-header__phone-number {
  font-size: 20px;
  font-weight: 700;
}

.site-header__nav-panel {
  height: 54px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .site-header__logo {
    width: 210px;
    justify-content: flex-start;
  }
  [dir=ltr] .site-header__logo {
    margin-right: 24px;
  }
  [dir=rtl] .site-header__logo {
    margin-left: 24px;
  }
}

/*
// .suggestions
*/
.suggestions__list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 19px;
}

.suggestions__item {
  position: relative;
  display: flex;
  padding: 8px 18px;
}

.suggestions__item:before {
  z-index: 1;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.suggestions__item-image {
  flex-shrink: 0;
  width: 44px;
  margin: -2px 0;
}

[dir=ltr] .suggestions__item-image {
  margin-right: 12px;
}

[dir=rtl] .suggestions__item-image {
  margin-left: 12px;
}

.suggestions__item-info {
  flex-grow: 1;
  padding: 3px 0 2px;
}

.suggestions__item-name, .suggestions__item-name:hover {
  color: inherit;
}

.suggestions__item-name:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.suggestions__item-meta {
  margin-top: 1px;
  font-size: 13px;
  line-height: 15px;
}

.suggestions__item-price {
  white-space: nowrap;
  flex-shrink: 0;
  padding: 3px 0 2px;
  font-weight: 700;
  font-size: 14px;
}

[dir=ltr] .suggestions__item-price {
  margin-left: 12px;
}

[dir=rtl] .suggestions__item-price {
  margin-right: 12px;
}

.suggestions__item-actions {
  align-self: center;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}

[dir=ltr] .suggestions__item-actions {
  margin-left: 14px;
}

[dir=rtl] .suggestions__item-actions {
  margin-right: 14px;
}

.suggestions--location--header {
  color: #3d464d;
}

.suggestions--location--header .suggestions__item:hover:before {
  background: rgba(0, 0, 0, 0.03);
}

.suggestions--location--header .suggestions__item-meta {
  color: #999;
}

.suggestions--location--indicator {
  color: #3d464d;
}

.suggestions--location--indicator .suggestions__item:hover:before {
  background: rgba(0, 0, 0, 0.03);
}

.suggestions--location--indicator .suggestions__item-meta {
  color: #999;
}

.suggestions--location--indicator .suggestions__item-actions {
  display: none;
}

.suggestions--location--mobile-header {
  color: #3d464d;
}

.suggestions--location--mobile-header .suggestions__item:hover:before {
  background: rgba(0, 0, 0, 0.03);
}

.suggestions--location--mobile-header .suggestions__item-meta {
  color: #999;
}

.suggestions--location--mobile-header .suggestions__item-actions {
  display: none;
}

/*
// .topbar
*/
.topbar {
  height: 34px;
  background: #3366cc;
  box-shadow: none;
  border-bottom: 1px solid #2e5cb8;
  font-size: 14px;
  line-height: 14px;
  color: #99bbff;
  position: relative;
  z-index: 20;
}

.topbar__container {
  height: 100%;
}

.topbar__row {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 0;
}

.topbar__item {
  margin: 0 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.topbar__item-value {
  color: #fff;
  transition: all .1s;
}

.topbar__spring {
  flex-grow: 1;
}

[dir=ltr] .topbar__item--link + .topbar__item--link {
  margin-left: 16px;
}

[dir=rtl] .topbar__item--link + .topbar__item--link {
  margin-right: 16px;
}

/*
// .topbar-dropdown
*/
.topbar-dropdown {
  position: relative;
  height: 100%;
}

.topbar-dropdown__btn {
  height: 100%;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: all .1s;
  background: transparent;
  color: inherit;
  fill: rgba(255, 255, 255, 0.4);
}

[dir=ltr] .topbar-dropdown__btn {
  padding-left: 10px;
  padding-right: 20px;
}

[dir=rtl] .topbar-dropdown__btn {
  padding-right: 10px;
  padding-left: 20px;
}

.topbar-dropdown__btn:focus {
  outline: none;
}

.topbar-dropdown__btn svg {
  position: absolute;
  top: calc(50% - 4px);
}

[dir=ltr] .topbar-dropdown__btn svg {
  right: 7px;
}

[dir=rtl] .topbar-dropdown__btn svg {
  left: 7px;
}

.topbar-dropdown__body {
  position: absolute;
  top: 100%;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

[dir=ltr] .topbar-dropdown__body {
  right: 0;
}

[dir=rtl] .topbar-dropdown__body {
  left: 0;
}

.topbar-dropdown--opened .topbar-dropdown__btn,
.topbar-dropdown__btn:hover,
.topbar-dropdown__btn:focus {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  fill: rgba(255, 255, 255, 0.4);
}

.topbar-dropdown--opened .topbar-dropdown__btn .topbar__item-value,
.topbar-dropdown__btn:hover .topbar__item-value,
.topbar-dropdown__btn:focus .topbar__item-value {
  color: #fff;
}

.topbar-dropdown--opened .topbar-dropdown__body {
  z-index: 1;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}

/*
// .topbar-link
*/
.topbar-link {
  color: inherit;
  transition: color .3s;
}

.topbar-link:hover {
  color: #fff;
}

/* ----------------------------------------
// Footer
// ---------------------------------------- */
/*
// .footer-contacts
*/
.footer-contacts__title {
  margin-bottom: 25px;
}

.footer-contacts__text {
  font-size: 15px;
  line-height: 22px;
  list-style: none;
  margin: 0;
  padding: 0;
}

[dir=ltr] .footer-contacts__text {
  padding-right: 20px;
}

[dir=rtl] .footer-contacts__text {
  padding-left: 20px;
}

.footer-contacts__text a {
  color: inherit;
}

.footer-contacts__contacts {
  font-size: 15px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  margin: 16px 0 0;
}

.footer-contacts__contacts a {
  color: inherit;
}

.footer-contacts__contacts li {
  padding: 4px 0;
  position: relative;
}

.footer-contacts__icon {
  display: inline-block;
  text-align: center;
  width: 22px;
}

[dir=ltr] .footer-contacts__icon {
  margin-right: 2px;
}

[dir=rtl] .footer-contacts__icon {
  margin-left: 2px;
}

@media (max-width: 767px) {
  .footer-contacts {
    text-align: center;
  }
  .footer-contacts__title {
    font-size: 28px;
    margin-bottom: 16px;
  }
  .footer-contacts__text {
    padding: 0;
  }
}

/*
// .footer-links
*/
.footer-links__title {
  margin-bottom: 22px;
}

.footer-links__list {
  font-size: 15px;
  line-height: 28px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links__list a {
  color: inherit;
  transition: .15s;
}

.footer-links__list a:hover {
  color: #3366cc;
}

@media (max-width: 767px) {
  .footer-links__title {
    margin-bottom: 12px;
  }
  .footer-links {
    margin-top: 42px;
    text-align: center;
  }
}

/*
// .footer-newsletter
*/
.footer-newsletter__title {
  margin-bottom: 25px;
}

.footer-newsletter__text {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 14px;
}

.footer-newsletter__text--social {
  margin-top: 20px;
  margin-bottom: 0;
}

.footer-newsletter__social-links {
  display: flex;
  margin-top: 8px;
}

.footer-newsletter__form {
  display: flex;
  max-width: 380px;
}

.footer-newsletter__form-input {
  min-width: 0;
}

[dir=ltr] .footer-newsletter__form-button {
  margin-left: 6px;
}

[dir=rtl] .footer-newsletter__form-button {
  margin-right: 6px;
}

@media (max-width: 991px) {
  .footer-newsletter {
    margin-top: 42px;
    max-width: 420px;
  }
  .footer-newsletter__title {
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .footer-newsletter {
    text-align: center;
  }
  [dir=ltr] .footer-newsletter {
    margin-left: auto;
    margin-right: auto;
  }
  [dir=rtl] .footer-newsletter {
    margin-right: auto;
    margin-left: auto;
  }
  .footer-newsletter__title {
    font-size: 28px;
  }
  .footer-newsletter__form {
    margin: 0 auto;
  }
  .footer-newsletter__social-links {
    margin-top: 16px;
    justify-content: center;
  }
}

/*
// .site-footer
*/
.site-footer {
  margin-top: 16px;
  background: transparent;
  border-top: 1px solid #ebebeb;
}

.site-footer__widgets {
  padding: 48px 0 54px;
}

.site-footer__bottom {
  height: 54px;
  border-top: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-footer__copyright {
  font-size: 14px;
}

.site-footer__copyright a:hover {
  text-decoration: underline;
}

.site-footer__payments img {
  max-width: 100%;
}

.site-footer__widget + .site-footer__widget {
  margin-top: 24px;
}

@media (max-width: 767px) {
  .site-footer__widgets {
    padding-bottom: 48px;
  }
  .site-footer__bottom {
    padding: 20px 0 24px;
    flex-wrap: wrap;
    height: auto;
  }
  .site-footer__payments {
    order: -1;
  }
  .site-footer__copyright + .site-footer__payments {
    margin-bottom: 24px;
  }
  .site-footer__copyright,
  .site-footer__payments {
    width: 100%;
    text-align: center;
  }
}

/*
// .totop
*/
.totop {
  position: relative;
  z-index: 9;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s, visibility 0s .3s;
}

.totop__body {
  position: fixed;
  bottom: 36px;
  left: 0;
  top: auto;
  width: 100%;
  display: flex;
  margin-top: -20px;
}

.totop__container {
  margin: 0 -15px;
}

.totop__start,
.totop__end {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.totop__end {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.totop__end:before {
  content: '';
  flex-grow: 1;
}

.totop__button {
  pointer-events: auto;
  width: 39px;
  height: 39px;
  border-radius: 19.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  cursor: pointer;
  color: #fff;
  background: #3366cc;
  fill: currentColor;
  flex-shrink: 0;
  transition: background-color .15s, color .15s;
}

[dir=ltr] .totop__button {
  margin-left: 80px;
  margin-right: 24px;
}

[dir=rtl] .totop__button {
  margin-right: 80px;
  margin-left: 24px;
}

.totop__button:focus {
  outline: none;
}

@media (hover: hover) {
  .totop__button:hover {
    color: #fff;
    background: #3d464d;
  }
}

.totop__button:active {
  color: #fff;
  background: #546069;
}

.totop__button svg {
  margin-top: -2px;
  margin-bottom: 1px;
}

.totop--show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s, 0s;
}

/* ----------------------------------------
// Pages
// ---------------------------------------- */
/*
// .about-us
*/
.about-us__image {
  background-image: url("../images/aboutus.jpg");
  background-size: cover;
  background-position: bottom center;
  height: 500px;
}

.about-us__body {
  background: #fff;
  min-height: 380px;
  margin-top: -380px;
  border-radius: 4px 4px 0 0;
  padding: 75px 92px;
}

.about-us__title {
  text-align: center;
  margin-bottom: 50px;
}

.about-us__text {
  text-align: center;
}

.about-us__team {
  margin-top: 75px;
  text-align: center;
}

.about-us__team-subtitle {
  margin-top: 24px;
}

.about-us__teammates {
  margin-top: 42px;
}

.teammates .owl-carousel .owl-dots {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.teammates .owl-carousel .owl-dots.disabled {
  display: none;
}

.teammates .owl-carousel .owl-dot {
  display: block;
  color: #e5e5e5;
  transition: color .2s;
}

.teammates .owl-carousel .owl-dot::before {
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: currentColor;
  margin: 6px;
}

.teammates .owl-carousel .owl-dot:focus {
  outline: none;
}

.teammates .owl-carousel .owl-dot:hover {
  color: #ccc;
}

.teammates .owl-carousel .owl-dot.active {
  color: #3366cc;
}

.teammate__avatar img {
  max-width: 100%;
  border-radius: 2px;
}

.teammate__name {
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
}

.teammate__position {
  font-size: 14px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .about-us__image {
    height: 460px;
  }
  .about-us__title {
    margin-bottom: 36px;
  }
  .about-us__body {
    padding: 50px;
  }
  .about-us__team {
    margin-top: 75px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .about-us__image {
    height: 360px;
  }
  .about-us__title {
    margin-bottom: 24px;
  }
  .about-us__body {
    min-height: 290px;
    margin-top: -290px;
    padding: 40px;
  }
  .about-us__team {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .about-us__image {
    height: 330px;
  }
  .about-us__body {
    min-height: 290px;
    margin-top: -290px;
    padding: 30px 24px;
  }
  .about-us__title {
    margin-bottom: 20px;
  }
  .about-us__team {
    margin-top: 50px;
  }
}

/*
// .checkout
*/
.checkout__totals {
  font-size: 15px;
  line-height: 19px;
  width: 100%;
  margin-bottom: 40px;
}

.checkout__totals th, .checkout__totals td {
  padding: 4px 0;
}

.checkout__totals th {
  font-weight: 700;
}

[dir=ltr] .checkout__totals tr > :first-child {
  padding-right: 20px;
}

[dir=rtl] .checkout__totals tr > :first-child {
  padding-left: 20px;
}

.checkout__totals tr > :last-child {
  white-space: nowrap;
}

[dir=ltr] .checkout__totals tr > :last-child {
  text-align: right;
}

[dir=rtl] .checkout__totals tr > :last-child {
  text-align: left;
}

.checkout__totals-header tr:last-child > * {
  padding-bottom: 10px;
}

.checkout__totals-products tr:first-child > * {
  padding-top: 20px;
  border-top: 1px solid #ebebeb;
}

.checkout__totals-products tr:last-child > * {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.checkout__totals-subtotals tr:first-child > * {
  padding-top: 20px;
}

.checkout__totals-subtotals tr:last-child > * {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.checkout__totals-footer {
  font-size: 24px;
}

.checkout__totals-footer th {
  font-weight: 500;
}

.checkout__totals-footer tr:first-child > * {
  padding-top: 20px;
}

.payment-methods {
  margin-bottom: 30px;
}

.payment-methods__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payment-methods__item {
  border-radius: 2px;
  background: transparent;
  border: 1px solid #e5e5e5;
}

.payment-methods__item:hover {
  background: #f7f7f7;
  border: 1px solid #e5e5e5;
}

.payment-methods__item + .payment-methods__item {
  margin-top: 6px;
}

.payment-methods__item-header {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .01em;
  cursor: pointer;
  margin-bottom: 0;
}

[dir=ltr] .payment-methods__item-radio {
  margin-right: 10px;
}

[dir=rtl] .payment-methods__item-radio {
  margin-left: 10px;
}

.payment-methods__item-container {
  overflow: hidden;
  height: 0;
  transition: height .2s;
}

.payment-methods__item-description {
  font-size: 15px;
  line-height: 20px;
  padding: 2px 16px 13px 16px;
}

.payment-methods__item--active, .payment-methods__item--active:hover {
  background: #f7f7f7;
  border: 1px solid #e5e5e5;
}

.payment-methods__item--active .payment-methods__item-container {
  height: auto;
}

.checkout__agree {
  margin-bottom: 36px;
}

.checkout__agree a:hover {
  text-decoration: underline;
}

/*
// .contact-us
*/
.contact-us__map {
  position: relative;
  height: 440px;
  margin: -2px;
  margin-bottom: 6px;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
}

.contact-us__map iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (max-width: 1199px) {
  .contact-us__map {
    height: 400px;
  }
}

@media (max-width: 991px) {
  .contact-us__map {
    height: 380px;
  }
}

@media (max-width: 767px) {
  .contact-us__map {
    height: 360px;
  }
}

@media (max-width: 575px) {
  .contact-us__map {
    height: 320px;
  }
}

/*
// .faq
*/
.faq__section + .faq__section {
  margin-top: 70px;
}

.faq__section-title {
  margin-bottom: 20px;
}

.faq__section-title h3 {
  font-size: 22px;
  font-weight: 500;
}

.faq__section-body {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 48px 44px;
}

@media (max-width: 991px) {
  .faq__section + .faq__section {
    margin-top: 40px;
  }
  .faq__section-body {
    padding: 28px 24px;
  }
  .faq__section-column + .faq__section-column {
    margin-top: 1.5em;
  }
}

/*
// .not-found
*/
.not-found {
  text-align: center;
}

.not-found__404 {
  font-size: 80px;
  font-weight: 700;
  color: #ebebeb;
  padding: 40px 0 20px;
}

.not-found__content {
  width: 480px;
  max-width: 100%;
  margin: 0 auto;
}

.not-found__title {
  margin-bottom: 30px;
}

.not-found__text {
  margin-bottom: 20px;
}

.not-found__search {
  display: flex;
  margin-bottom: 30px;
}

.not-found__search-input {
  width: 1px;
  flex-grow: 1;
}

[dir=ltr] .not-found__search-input {
  margin-right: 10px;
}

[dir=rtl] .not-found__search-input {
  margin-left: 10px;
}

@media (max-width: 991px) {
  .not-found__404 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .not-found__404 {
    font-size: 50px;
    line-height: 54px;
    padding: 40px 0 32px;
  }
}

/* ----------------------------------------
// Shop
// ---------------------------------------- */
/*
// .address-card
*/
.address-card {
  position: relative;
}

.address-card__badge {
  position: absolute;
  background-color: #3366cc;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 8px 2px;
  top: -2px;
}

[dir=ltr] .address-card__badge {
  right: .75rem;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

[dir=rtl] .address-card__badge {
  left: .75rem;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.address-card__badge--muted {
  background-color: #f0f0f0;
  color: #3d464d;
}

.address-card__body {
  padding: 1.5rem;
  font-size: 15px;
  line-height: 18px;
}

.address-card__name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1.125rem;
}

.address-card__row + .address-card__row {
  margin-top: .75rem;
}

.address-card__row-title {
  font-size: 12px;
  color: #999;
}

.address-card__footer {
  margin-top: 1.625rem;
}

.address-card--featured .address-card__body {
  padding: 2rem;
}

.address-card--featured .address-card__name {
  font-weight: 700;
}

@media (min-width: 768px) and (max-width: 991px) {
  .address-card--featured .address-card__body {
    padding: 1.5rem;
  }
}

@media (max-width: 767px) {
  .address-card--featured .address-card__body {
    padding: 1.375rem;
  }
}

/*
// .addresses-list
*/
.addresses-list {
  display: flex;
  flex-wrap: wrap;
}

.addresses-list__item {
  flex-basis: 0;
  flex-grow: 1;
}

.addresses-list__item--new {
  border-radius: 2px;
  border: 2px dashed #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.addresses-list__divider:last-child {
  display: none;
}

.addresses-list__plus {
  width: 60px;
  height: 60px;
  border: 2px dashed #ebebeb;
  position: relative;
  border-radius: 30px;
  margin-bottom: 24px;
  transition: border-color .2s;
}

.addresses-list__plus::before, .addresses-list__plus::after {
  content: '';
  position: absolute;
  width: 32px;
  height: 4px;
  background-color: #e5e5e5;
  border-radius: 1.5px;
  top: calc(50% - 2px);
  transition: background-color .2s;
}

[dir=ltr] .addresses-list__plus::before, [dir=ltr] .addresses-list__plus::after {
  left: calc(50% - 16px);
}

[dir=rtl] .addresses-list__plus::before, [dir=rtl] .addresses-list__plus::after {
  right: calc(50% - 16px);
}

.addresses-list__plus::after {
  transform: rotateZ(90deg);
}

.addresses-list__item--new:hover .addresses-list__plus {
  border-color: #e0e0e0;
}

.addresses-list__item--new:hover .addresses-list__plus::before, .addresses-list__item--new:hover .addresses-list__plus::after {
  background-color: #dbdbdb;
}

@media (min-width: 1200px) {
  .addresses-list__item {
    max-width: calc((100% - 32px) / 3);
  }
  .addresses-list__divider:nth-child(2n) {
    width: 16px;
  }
  .addresses-list__divider:nth-child(6n) {
    width: 100%;
    height: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .addresses-list__item {
    max-width: calc((100% - 24px) / 3);
  }
  .addresses-list__divider:nth-child(2n) {
    width: 12px;
  }
  .addresses-list__divider:nth-child(6n) {
    width: 100%;
    height: 12px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .addresses-list__item {
    max-width: calc((100% - 12px) / 2);
  }
  .addresses-list__divider:nth-child(2n) {
    width: 12px;
  }
  .addresses-list__divider:nth-child(4n) {
    width: 100%;
    height: 12px;
  }
}

@media (max-width: 479px) {
  .addresses-list__item {
    max-width: calc((100% - 0px) / 1);
  }
  .addresses-list__divider:nth-child(2n) {
    width: 12px;
  }
  .addresses-list__divider:nth-child(2n) {
    width: 100%;
    height: 12px;
  }
}

/*
// .cart
*/
.cart__actions {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.cart__coupon-form {
  width: calc(50% - 15px);
  display: flex;
}

.cart__coupon-form .btn {
  flex-shrink: 0;
}

[dir=ltr] .cart__coupon-form .btn {
  margin-left: .5rem;
}

[dir=rtl] .cart__coupon-form .btn {
  margin-right: .5rem;
}

.cart__buttons {
  width: calc(50% - 15px);
  display: flex;
  justify-content: flex-end;
}

[dir=ltr] .cart__update-button {
  margin-left: .5rem;
}

[dir=rtl] .cart__update-button {
  margin-right: .5rem;
}

.cart__totals {
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  margin-bottom: 40px;
}

.cart__totals a:hover {
  text-decoration: underline;
}

.cart__totals th, .cart__totals td {
  vertical-align: top;
  padding: 6px 0;
}

.cart__totals th {
  font-weight: 700;
}

[dir=ltr] .cart__totals tr > :first-child {
  padding-right: 20px;
}

[dir=rtl] .cart__totals tr > :first-child {
  padding-left: 20px;
}

[dir=ltr] .cart__totals tr > :last-child {
  text-align: right;
}

[dir=rtl] .cart__totals tr > :last-child {
  text-align: left;
}

.cart__totals-header tr:last-child > * {
  padding-bottom: 10px;
}

.cart__totals-body tr:first-child > * {
  padding-top: 20px;
  border-top: 1px solid #ebebeb;
}

.cart__totals-body tr:last-child > * {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.cart__totals-footer {
  font-size: 24px;
}

.cart__totals-footer th {
  font-weight: 500;
}

.cart__totals-footer tr:first-child > * {
  padding-top: 20px;
}

.cart__calc-shipping {
  margin-top: 2px;
  font-size: 15px;
}

@media (max-width: 767px) {
  .cart__actions {
    margin-top: 1.5rem;
    display: block;
  }
  .cart__coupon-form {
    width: 100%;
  }
  .cart__buttons {
    flex-direction: column;
    margin-top: 2.5rem;
    width: 100%;
  }
  .cart__update-button {
    order: -1;
    margin-bottom: .75rem;
  }
  [dir=ltr] .cart__update-button {
    margin-left: 0;
  }
  [dir=rtl] .cart__update-button {
    margin-right: 0;
  }
}

@media (max-width: 479px) {
  .cart__checkout-button {
    font-size: 1.125rem;
    height: calc(2.75rem + 2px);
    padding: .8125rem 2rem;
    line-height: 1;
    font-weight: 500;
  }
  .cart__checkout-button.btn-svg-icon {
    width: calc(2.75rem + 2px);
  }
}

@media (max-width: 379px) {
  .cart__checkout-button {
    font-size: 1rem;
    height: calc(2.25rem + 2px);
    line-height: 1.5;
    padding: .375rem 1.25rem;
    font-weight: 500;
  }
  .cart__checkout-button.btn-svg-icon {
    width: calc(2.25rem + 2px);
  }
}

/*
// .cart-table
*/
.cart-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.cart-table__product-name {
  color: inherit;
}

.cart-table__options {
  list-style: none;
  padding: 0;
  margin: 4px 0 0;
  font-size: 14px;
  color: #999;
  line-height: 19px;
}

.cart-table__options li {
  position: relative;
}

[dir=ltr] .cart-table__options li {
  padding-left: 12px;
}

[dir=rtl] .cart-table__options li {
  padding-right: 12px;
}

.cart-table__options li::before {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: currentColor;
}

[dir=ltr] .cart-table__options li::before {
  left: 1px;
}

[dir=rtl] .cart-table__options li::before {
  right: 1px;
}

@media (min-width: 768px) {
  .cart-table__column {
    padding: 12px 18px;
    border-top: 1px solid #ebebeb;
  }
  [dir=ltr] .cart-table__column:first-child {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .cart-table__column:first-child {
    border-right: 1px solid #ebebeb;
  }
  [dir=ltr] .cart-table__column:last-child {
    border-right: 1px solid #ebebeb;
  }
  [dir=rtl] .cart-table__column:last-child {
    border-left: 1px solid #ebebeb;
  }
  .cart-table__head {
    background: #f7f7f7;
    font-size: 15px;
  }
  .cart-table__head .cart-table__column {
    font-weight: 500;
  }
  [dir=ltr] .cart-table__head .cart-table__column:first-child {
    border-top-left-radius: 2.5px;
  }
  [dir=rtl] .cart-table__head .cart-table__column:first-child {
    border-top-right-radius: 2.5px;
  }
  [dir=ltr] .cart-table__head .cart-table__column:last-child {
    border-top-right-radius: 2.5px;
  }
  [dir=rtl] .cart-table__head .cart-table__column:last-child {
    border-top-left-radius: 2.5px;
  }
  .cart-table__body .cart-table__column {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .cart-table__body .cart-table__row:last-child .cart-table__column {
    border-bottom: 1px solid #ebebeb;
  }
  [dir=ltr] .cart-table__body .cart-table__row:last-child .cart-table__column:first-child {
    border-bottom-left-radius: 2.5px;
  }
  [dir=rtl] .cart-table__body .cart-table__row:last-child .cart-table__column:first-child {
    border-bottom-right-radius: 2.5px;
  }
  [dir=ltr] .cart-table__body .cart-table__row:last-child .cart-table__column:last-child {
    border-bottom-right-radius: 2.5px;
  }
  [dir=rtl] .cart-table__body .cart-table__row:last-child .cart-table__column:last-child {
    border-bottom-left-radius: 2.5px;
  }
  .cart-table__column--image {
    width: 1px;
    text-align: center;
  }
  [dir=ltr] .cart-table__column--image {
    padding-right: 4px;
  }
  [dir=rtl] .cart-table__column--image {
    padding-left: 4px;
  }
  .cart-table__column--image .product-image {
    width: 80px;
  }
  .cart-table__column--price {
    width: 170px;
  }
  [dir=ltr] .cart-table__column--price {
    text-align: right;
  }
  [dir=rtl] .cart-table__column--price {
    text-align: left;
  }
  .cart-table__column--quantity {
    text-align: center;
    width: 1px;
  }
  [dir=ltr] .cart-table__column--quantity {
    padding-left: 90px;
  }
  [dir=rtl] .cart-table__column--quantity {
    padding-right: 90px;
  }
  .cart-table__column--total {
    width: 170px;
  }
  [dir=ltr] .cart-table__column--total {
    text-align: right;
  }
  [dir=rtl] .cart-table__column--total {
    text-align: left;
  }
  .cart-table__column--remove {
    width: 1px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  [dir=ltr] .cart-table__column--quantity {
    padding-left: 40px;
  }
  [dir=rtl] .cart-table__column--quantity {
    padding-right: 40px;
  }
  .cart-table__column--price,
  .cart-table__column--total {
    width: 130px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  [dir=ltr] .cart-table__column--quantity {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .cart-table__column--quantity {
    padding-right: 0;
    padding-left: 0;
  }
  .cart-table__column--price,
  .cart-table__column--total {
    width: 1px;
  }
  [dir=ltr] .cart-table__column--remove {
    padding-left: 0;
  }
  [dir=rtl] .cart-table__column--remove {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .cart-table {
    display: block;
  }
  .cart-table__head {
    display: none;
  }
  .cart-table__body {
    display: flex;
    flex-wrap: wrap;
  }
  .cart-table__row {
    position: relative;
    width: 100%;
    display: block;
    border: 1px solid #ebebeb;
    border-radius: 2px;
  }
  .cart-table__row + .cart-table__row {
    margin-top: 15px;
  }
  .cart-table__column {
    display: block;
    padding: 0;
  }
  .cart-table__column--image {
    padding: 20px 20px 12px;
    text-align: center;
  }
  .cart-table__column--image .product-image {
    width: 100%;
    max-width: 160px;
    margin: 0 auto;
  }
  .cart-table__column--product {
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    padding: 0 20px 18px;
  }
  .cart-table__column--price,
  .cart-table__column--quantity,
  .cart-table__column--total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
  }
  .cart-table__column--price::before,
  .cart-table__column--quantity::before,
  .cart-table__column--total::before {
    font-weight: 500;
    width: 55%;
    flex-shrink: 0;
    content: attr(data-title) ": ";
  }
  [dir=ltr] .cart-table__column--price::before, [dir=ltr]
  .cart-table__column--quantity::before, [dir=ltr]
  .cart-table__column--total::before {
    text-align: right;
  }
  [dir=rtl] .cart-table__column--price::before, [dir=rtl]
  .cart-table__column--quantity::before, [dir=rtl]
  .cart-table__column--total::before {
    text-align: left;
  }
  .cart-table__column--quantity .input-number {
    width: 110px;
  }
  .cart-table__column--price {
    padding-top: 18px;
  }
  .cart-table__column--total {
    padding-bottom: 18px;
  }
  .cart-table__column--remove {
    position: absolute;
    top: 5px;
  }
  [dir=ltr] .cart-table__column--remove {
    right: 5px;
  }
  [dir=rtl] .cart-table__column--remove {
    left: 5px;
  }
  .cart-table__options li::before {
    display: none;
  }
}

/*
// .category-card
*/
.category-card {
  display: flex;
}

.category-card__body {
  display: flex;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.04);
  padding: 24px 20px;
  flex-grow: 1;
}

.category-card__image {
  width: 130px;
}

[dir=ltr] .category-card__image {
  margin-right: 24px;
}

[dir=rtl] .category-card__image {
  margin-left: 24px;
}

.category-card__image img {
  max-width: 100%;
}

.category-card__content {
  display: flex;
  flex-direction: column;
}

.category-card__name {
  margin-top: -2px;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
}

.category-card__name a {
  color: inherit;
  transition: .15s;
}

.category-card__name a:hover {
  color: #3366cc;
}

.category-card__links {
  list-style: none;
  margin: 14px 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  flex-grow: 1;
}

.category-card__links a {
  color: #6c757d;
  transition: .15s;
}

.category-card__links a:hover {
  color: #3366cc;
}

.category-card__all {
  margin-top: 14px;
  font-size: 14px;
}

.category-card__all a:hover {
  text-decoration: underline;
}

.category-card__products {
  display: none;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .category-card--layout--classic .category-card__image {
    width: 90px;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .category-card--layout--classic .category-card__body {
    flex-direction: column;
    padding: 24px 28px;
  }
  .category-card--layout--classic .category-card__image {
    width: auto;
    margin: 0 0 18px;
  }
}

@media (min-width: 400px) and (max-width: 479px) {
  .category-card--layout--classic .category-card__image {
    width: 40%;
  }
}

@media (max-width: 399px) {
  .category-card--layout--classic .category-card__body {
    flex-direction: column;
    padding: 24px 28px;
    text-align: center;
  }
  .category-card--layout--classic .category-card__image {
    width: 120px;
    margin: 0 auto 18px;
  }
}

.category-card--layout--compact .category-card__body {
  padding: 16px 30px;
  align-items: center;
}

.category-card--layout--compact .category-card__image {
  width: 74px;
}

[dir=ltr] .category-card--layout--compact .category-card__image {
  margin-right: 30px;
}

[dir=rtl] .category-card--layout--compact .category-card__image {
  margin-left: 30px;
}

.category-card--layout--compact .category-card__links {
  display: none;
}

.category-card--layout--compact .category-card__all {
  display: none;
}

.category-card--layout--compact .category-card__name {
  margin-top: 0;
  font-size: 17px;
  line-height: 19px;
}

.category-card--layout--compact .category-card__products {
  display: block;
  font-size: 15px;
  color: #999;
  margin-top: 5px;
}

/*
// .compare-table
*/
.compare-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 15px;
  line-height: 18px;
}

.compare-table tr > * {
  border-top: 1px solid #ebebeb;
}

[dir=ltr] .compare-table tr > * {
  border-left: 1px solid #ebebeb;
}

[dir=rtl] .compare-table tr > * {
  border-right: 1px solid #ebebeb;
}

[dir=ltr] .compare-table tr > *:last-child {
  border-right: 1px solid #ebebeb;
}

[dir=rtl] .compare-table tr > *:last-child {
  border-left: 1px solid #ebebeb;
}

[dir=ltr] .compare-table > *:first-child > tr:first-child > *:first-child {
  border-top-left-radius: 2.5px;
}

[dir=rtl] .compare-table > *:first-child > tr:first-child > *:first-child {
  border-top-right-radius: 2.5px;
}

[dir=ltr] .compare-table > *:first-child > tr:first-child > *:last-child {
  border-top-right-radius: 2.5px;
}

[dir=rtl] .compare-table > *:first-child > tr:first-child > *:last-child {
  border-top-left-radius: 2.5px;
}

[dir=ltr] .compare-table > *:last-child > tr:last-child > *:first-child {
  border-bottom-left-radius: 2.5px;
}

[dir=rtl] .compare-table > *:last-child > tr:last-child > *:first-child {
  border-bottom-right-radius: 2.5px;
}

[dir=ltr] .compare-table > *:last-child > tr:last-child > *:last-child {
  border-bottom-right-radius: 2.5px;
}

[dir=rtl] .compare-table > *:last-child > tr:last-child > *:last-child {
  border-bottom-left-radius: 2.5px;
}

.compare-table > *:last-child > tr:last-child > * {
  border-bottom: 1px solid #ebebeb;
}

.compare-table th {
  min-width: 140px;
  background: #f7f7f7;
  font-weight: 500;
}

[dir=ltr] .compare-table th {
  text-align: right;
}

[dir=rtl] .compare-table th {
  text-align: left;
}

.compare-table td {
  min-width: 180px;
  max-width: 180px;
  text-align: center;
  vertical-align: top;
}

.compare-table td, .compare-table th {
  padding: 14px 16px;
}

.compare-table__product-link {
  display: block;
  padding: 8px 8px 0;
  color: inherit;
}

.compare-table__product-image {
  margin: 0 auto 14px;
  width: 140px;
  max-width: 100%;
}

.compare-table__product-name {
  line-height: 20px;
}

.compare-table__product-rating {
  padding-top: 3px;
  display: flex;
  justify-content: center;
}

.compare-table__product-rating-legend {
  margin-top: 8px;
  font-size: 13px;
  line-height: 1;
  color: #b3b3b3;
}

.compare-table__product-badge {
  font-size: 12px;
}

/*
// .dashboard
*/
.dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboard__orders {
  margin-top: 24px;
  width: 100%;
}

@media (min-width: 768px) {
  .dashboard__profile,
  .dashboard__address {
    width: calc(50% - 12px);
  }
}

@media (max-width: 767px) {
  .dashboard__profile,
  .dashboard__address {
    width: 100%;
  }
  .dashboard__address {
    margin-top: 24px;
  }
}

/*
// .order-header
*/
.order-header {
  padding: 1.625rem 2rem 1.375rem;
}

.order-header:after {
  display: block;
  content: '';
  clear: both;
}

.order-header__actions {
  margin-top: -1px;
}

[dir=ltr] .order-header__actions {
  float: right;
}

[dir=rtl] .order-header__actions {
  float: left;
}

.order-header__title {
  border-bottom: 2px solid #e5e5e5;
  padding-bottom: 4px;
  margin-bottom: 0;
}

[dir=ltr] .order-header__title {
  padding-right: 3.5rem;
  float: left;
}

[dir=rtl] .order-header__title {
  padding-left: 3.5rem;
  float: right;
}

.order-header__subtitle {
  color: #6c757d;
  font-size: 14px;
  line-height: 1.375;
  clear: both;
  padding-top: 12px;
}

.order-header__subtitle mark {
  padding: 0;
  color: #3d464d;
  background: transparent;
  font-weight: 500;
}

@media (min-width: 768px) and (max-width: 991px) {
  .order-header {
    padding: 1.375rem 1.5rem 1.25rem;
  }
}

@media (max-width: 767px) {
  .order-header {
    padding: 1.125rem 1.375rem 1rem;
  }
}

@media (max-width: 440px) {
  .order-header__actions {
    margin-bottom: .875rem;
  }
  [dir=ltr] .order-header__actions {
    float: none;
  }
  [dir=rtl] .order-header__actions {
    float: none;
  }
}

/*
// .order-list
*/
.order-list a {
  color: inherit;
}

.order-list a:hover {
  color: inherit;
  text-decoration: underline;
}

.order-list table {
  width: 100%;
  font-size: 15px;
}

.order-list__header th,
.order-list__header td {
  text-transform: uppercase;
  color: #6c757d;
  font-size: 13px;
  font-weight: 500;
}

.order-list__footer {
  font-size: 20px;
}

.order-list__footer th {
  font-weight: 500;
}

.order-list__column-image {
  width: 1px;
}

.order-list__column-image .product-image {
  width: 40px;
}

.order-list__column-product {
  line-height: 1.25;
}

.order-list__column-quantity {
  text-align: center;
}

[dir=ltr] .order-list__column-total {
  text-align: right;
}

[dir=rtl] .order-list__column-total {
  text-align: left;
}

.order-list__options {
  color: #6c757d;
  font-size: 13px;
  margin-top: 2px;
}

.order-list__options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.order-list__options-item:not(:last-child) {
  position: relative;
}

[dir=ltr] .order-list__options-item:not(:last-child) {
  padding-right: 19px;
}

[dir=rtl] .order-list__options-item:not(:last-child) {
  padding-left: 19px;
}

.order-list__options-item:not(:last-child):after {
  position: absolute;
  content: '';
  display: block;
  background: #ccc;
  width: 1px;
  height: 10px;
  top: calc(50% - 5px);
  transform: skewX(-20deg);
}

[dir=ltr] .order-list__options-item:not(:last-child):after {
  right: 9px;
}

[dir=rtl] .order-list__options-item:not(:last-child):after {
  left: 9px;
}

@media (min-width: 576px) {
  .order-list th,
  .order-list td {
    padding: 4px 12px;
  }
  [dir=ltr] .order-list th:first-child, [dir=ltr]
  .order-list td:first-child {
    padding-left: 1.5rem;
  }
  [dir=rtl] .order-list th:first-child, [dir=rtl]
  .order-list td:first-child {
    padding-right: 1.5rem;
  }
  [dir=ltr] .order-list th:last-child, [dir=ltr]
  .order-list td:last-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .order-list th:last-child, [dir=rtl]
  .order-list td:last-child {
    padding-left: 1.5rem;
  }
  .order-list__header th,
  .order-list__header td {
    border-bottom: 2px solid #f0f0f0;
    padding: 10px 12px;
  }
  .order-list__products th,
  .order-list__products td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .order-list__products tr:first-child th,
  .order-list__products tr:first-child td {
    padding-top: 14px;
  }
  .order-list__products tr:last-child th,
  .order-list__products tr:last-child td {
    padding-bottom: 14px;
  }
  [dir=ltr] .order-list__products .order-list__column-product {
    padding-left: 4px;
  }
  [dir=rtl] .order-list__products .order-list__column-product {
    padding-right: 4px;
  }
  .order-list__subtotals th,
  .order-list__subtotals td {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .order-list__subtotals tr:first-child th,
  .order-list__subtotals tr:first-child td {
    padding-top: 12px;
    border-top: 1px solid #f0f0f0;
  }
  .order-list__subtotals tr:last-child th,
  .order-list__subtotals tr:last-child td {
    padding-bottom: 12px;
  }
  .order-list__footer th,
  .order-list__footer td {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid #f0f0f0;
  }
}

@media (max-width: 575px) {
  .order-list table,
  .order-list tr,
  .order-list th,
  .order-list td {
    display: block;
    padding: 0;
  }
  .order-list__header,
  .order-list__products,
  .order-list__subtotals,
  .order-list__footer {
    display: block;
  }
  .order-list__header tr,
  .order-list__products tr,
  .order-list__subtotals tr,
  .order-list__footer tr {
    padding: 0 1rem;
  }
  .order-list__header tr,
  .order-list__subtotals tr,
  .order-list__footer tr {
    display: flex;
  }
  .order-list__header .order-list__column-label,
  .order-list__subtotals .order-list__column-label,
  .order-list__footer .order-list__column-label {
    flex-grow: 1;
  }
  .order-list__header .order-list__column-quantity,
  .order-list__subtotals .order-list__column-quantity,
  .order-list__footer .order-list__column-quantity {
    display: none;
  }
  .order-list__header tr {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
  }
  .order-list__products tr {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: flex;
    flex-wrap: wrap;
  }
  .order-list__products tr:not(:first-child) {
    border-top: 1px solid #f0f0f0;
  }
  .order-list__products .order-list__column-image {
    width: 40px;
    flex-shrink: 0;
  }
  .order-list__products .order-list__column-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 40px);
  }
  [dir=ltr] .order-list__products .order-list__column-product {
    padding-left: 1rem;
  }
  [dir=rtl] .order-list__products .order-list__column-product {
    padding-right: 1rem;
  }
  .order-list__products .order-list__column-quantity:before {
    content: attr(data-title) " ";
  }
  .order-list__products .order-list__column-total {
    flex-grow: 1;
  }
  .order-list__products .order-list__column-quantity,
  .order-list__products .order-list__column-total {
    margin-top: .625rem;
    margin-bottom: -.125rem;
  }
  .order-list__subtotals {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #f0f0f0;
  }
  .order-list__subtotals th,
  .order-list__subtotals td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .order-list__footer {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #f0f0f0;
  }
}

/*
// .order-success
*/
.order-success__body {
  max-width: 690px;
  margin: 0 auto;
}

.order-success__header {
  padding: 60px 0 52px;
  text-align: center;
}

.order-success__icon {
  fill: #3366cc;
  margin: 0 auto;
  display: block;
}

.order-success__title {
  margin-top: 24px;
  margin-bottom: 2px;
}

.order-success__subtitle {
  font-size: 19px;
  font-weight: 500;
}

.order-success__actions {
  margin-top: 20px;
}

.order-success__meta {
  margin-bottom: 28px;
  text-align: center;
}

.order-success__meta-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.order-success__meta-item {
  flex-basis: 25%;
  line-height: 1.25;
  position: relative;
  padding: 2px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.order-success__meta-item:not(:last-child):before {
  position: absolute;
  display: block;
  content: '';
  border-left: 2px dashed #ebebeb;
  width: 0;
  height: 100%;
  top: 0;
}

[dir=ltr] .order-success__meta-item:not(:last-child):before {
  right: -1px;
}

[dir=rtl] .order-success__meta-item:not(:last-child):before {
  left: -1px;
}

.order-success__meta-title {
  display: block;
  font-size: 13px;
  color: #6c757d;
  margin-bottom: 4px;
}

.order-success__meta-value {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .order-success__header {
    padding: 44px 0 40px;
  }
  .order-success__meta-list {
    flex-wrap: wrap;
  }
  .order-success__meta-item {
    flex-basis: 50%;
  }
  .order-success__meta-item:nth-child(2n):before {
    display: none;
  }
  .order-success__meta-item:nth-child(n + 3) {
    margin-top: 16px;
  }
}

/*
// .product
*/
.product__content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
  grid-template-columns: [gallery] calc(50% - 20px) [info] calc(50% - 20px);
  grid-template-rows: auto auto auto auto auto;
  grid-column-gap: 40px;
}

.product__gallery {
  -ms-grid-row: 1;
  -ms-grid-row-span: 6;
  -ms-grid-column: 1;
  grid-row-start: 1;
  grid-row-end: 6;
  min-height: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  [dir=ltr] .product__gallery {
    margin-right: 40px;
  }
  [dir=rtl] .product__gallery {
    margin-left: 40px;
  }
}

.product__info {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  position: relative;
  min-height: 0;
}

.product__sidebar {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
}

.product__footer {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
}

.product__wishlist-compare {
  display: none;
}

.product__name {
  margin-bottom: 22px;
}

.product__rating {
  display: flex;
  margin-bottom: 5px;
}

.product__rating-stars {
  padding-top: 2px;
}

[dir=ltr] .product__rating-stars {
  margin-right: 12px;
}

[dir=rtl] .product__rating-stars {
  margin-left: 12px;
}

.product__rating-legend {
  font-size: 14px;
  line-height: 20px;
  color: #b3b3b3;
}

.product__rating-legend a {
  color: inherit;
  transition: color .2s;
}

.product__rating-legend a:hover {
  color: #3366cc;
}

.product__rating-legend span {
  content: '/';
  padding: 0 7px;
}

.product__description {
  font-size: 16px;
}

.product__features {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.product__features li {
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;
}

[dir=ltr] .product__features li {
  padding-left: 13px;
  padding-right: 0;
}

[dir=rtl] .product__features li {
  padding-right: 13px;
  padding-left: 0;
}

.product__features li::before {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  border: 1px solid currentColor;
}

[dir=ltr] .product__features li::before {
  left: 0;
}

[dir=rtl] .product__features li::before {
  right: 0;
}

.product__meta {
  list-style: none;
  margin: 12px 0 0;
  padding: 12px 0 0;
  display: flex;
  flex-wrap: wrap;
  color: #999;
  font-size: 14px;
  border-top: 1px solid #ebebeb;
}

[dir=ltr] .product__meta li {
  margin-right: 18px;
}

[dir=rtl] .product__meta li {
  margin-left: 18px;
}

.product__meta a {
  color: inherit;
  transition: color .2s;
}

.product__meta a:hover {
  color: #3366cc;
}

.product__availability {
  display: none;
}

.product__prices {
  margin-top: 18px;
  margin-bottom: 14px;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -.03em;
  color: #3d464d;
}

.product__new-price {
  color: #ff2626;
}

.product__old-price {
  color: #b3b3b3;
  font-weight: 400;
  font-size: 20px;
  text-decoration: line-through;
}

.product__option {
  margin-bottom: 18px;
}

.product__option:last-child {
  margin-bottom: 0;
}

.product__option-label {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  color: #6c757d;
  padding-bottom: 2px;
}

.product__actions {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.product__actions-item {
  margin: 4px;
}

.product__quantity {
  width: 120px;
}

.product__footer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.product__share-links {
  margin-top: 2px;
  margin-bottom: 2px;
}

[dir=ltr] .product__share-links {
  margin-left: 24px;
  margin-right: 0;
}

[dir=rtl] .product__share-links {
  margin-right: 24px;
  margin-left: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product__name {
    font-size: 24px;
    margin-bottom: 18px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product__content {
    -ms-grid-columns: 44% 56%;
    grid-template-columns: [gallery] calc(44% - 15px) [info] calc(56% - 15px);
    grid-column-gap: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) and (-ms-high-contrast: active), (min-width: 768px) and (max-width: 991px) and (-ms-high-contrast: none) {
  [dir=ltr] .product__gallery {
    margin-right: 30px;
  }
  [dir=rtl] .product__gallery {
    margin-left: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product__name {
    font-size: 24px;
    margin-bottom: 18px;
  }
  .product__footer {
    display: block;
    margin-top: 18px;
  }
  .product__share-links {
    margin: 12px 0 0;
  }
}

@media (max-width: 767px) {
  .product__content {
    display: block;
  }
  .product__gallery {
    margin-bottom: 24px;
  }
  .product__name {
    font-size: 24px;
    margin-bottom: 18px;
  }
}

@media (max-width: 559px) {
  .product__footer {
    display: block;
    margin-top: 24px;
  }
  .product__share-links {
    margin: 12px 0 0;
  }
}

/*
// .product--layout--columnar
*/
@media (min-width: 992px) {
  .product--layout--columnar .product__content {
    -ms-grid-columns: 380px auto  260px;
    grid-template-columns: [gallery] 380px [info] auto [sidebar] 260px;
    grid-template-rows: auto auto auto auto;
    grid-column-gap: 0;
  }
  .product--layout--columnar .product__gallery {
    grid-row-start: 1;
    grid-row-end: 4;
    min-height: 0;
  }
}

@media screen and (min-width: 992px) and (-ms-high-contrast: active), (min-width: 992px) and (-ms-high-contrast: none) {
  [dir=ltr] .product--layout--columnar .product__gallery {
    margin-right: 0;
  }
  [dir=rtl] .product--layout--columnar .product__gallery {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .product--layout--columnar .product__info {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-row: 1;
    grid-column: info;
    padding: 0 30px;
    min-height: 0;
  }
  .product--layout--columnar .product__sidebar {
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    -ms-grid-column: 3;
    grid-column: sidebar;
    grid-row-start: 1;
    grid-row-end: 4;
    min-height: 0;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  [dir=ltr] .product--layout--columnar .product__sidebar {
    border-left: 2px solid #f0f0f0;
    padding-left: 30px;
  }
  [dir=rtl] .product--layout--columnar .product__sidebar {
    border-right: 2px solid #f0f0f0;
    padding-right: 30px;
  }
  .product--layout--columnar .product__footer {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    grid-row: 2;
    grid-column: info;
    padding: 0 30px;
    min-height: 0;
  }
  .product--layout--columnar .product__wishlist-compare {
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  [dir=ltr] .product--layout--columnar .product__wishlist-compare {
    right: 8px;
  }
  [dir=rtl] .product--layout--columnar .product__wishlist-compare {
    left: 8px;
  }
  .product--layout--columnar .product__wishlist-compare > * + * {
    margin-top: 2px;
  }
  .product--layout--columnar .product__name {
    font-size: 24px;
    margin-bottom: 12px;
  }
  [dir=ltr] .product--layout--columnar .product__name {
    padding-right: 16px;
  }
  [dir=rtl] .product--layout--columnar .product__name {
    padding-left: 16px;
  }
  .product--layout--columnar .product__description {
    margin: 10px 0 12px;
    font-size: 15px;
  }
  .product--layout--columnar .product__features {
    display: block;
  }
  .product--layout--columnar .product__meta {
    margin: 12px 0 0;
    padding: 0;
    border-top: none;
  }
  .product--layout--columnar .product__meta-availability {
    display: none;
  }
  .product--layout--columnar .product__footer {
    flex-direction: column;
  }
  .product--layout--columnar .product__share-links {
    margin-top: 12px;
    margin-bottom: 0;
  }
  [dir=ltr] .product--layout--columnar .product__share-links {
    margin-left: 1px;
    margin-right: 0;
  }
  [dir=rtl] .product--layout--columnar .product__share-links {
    margin-right: 1px;
    margin-left: 0;
  }
  .product--layout--columnar .product__prices {
    margin-top: 20px;
    margin-bottom: 24px;
    line-height: 28px;
  }
  .product--layout--columnar .product__new-price {
    display: block;
  }
  .product--layout--columnar .product__quantity {
    width: 88px;
  }
  .product--layout--columnar .product__actions {
    flex-wrap: nowrap;
  }
  .product--layout--columnar .product__actions-item--addtocart {
    flex-grow: 1;
  }
  .product--layout--columnar .product__actions-item--addtocart .btn {
    width: 100%;
  }
  [dir=ltr] .product--layout--columnar .product__actions-item--addtocart .btn {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  [dir=rtl] .product--layout--columnar .product__actions-item--addtocart .btn {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .product--layout--columnar .product__actions-item--wishlist,
  .product--layout--columnar .product__actions-item--compare {
    display: none;
  }
  .product--layout--columnar .product__availability {
    display: block;
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product--layout--columnar .product__content {
    -ms-grid-columns: 320px auto  200px;
    grid-template-columns: [gallery] 320px [info] auto [sidebar] 200px;
  }
  [dir=ltr] .product--layout--columnar .product__sidebar {
    padding-left: 24px;
  }
  [dir=rtl] .product--layout--columnar .product__sidebar {
    padding-right: 24px;
  }
  .product--layout--columnar .product__option {
    margin-bottom: 12px;
  }
  .product--layout--columnar .product__actions {
    flex-wrap: wrap;
    margin: 0;
  }
  .product--layout--columnar .product__quantity {
    width: 100px;
  }
  .product--layout--columnar .product__actions-item {
    margin: 0;
  }
  .product--layout--columnar .product__actions-item--addtocart {
    margin-top: 16px;
  }
}

@media (min-width: 992px) {
  .product--layout--sidebar .product__content {
    -ms-grid-columns: 50% 50%;
    grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
    grid-column-gap: 32px;
  }
}

@media screen and (min-width: 992px) and (-ms-high-contrast: active), (min-width: 992px) and (-ms-high-contrast: none) {
  [dir=ltr] .product--layout--sidebar .product__gallery {
    margin-right: 32px;
  }
  [dir=rtl] .product--layout--sidebar .product__gallery {
    margin-left: 32px;
  }
}

@media (min-width: 992px) {
  .product--layout--sidebar .product__name {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .product--layout--sidebar .product__footer {
    display: block;
    margin-top: 18px;
  }
  .product--layout--sidebar .product__share-links {
    margin: 12px 0 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product--layout--quickview .product__content {
    grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
    grid-column-gap: 32px;
  }
  .product--layout--quickview .product__name {
    margin-bottom: 12px;
  }
  .product--layout--quickview .product__footer {
    display: block;
    margin-top: 18px;
  }
  .product--layout--quickview .product__share-links {
    margin: 12px 0 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product--layout--quickview .product__content {
    display: block;
  }
  .product--layout--quickview .product__gallery {
    margin-bottom: 24px;
  }
  .product--layout--quickview .product__name {
    font-size: 24px;
    margin-bottom: 18px;
  }
}

/*
// .product-gallery
*/
.product-gallery__featured {
  position: relative;
  box-shadow: 0 0 0 2px #f2f2f2 inset;
  padding: 2px;
  border-radius: 2px;
}

.product-gallery__featured .product-image {
  padding: 20px;
}

.product-gallery__zoom {
  position: absolute;
  cursor: pointer;
  padding: 11px;
  border: none;
  z-index: 2;
  top: 0;
  background: transparent;
  fill: #d9d9d9;
  transition: background .2s, fill .2s;
}

[dir=ltr] .product-gallery__zoom {
  right: 0;
  border-top-right-radius: 2.5px;
  border-bottom-left-radius: 2px;
  transform: scaleX(1);
}

[dir=rtl] .product-gallery__zoom {
  left: 0;
  border-top-left-radius: 2.5px;
  border-bottom-right-radius: 2px;
  transform: scaleX(-1);
}

.product-gallery__zoom:focus {
  outline: none;
}

.product-gallery__zoom:hover {
  background: #f2f2f2;
  fill: rgba(0, 0, 0, 0.25);
}

.product-gallery__zoom:active {
  background: #ebebeb;
  fill: rgba(0, 0, 0, 0.25);
}

.product-gallery__carousel {
  margin-top: 16px;
}

.product-gallery__carousel-item {
  cursor: pointer;
  display: block;
  box-shadow: 0 0 0 2px #f2f2f2 inset;
  padding: 12px;
  border-radius: 2px;
}

.product-gallery__carousel-item--active {
  box-shadow: 0 0 0 2px #3366cc inset;
}

/*
// .product__tabs
*/
@keyframes product-tabs-sticky {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes product-tabs-sticky-header {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(54px);
  }
}

@keyframes product-tabs-sticky-header-hidden {
  from {
    transform: translateY(54px);
  }
  to {
    transform: translateY(0);
  }
}

.product-tabs {
  margin-top: 50px;
}

.product-tabs__list {
  height: 68px;
  margin-bottom: -2px;
}

.product-tabs__list-container {
  padding: 0;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.product-tabs__list-container::after, .product-tabs__list-container::before {
  content: '';
  display: block;
  width: 8px;
  flex-shrink: 0;
}

.product-tabs__item {
  font-size: 20px;
  padding: 18px 48px;
  border-bottom: 2px solid transparent;
  color: inherit;
  font-weight: 500;
  border-radius: 3px 3px 0 0;
  transition: border-color .15s, background-color .15s;
}

.product-tabs__item:hover {
  color: inherit;
  background: #f7f7f7;
  border-bottom-color: #d9d9d9;
}

[dir=ltr] .product-tabs__item:first-child {
  margin-left: auto;
}

[dir=rtl] .product-tabs__item:first-child {
  margin-right: auto;
}

[dir=ltr] .product-tabs__item:last-child {
  margin-right: auto;
}

[dir=rtl] .product-tabs__item:last-child {
  margin-left: auto;
}

.product-tabs__item--active {
  transition-duration: 0s;
}

.product-tabs__item--active, .product-tabs__item--active:hover {
  cursor: default;
  border-bottom-color: #3366cc;
  background: transparent;
}

.product-tabs__content {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 80px 90px;
}

.product-tabs__pane {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity .5s;
}

.product-tabs__pane--active {
  overflow: visible;
  height: auto;
  opacity: 1;
}

.product-tabs--layout--sidebar .product-tabs__list {
  height: 60px;
}

.product-tabs--layout--sidebar .product-tabs__item {
  padding: 14px 30px;
}

.product-tabs--layout--sidebar .product-tabs__content {
  padding: 48px 50px;
}

.product-tabs--stuck .product-tabs__list-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 5;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.02);
  animation-name: product-tabs-sticky;
  animation-duration: .3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transition: transform .3s ease-in-out;
}

.product-tabs--stuck .product-tabs__list-body:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  height: 54px;
  background: inherit;
}

.product-tabs--stuck .product-tabs__item {
  padding: 8px 20px;
  font-size: 16px;
}

.product-tabs--stuck.product-tabs--header-stuck .product-tabs__list-body {
  animation-name: product-tabs-sticky-header;
}

.product-tabs--stuck.product-tabs--header-stuck-hidden .product-tabs__list-body {
  animation-name: product-tabs-sticky-header-hidden;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-tabs__content {
    padding: 60px 70px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product-tabs {
    margin-top: 40px;
  }
  .product-tabs__list {
    height: 60px;
  }
  .product-tabs:not(.product-tabs--stuck) .product-tabs__item {
    padding: 14px 30px;
  }
  .product-tabs .product-tabs__content {
    padding: 40px 50px;
  }
}

@media (max-width: 767px) {
  .product-tabs {
    margin-top: 24px;
  }
  .product-tabs__list,
  .product-tabs--layout--sidebar .product-tabs__list {
    height: 52px;
  }
  .product-tabs:not(.product-tabs--stuck) .product-tabs__item {
    padding: 10px 22px;
  }
  .product-tabs .product-tabs__content {
    padding: 24px;
  }
}

/*
// .products-list
*/
.products-list__body {
  display: flex;
  flex-wrap: wrap;
}

.products-list__item {
  display: flex;
}

.products-list__item .product-card {
  width: 100%;
  flex-shrink: 0;
}

.products-list[data-layout^="grid-"] .product-card .product-card__image {
  padding: 18px 18px 20px;
}

.products-list[data-layout^="grid-"] .product-card .product-card__info {
  padding: 0 24px 0;
}

.products-list[data-layout^="grid-"] .product-card .product-card__actions {
  padding: 0 24px 24px;
}

.products-list[data-layout^="grid-"] .product-card .product-card__description {
  display: none;
}

.products-list[data-layout^="grid-"] .product-card .product-card__availability {
  display: none;
}

.products-list[data-layout^="grid-"] .product-card .product-card__features-list {
  display: none;
}

.products-list[data-layout^="grid-"][data-with-features="true"] .product-card .product-card__features-list {
  display: block;
}

.products-list[data-layout="grid-3-sidebar"] .products-list__body {
  margin: -8px -6px;
}

.products-list[data-layout="grid-3-sidebar"] .products-list__item {
  width: calc(33.33333% - 12px);
  margin: 8px 6px;
}

.products-list[data-layout="grid-4-full"] .products-list__body {
  margin: -8px -6px;
}

.products-list[data-layout="grid-4-full"] .products-list__item {
  width: calc(25% - 12px);
  margin: 8px 6px;
}

.products-list[data-layout="grid-3-sidebar"] .product-card .product-card__buttons .btn,
.products-list[data-layout="grid-4-full"] .product-card .product-card__buttons .btn {
  font-size: .875rem;
  height: calc(1.875rem + 2px);
  line-height: 1.25;
  padding: .375rem 1rem;
  font-weight: 500;
}

.products-list[data-layout="grid-3-sidebar"] .product-card .product-card__buttons .btn.btn-svg-icon,
.products-list[data-layout="grid-4-full"] .product-card .product-card__buttons .btn.btn-svg-icon {
  width: calc(1.875rem + 2px);
}

@media (hover: hover) {
  .products-list[data-layout="grid-3-sidebar"] .product-card--hidden-actions .product-card__buttons,
  .products-list[data-layout="grid-4-full"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-3-sidebar"] .product-card--hidden-actions:hover,
  .products-list[data-layout="grid-4-full"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .products-list[data-layout="grid-3-sidebar"] .product-card--hidden-actions:hover .product-card__buttons,
  .products-list[data-layout="grid-4-full"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .products-list[data-layout="grid-3-sidebar"] .product-card--hidden-actions .product-card__buttons,
  .products-list[data-layout="grid-4-full"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-3-sidebar"] .product-card--hidden-actions:hover,
  .products-list[data-layout="grid-4-full"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .products-list[data-layout="grid-3-sidebar"] .product-card--hidden-actions:hover .product-card__buttons,
  .products-list[data-layout="grid-4-full"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 1199px) and (min-width: 420px) {
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card .product-card__image,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card .product-card__image {
    padding: 15px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list, [dir=ltr]
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list, [dir=rtl]
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card .product-card__info,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card .product-card__actions,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card .product-card__buttons .btn,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card .product-card__buttons .btn.btn-svg-icon,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (max-width: 1199px) and (min-width: 420px) and (hover: hover) {
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card--hidden-actions .product-card__buttons,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover .product-card__buttons,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 1199px) and (min-width: 420px) and (-ms-high-contrast: active), (max-width: 1199px) and (min-width: 420px) and (-ms-high-contrast: none) {
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card--hidden-actions .product-card__buttons,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover .product-card__buttons,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 1199px) and (min-width: 375px) {
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card .product-card__image,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card .product-card__image {
    padding: 15px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list, [dir=ltr]
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list, [dir=rtl]
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card .product-card__info,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card .product-card__actions,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn.btn-svg-icon,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (max-width: 1199px) and (min-width: 375px) and (hover: hover) {
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 1199px) and (min-width: 375px) and (-ms-high-contrast: active), (max-width: 1199px) and (min-width: 375px) and (-ms-high-contrast: none) {
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .products-list[data-layout="grid-3-sidebar"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons,
  .products-list[data-layout="grid-4-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

.products-list[data-layout="grid-5-full"] .products-list__body {
  margin: -8px -6px;
}

.products-list[data-layout="grid-5-full"] .products-list__item {
  width: calc(20% - 12px);
  margin: 8px 6px;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .products-list[data-layout="grid-5-full"] .products-list__body {
    margin: -8px -6px;
  }
  .products-list[data-layout="grid-5-full"] .products-list__item {
    width: calc(25% - 12px);
    margin: 8px 6px;
  }
}

@media (min-width: 420px) {
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card .product-card__image {
    padding: 15px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (min-width: 420px) and (hover: hover) {
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (min-width: 420px) and (-ms-high-contrast: active), (min-width: 420px) and (-ms-high-contrast: none) {
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="1"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (min-width: 375px) {
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card .product-card__image {
    padding: 15px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (min-width: 375px) and (hover: hover) {
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (min-width: 375px) and (-ms-high-contrast: active), (min-width: 375px) and (-ms-high-contrast: none) {
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .products-list[data-layout="grid-5-full"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .products-list[data-layout^="grid-"] .products-list__body {
    margin: -8px -6px;
  }
  .products-list[data-layout^="grid-"] .products-list__item {
    width: calc(33.33333% - 12px);
    margin: 8px 6px;
  }
}

@media (max-width: 767px) and (min-width: 420px) {
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="1"] .products-list__body {
    margin: -8px -6px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="1"] .products-list__item {
    width: calc(50% - 12px);
    margin: 8px 6px;
  }
}

@media (max-width: 419px) {
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="1"] .products-list__body {
    margin: -8px -6px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="1"] .products-list__item {
    width: 100%;
    margin: 8px 6px;
  }
}

@media (max-width: 374px) {
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__image {
    padding: 15px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -9px;
    margin-top: 16px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons > * + * {
    margin-top: 6px;
  }
  [dir=ltr] .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons > * + * {
    margin-left: 0;
    margin-right: 4px;
  }
  [dir=rtl] .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons > * + * {
    margin-right: 0;
    margin-left: 4px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__addtocart {
    width: 100%;
  }
}

@media (max-width: 374px) and (hover: hover) {
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -65px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 374px) and (-ms-high-contrast: active), (max-width: 374px) and (-ms-high-contrast: none) {
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -65px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 767px) and (min-width: 420px) {
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .products-list__body {
    margin: -5px -5px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .products-list__item {
    width: calc(50% - 10px);
    margin: 5px 5px;
  }
}

@media (max-width: 419px) and (min-width: 320px) {
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .products-list__body {
    margin: 0px -15px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .products-list__item {
    width: calc(50% - 0px);
    margin: 0px 0px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card::before {
    border-radius: 0;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .products-list__item:nth-child(2n - 1) .product-card {
    width: calc(100% + 1px);
    padding-right: 2px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .products-list__item:nth-child(n + 3) .product-card {
    margin-top: -1px;
  }
  [dir=ltr] .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card__quickview {
    border-top-right-radius: 0;
  }
  [dir=rtl] .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card__quickview {
    border-top-left-radius: 0;
  }
}

@media (max-width: 319px) {
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .products-list__body {
    margin: -8px -6px;
  }
  .products-list[data-layout^="grid-"][data-mobile-grid-columns="2"] .products-list__item {
    width: 100%;
    margin: 8px 6px;
  }
}

.products-list[data-layout="list"] .products-list__body {
  margin: -8px 0px;
}

.products-list[data-layout="list"] .products-list__item {
  width: 100%;
  margin: 8px 0px;
}

.products-list[data-layout="list"] .products-list__item {
  display: block;
}

.products-list[data-layout="list"] .product-card {
  flex-direction: row;
}

.products-list[data-layout="list"] .product-card .product-card__image {
  flex-shrink: 0;
  padding: 24px;
  width: 210px;
}

.products-list[data-layout="list"] .product-card .product-card__info {
  padding: 20px 0;
}

[dir=ltr] .products-list[data-layout="list"] .product-card .product-card__info {
  padding-left: 0;
  padding-right: 20px;
}

[dir=rtl] .products-list[data-layout="list"] .product-card .product-card__info {
  padding-right: 0;
  padding-left: 20px;
}

.products-list[data-layout="list"] .product-card .product-card__name {
  font-size: 16px;
  line-height: 20px;
  flex-grow: 0;
}

.products-list[data-layout="list"] .product-card .product-card__rating {
  margin-top: 4px;
}

.products-list[data-layout="list"] .product-card .product-card__description {
  color: #6c757d;
  font-size: 15px;
  line-height: 22px;
  margin-top: 12px;
}

.products-list[data-layout="list"] .product-card .product-card__actions {
  flex-shrink: 0;
  width: 190px;
  padding: 16px 20px;
}

[dir=ltr] .products-list[data-layout="list"] .product-card .product-card__actions {
  border-left: 1px solid #ebebeb;
}

[dir=rtl] .products-list[data-layout="list"] .product-card .product-card__actions {
  border-right: 1px solid #ebebeb;
}

.products-list[data-layout="list"] .product-card .product-card__prices {
  margin-top: 16px;
  font-size: 18px;
}

.products-list[data-layout="list"] .product-card .product-card__old-price {
  font-size: 14px;
}

.products-list[data-layout="list"] .product-card .product-card__buttons {
  flex-wrap: wrap;
}

.products-list[data-layout="list"] .product-card .product-card__addtocart {
  display: none;
}

.products-list[data-layout="list"] .product-card .product-card__addtocart--list {
  display: block;
}

.products-list[data-layout="list"] .product-card .product-card__addtocart {
  width: 100%;
}

[dir=ltr] .products-list[data-layout="list"] .product-card .product-card__addtocart + * {
  margin-left: 0;
}

[dir=rtl] .products-list[data-layout="list"] .product-card .product-card__addtocart + * {
  margin-right: 0;
}

.products-list[data-layout="list"] .product-card .product-card__addtocart ~ * {
  margin-top: 8px;
}

.products-list[data-layout="list"] .product-card .product-card__addtocart,
.products-list[data-layout="list"] .product-card .product-card__wishlist,
.products-list[data-layout="list"] .product-card .product-card__compare {
  font-size: .875rem;
  height: calc(1.875rem + 2px);
  line-height: 1.25;
  padding: .375rem 1rem;
  font-weight: 500;
}

.products-list[data-layout="list"] .product-card .product-card__addtocart.btn-svg-icon,
.products-list[data-layout="list"] .product-card .product-card__wishlist.btn-svg-icon,
.products-list[data-layout="list"] .product-card .product-card__compare.btn-svg-icon {
  width: calc(1.875rem + 2px);
}

.products-list[data-layout="list"] .product-card .product-card__availability {
  color: #999;
  font-size: 14px;
}

[dir=ltr] .products-list[data-layout="list"] .product-card .product-card__availability {
  padding-right: 10px;
}

[dir=rtl] .products-list[data-layout="list"] .product-card .product-card__availability {
  padding-left: 10px;
}

.products-list[data-layout="list"] .product-card .product-card__features-list {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
}

.products-list[data-layout="list"] .product-card .product-card__features-list li {
  padding: 3px 0;
}

[dir=ltr] .products-list[data-layout="list"] .product-card .product-card__features-list li {
  padding-left: 12px;
  padding-right: 0;
}

[dir=rtl] .products-list[data-layout="list"] .product-card .product-card__features-list li {
  padding-right: 12px;
  padding-left: 0;
}

.products-list[data-layout="list"] .product-card .product-card__features-list li::before {
  top: 8px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .products-list[data-layout="list"] .product-card .product-card__image {
    width: 180px;
  }
  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__image {
    padding-left: 20px;
    padding-right: 20px;
  }
  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__image {
    padding-right: 20px;
    padding-left: 20px;
  }
  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__info {
    padding-left: 4px;
    padding-right: 20px;
  }
  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__info {
    padding-right: 4px;
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .products-list[data-layout="list"] .product-card {
    flex-direction: column;
  }
  .products-list[data-layout="list"] .product-card .product-card__image {
    width: 250px;
    max-width: 100%;
    padding: 20px;
    margin: 0 auto;
  }
  .products-list[data-layout="list"] .product-card .product-card__info {
    border-top: 1px solid #ebebeb;
    padding: 20px;
  }
  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__info {
    padding-left: 20px;
    padding-right: 20px;
  }
  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__info {
    padding-right: 20px;
    padding-left: 20px;
  }
  .products-list[data-layout="list"] .product-card .product-card__actions {
    width: auto;
    border-top: 1px solid #ebebeb;
    padding: 20px;
  }
  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__actions {
    border-left: none;
  }
  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__actions {
    border-right: none;
  }
  .products-list[data-layout="list"] .product-card .product-card__buttons {
    flex-wrap: nowrap;
  }
  .products-list[data-layout="list"] .product-card .product-card__buttons .btn {
    font-size: 1rem;
    height: calc(2.25rem + 2px);
    line-height: 1.5;
    padding: .375rem 1.25rem;
    font-weight: 500;
  }
  .products-list[data-layout="list"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(2.25rem + 2px);
  }
  .products-list[data-layout="list"] .product-card .product-card__addtocart--list {
    width: auto;
  }
  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__addtocart--list {
    margin-right: auto;
  }
  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__addtocart--list {
    margin-left: auto;
  }
}

/*
// .products-view
*/
.products-view__options {
  padding-bottom: 20px;
}

.products-view__pagination {
  padding-top: 32px;
}

/*
// .profile-card
*/
.profile-card__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-card__avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 16px;
}

.profile-card__avatar img {
  border-radius: 50%;
  max-width: 100%;
}

.profile-card__name {
  font-weight: 500;
  line-height: 20px;
}

.profile-card__email {
  font-size: 15px;
  margin-bottom: 24px;
}

/*
// .quickview
*/
.quickview {
  padding: 60px;
  position: relative;
}

.quickview__close {
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  border-radius: 3px;
  border: none;
  fill: #bfbfbf;
  transition: all .2s;
  z-index: 2;
}

[dir=ltr] .quickview__close {
  right: 0;
}

[dir=rtl] .quickview__close {
  left: 0;
}

.quickview__close:focus, .quickview__close:hover {
  fill: #808080;
}

.quickview__close:focus {
  outline: none;
}

@media (min-width: 576px) and (max-width: 1199px) {
  .quickview {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .quickview {
    padding: 20px;
  }
}

/*
// .review
*/
.review {
  display: flex;
}

.review__avatar {
  flex-shrink: 0;
}

[dir=ltr] .review__avatar {
  margin-left: 16px;
  margin-right: 24px;
}

[dir=rtl] .review__avatar {
  margin-right: 16px;
  margin-left: 24px;
}

.review__avatar img {
  width: 70px;
  border-radius: 1000px;
}

.review__author {
  margin-top: -4px;
  font-size: 16px;
  font-weight: 500;
}

.review__rating {
  margin-top: 2px;
}

.review__text {
  font-size: 16px;
  margin-top: 12px;
}

.review__date {
  margin-top: 10px;
  color: #999;
  font-size: 14px;
}

@media (min-width: 576px) and (max-width: 767px) {
  [dir=ltr] .review__avatar {
    margin-right: 18px;
  }
  [dir=rtl] .review__avatar {
    margin-left: 18px;
  }
  .review__avatar img {
    width: 60px;
  }
}

@media (max-width: 575px) {
  .review__avatar {
    display: none;
  }
}

/*
// .reviews-list
*/
.reviews-list__content {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reviews-list__item {
  border-bottom: 1px solid #ebebeb;
  padding-top: 28px;
  padding-bottom: 24px;
}

.reviews-list__item:first-child {
  padding-top: 0;
}

.reviews-list__pagination {
  margin-top: 36px;
}

@media (max-width: 767px) {
  .reviews-list__pagination {
    margin-top: 30px;
  }
}

/*
// .reviews-view
*/
.reviews-view__header {
  margin-bottom: 50px;
}

.reviews-view__form {
  margin-top: 40px;
  border-top: 1px solid #ebebeb;
  padding-top: 60px;
}

@media (max-width: 575px) {
  .reviews-view__header {
    margin-bottom: 32px;
  }
  .reviews-view__form {
    margin-top: 30px;
    padding-top: 50px;
  }
}

/*
// .shop-layout
*/
.shop-layout {
  display: flex;
}

@media (min-width: 992px) {
  .shop-layout__sidebar {
    width: 255px;
    flex-shrink: 0;
  }
  .shop-layout__content {
    width: calc(100% - 285px);
  }
  [dir=ltr] .shop-layout--sidebar--start .shop-layout__sidebar {
    margin-right: 30px;
  }
  [dir=rtl] .shop-layout--sidebar--start .shop-layout__sidebar {
    margin-left: 30px;
  }
  [dir=ltr] .shop-layout--sidebar--end .shop-layout__sidebar {
    margin-left: 30px;
  }
  [dir=rtl] .shop-layout--sidebar--end .shop-layout__sidebar {
    margin-right: 30px;
  }
}

@media (max-width: 991px) {
  .shop-layout {
    flex-wrap: wrap;
  }
  .shop-layout__content,
  .shop-layout__sidebar {
    width: 100%;
  }
  .shop-layout__sidebar {
    order: 1;
  }
  [dir=ltr] .shop-layout--sidebar--start .shop-layout__sidebar, [dir=ltr]
  .shop-layout--sidebar--end .shop-layout__sidebar {
    margin-left: 0;
    margin-right: 0;
  }
  [dir=rtl] .shop-layout--sidebar--start .shop-layout__sidebar, [dir=rtl]
  .shop-layout--sidebar--end .shop-layout__sidebar {
    margin-right: 0;
    margin-left: 0;
  }
}

/*
// .spec
*/
.spec {
  font-size: 15px;
  line-height: 18px;
}

.spec__header {
  margin-bottom: 50px;
}

.spec__section + .spec__section {
  margin-top: 40px;
}

.spec__section-title {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -.03em;
  line-height: 20px;
  margin: 0 0 12px;
}

.spec__row {
  padding: 8px 12px;
  display: flex;
  border-bottom: 1px solid #ebebeb;
}

.spec__row:first-of-type {
  border-top: 1px solid #ebebeb;
}

.spec__name {
  width: 230px;
  flex-shrink: 0;
  color: #999;
}

[dir=ltr] .spec__name {
  padding-right: 30px;
}

[dir=rtl] .spec__name {
  padding-left: 30px;
}

.spec__disclaimer {
  margin-top: 40px;
  font-size: 14px;
  line-height: 22px;
  color: #999;
}

@media (min-width: 576px) and (max-width: 767px) {
  .spec__name {
    width: 170px;
  }
}

@media (max-width: 575px) {
  .spec__header {
    margin-bottom: 30px;
  }
  .spec__row {
    display: block;
    padding: 12px 0;
  }
  .spec__name {
    width: auto;
    margin-bottom: 6px;
  }
}

/*
// .view-options
*/
.view-options {
  display: flex;
  align-items: center;
  font-size: 15px;
}

[dir=ltr] .view-options__legend {
  margin-left: 20px;
}

[dir=rtl] .view-options__legend {
  margin-right: 20px;
}

.view-options__divider {
  flex-grow: 1;
}

[dir=ltr] .view-options__filters-button {
  margin-right: 12px;
}

[dir=rtl] .view-options__filters-button {
  margin-left: 12px;
}

.view-options__control {
  display: flex;
  align-items: center;
}

[dir=ltr] .view-options__control {
  margin-left: 24px;
}

[dir=rtl] .view-options__control {
  margin-right: 24px;
}

.view-options__control label {
  margin-bottom: 0;
}

[dir=ltr] .view-options__control label {
  margin-right: 8px;
}

[dir=rtl] .view-options__control label {
  margin-left: 8px;
}

@media (max-width: 991px) {
  .view-options {
    flex-wrap: wrap;
  }
  [dir=ltr] .view-options__legend {
    margin-left: auto;
  }
  [dir=rtl] .view-options__legend {
    margin-right: auto;
  }
  .view-options__divider {
    width: 100%;
    height: 12px;
  }
  [dir=ltr] .view-options__control {
    margin-left: auto;
  }
  [dir=rtl] .view-options__control {
    margin-right: auto;
  }
  [dir=ltr] .view-options__control + .view-options__control {
    margin-left: 24px;
  }
  [dir=rtl] .view-options__control + .view-options__control {
    margin-right: 24px;
  }
}

@media (max-width: 474px) {
  .view-options__legend {
    width: 100%;
    padding-top: 12px;
  }
  .view-options__divider {
    height: 8px;
  }
  .view-options__control {
    display: block;
  }
  [dir=ltr] .view-options__control {
    margin-left: 0;
    margin-right: 24px;
  }
  [dir=rtl] .view-options__control {
    margin-right: 0;
    margin-left: 24px;
  }
  [dir=ltr] .view-options__control + .view-options__control {
    margin-left: 0;
  }
  [dir=rtl] .view-options__control + .view-options__control {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .view-options--offcanvas--mobile .view-options__filters-button {
    display: none;
  }
}

/*
// .wishlist
*/
.wishlist__product-name {
  color: inherit;
}

.wishlist__product-rating {
  margin-top: 6px;
  display: flex;
}

.wishlist__product-rating-legend {
  font-size: 13px;
  line-height: 1;
  color: #b3b3b3;
  padding-top: 1px;
}

[dir=ltr] .wishlist__product-rating-legend {
  margin-left: 9px;
}

[dir=rtl] .wishlist__product-rating-legend {
  margin-right: 9px;
}

@media (min-width: 768px) {
  .wishlist {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  .wishlist__column {
    padding: 12px 18px;
    border-top: 1px solid #ebebeb;
  }
  [dir=ltr] .wishlist__column:first-child {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .wishlist__column:first-child {
    border-right: 1px solid #ebebeb;
  }
  [dir=ltr] .wishlist__column:last-child {
    border-right: 1px solid #ebebeb;
  }
  [dir=rtl] .wishlist__column:last-child {
    border-left: 1px solid #ebebeb;
  }
  .wishlist__head {
    background: #f7f7f7;
    font-size: 15px;
  }
  .wishlist__head .wishlist__column {
    font-weight: 500;
  }
  [dir=ltr] .wishlist__head .wishlist__column:first-child {
    border-top-left-radius: 2.5px;
  }
  [dir=rtl] .wishlist__head .wishlist__column:first-child {
    border-top-right-radius: 2.5px;
  }
  [dir=ltr] .wishlist__head .wishlist__column:last-child {
    border-top-right-radius: 2.5px;
  }
  [dir=rtl] .wishlist__head .wishlist__column:last-child {
    border-top-left-radius: 2.5px;
  }
  .wishlist__body .wishlist__column {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .wishlist__body .wishlist__row:last-child .wishlist__column {
    border-bottom: 1px solid #ebebeb;
  }
  [dir=ltr] .wishlist__body .wishlist__row:last-child .wishlist__column:first-child {
    border-bottom-left-radius: 2.5px;
  }
  [dir=rtl] .wishlist__body .wishlist__row:last-child .wishlist__column:first-child {
    border-bottom-right-radius: 2.5px;
  }
  [dir=ltr] .wishlist__body .wishlist__row:last-child .wishlist__column:last-child {
    border-bottom-right-radius: 2.5px;
  }
  [dir=rtl] .wishlist__body .wishlist__row:last-child .wishlist__column:last-child {
    border-bottom-left-radius: 2.5px;
  }
  .wishlist__column--image {
    width: 1px;
    text-align: center;
  }
  [dir=ltr] .wishlist__column--image {
    padding-right: 4px;
  }
  [dir=rtl] .wishlist__column--image {
    padding-left: 4px;
  }
  .wishlist__column--image .product-image {
    width: 80px;
  }
  .wishlist__column--stock {
    white-space: nowrap;
    text-align: center;
    width: 1px;
  }
  .wishlist__column--price {
    width: 170px;
  }
  [dir=ltr] .wishlist__column--price {
    text-align: right;
    padding-left: 90px;
  }
  [dir=rtl] .wishlist__column--price {
    text-align: left;
    padding-right: 90px;
  }
  .wishlist__column--tocart {
    white-space: nowrap;
    width: 1px;
  }
  [dir=ltr] .wishlist__column--tocart {
    padding-left: 90px;
    padding-right: 0;
  }
  [dir=rtl] .wishlist__column--tocart {
    padding-right: 90px;
    padding-left: 0;
  }
  .wishlist__column--remove {
    width: 1px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .wishlist__column--price {
    width: 140px;
  }
  [dir=ltr] .wishlist__column--price, [dir=ltr]
  .wishlist__column--tocart {
    padding-left: 30px;
  }
  [dir=rtl] .wishlist__column--price, [dir=rtl]
  .wishlist__column--tocart {
    padding-right: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .wishlist__column--price {
    width: 1px;
  }
  [dir=ltr] .wishlist__column--tocart, [dir=ltr]
  .wishlist__column--price, [dir=ltr]
  .wishlist__column--stock {
    padding-left: 0;
  }
  [dir=rtl] .wishlist__column--tocart, [dir=rtl]
  .wishlist__column--price, [dir=rtl]
  .wishlist__column--stock {
    padding-right: 0;
  }
  [dir=ltr] .wishlist__column--remove {
    padding-left: 8px;
    padding-right: 12px;
  }
  [dir=rtl] .wishlist__column--remove {
    padding-right: 8px;
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .wishlist {
    display: block;
  }
  .wishlist__head {
    display: none;
  }
  .wishlist__body {
    display: block;
  }
  .wishlist__row {
    position: relative;
    width: 100%;
    display: block;
    border: 1px solid #ebebeb;
    border-radius: 2px;
    padding: 20px 20px 24px;
  }
  .wishlist__row + .wishlist__row {
    margin-top: 15px;
  }
  .wishlist__column {
    display: block;
    padding: 0;
  }
  .wishlist__column--image {
    padding: 0 0 12px;
    text-align: center;
  }
  .wishlist__column--image .product-image {
    width: 100%;
    max-width: 160px;
    margin: 0 auto;
  }
  .wishlist__column--product,
  .wishlist__column--stock,
  .wishlist__column--price,
  .wishlist__column--tocart {
    text-align: center;
  }
  .wishlist__column--stock {
    margin-top: 8px;
  }
  .wishlist__column--price {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 700;
  }
  .wishlist__column--tocart {
    margin-top: 24px;
  }
  .wishlist__column--remove {
    position: absolute;
    top: 5px;
  }
  [dir=ltr] .wishlist__column--remove {
    right: 5px;
  }
  [dir=rtl] .wishlist__column--remove {
    left: 5px;
  }
  .wishlist__product-rating {
    justify-content: center;
  }
}

/* ----------------------------------------
// Blog
// ---------------------------------------- */
/*
// .comment
*/
.comment {
  display: flex;
}

.comment__avatar {
  width: 70px;
  flex-shrink: 0;
}

[dir=ltr] .comment__avatar {
  margin-left: 20px;
  margin-right: 24px;
}

[dir=rtl] .comment__avatar {
  margin-right: 20px;
  margin-left: 24px;
}

.comment__avatar img {
  max-width: 100%;
  border-radius: 222px;
}

.comment__content {
  flex-grow: 1;
}

.comment__header {
  margin-top: -4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment__author {
  font-size: 16px;
  font-weight: 500;
}

.comment__author a {
  color: inherit;
  transition: color .2s;
}

.comment__author a:hover {
  color: #3366cc;
}

.comment__reply .btn {
  height: 22px;
  padding: .125rem .375rem;
  display: block;
}

.comment__text {
  font-size: 16px;
  margin-top: 6px;
}

.comment__date {
  margin-top: 10px;
  color: #999;
  font-size: 14px;
}

@media (max-width: 767px) {
  [dir=ltr] .comment__avatar {
    margin-left: 0;
  }
  [dir=rtl] .comment__avatar {
    margin-right: 0;
  }
}

@media (max-width: 575px) and (max-width: 575px) {
  .comment__avatar {
    display: none;
  }
}

/*
// .comments-list
*/
.comments-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comments-list__item + .comments-list__item {
  border-top: 1px solid #ebebeb;
  margin-top: 24px;
  padding-top: 28px;
}

.comments-list--level--0 > .comments-list__item:last-child {
  padding-bottom: 28px;
  border-bottom: 1px solid #ebebeb;
}

.comments-list--level--1 {
  border-top: 1px solid #ebebeb;
  margin-top: 24px;
  padding-top: 28px;
}

[dir=ltr] .comments-list--level--1 > .comments-list__item {
  margin-left: 46px;
}

[dir=rtl] .comments-list--level--1 > .comments-list__item {
  margin-right: 46px;
}

@media (max-width: 767px) {
  .comments-list__item + .comments-list__item {
    margin-top: 20px;
    padding-top: 24px;
  }
  .comments-list--level--1 {
    margin-top: 20px;
    padding-top: 24px;
  }
}

/*
// .post
*/
.post__featured {
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  margin-bottom: 40px;
}

.post__featured img {
  transition: opacity .2s;
  max-width: 100%;
}

.post__featured:hover img {
  opacity: .8;
}

.post__footer {
  margin-top: 60px;
}

.post__tags-share-links {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

[dir=ltr] .post__tags {
  margin-right: 30px;
}

[dir=rtl] .post__tags {
  margin-left: 30px;
}

.post__share-links {
  padding-top: 2px;
}

.post__section {
  margin-top: 70px;
}

.post__section-title {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  font-size: 22px;
}

.post__section-title::after {
  margin-top: 1px;
  content: '';
  display: block;
  height: 2px;
  flex-grow: 1;
  background: #ebebeb;
}

[dir=ltr] .post__section-title::after {
  margin-left: 16px;
}

[dir=rtl] .post__section-title::after {
  margin-right: 16px;
}

@media (max-width: 1199px) {
  .post__section {
    margin-top: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .post__featured {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .post__featured {
    margin-bottom: 24px;
  }
  .post__footer {
    margin-top: 40px;
  }
  .post__tags-share-links {
    flex-direction: column;
    align-items: center;
  }
  [dir=ltr] .post__tags {
    margin-right: 0;
  }
  [dir=rtl] .post__tags {
    margin-left: 0;
  }
  .post__tags .tags__list {
    justify-content: center;
  }
  .post__share-links {
    padding: 20px 0 0;
  }
}

@media (min-width: 992px) {
  .post--layout--full .post__featured {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .post--layout--full .post__featured {
    width: calc(100% + 380px);
  }
  [dir=ltr] .post--layout--full .post__featured {
    left: -190px;
  }
  [dir=rtl] .post--layout--full .post__featured {
    right: -190px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .post--layout--full .post__featured {
    width: calc(100% + 240px);
  }
  [dir=ltr] .post--layout--full .post__featured {
    left: -120px;
  }
  [dir=rtl] .post--layout--full .post__featured {
    right: -120px;
  }
}

/*
// .post-author
*/
.post-author {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 22px;
  display: flex;
}

.post-author__avatar {
  width: 70px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 2px;
}

[dir=ltr] .post-author__avatar {
  margin-right: 18px;
}

[dir=rtl] .post-author__avatar {
  margin-left: 18px;
}

.post-author__avatar img {
  max-width: 100%;
}

.post-author__name {
  margin-top: 1px;
  font-size: 15px;
  font-weight: 500;
}

.post-author__name a {
  color: inherit;
}

.post-author__about {
  margin-top: 4px;
  font-size: 14px;
}

.post__related-posts {
  margin-top: 50px;
}

@media (max-width: 575px) {
  .post-author {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .post-author__avatar {
    width: 90px;
  }
  [dir=ltr] .post-author__avatar {
    margin-right: 0;
  }
  [dir=rtl] .post-author__avatar {
    margin-left: 0;
  }
  .post-author__info {
    margin-top: 16px;
  }
  .post-author__about {
    margin-top: 8px;
  }
}

/*
// .post-header
*/
.post-header__categories {
  display: flex;
}

.post-header__categories a {
  padding: 5px 7px 4px;
  display: block;
  font-size: 11px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  color: #808080;
  background: #ebebeb;
  border-radius: 1.5px;
}

.post-header__meta {
  font-size: 14px;
  letter-spacing: .02em;
  color: #b2b2b2;
  display: flex;
  flex-wrap: wrap;
}

.post-header__meta a {
  color: inherit;
  transition: color .2s;
}

.post-header__meta a:hover {
  color: #3366cc;
}

.post-header__meta-item {
  position: relative;
}

[dir=ltr] .post-header__meta-item {
  margin-right: 20px;
}

[dir=rtl] .post-header__meta-item {
  margin-left: 20px;
}

.post-header__meta-item::after {
  position: absolute;
  display: block;
  content: '';
  background: currentColor;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  top: 8px;
}

[dir=ltr] .post-header__meta-item::after {
  right: -12px;
}

[dir=rtl] .post-header__meta-item::after {
  left: -12px;
}

[dir=ltr] .post-header__meta-item:last-child {
  margin-right: 0;
}

[dir=rtl] .post-header__meta-item:last-child {
  margin-left: 0;
}

.post-header__meta-item:last-child::after {
  display: none;
}

.post-header--layout--classic .post-header__categories {
  margin-bottom: 12px;
}

.post-header--layout--classic .post-header__title {
  line-height: 38px;
  margin-bottom: 8px;
}

.post-header--layout--classic .post-header__meta {
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .post-header--layout--classic .post-header__title {
    margin-bottom: 6px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .post-header--layout--classic .post-header__meta {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .post-header--layout--classic .post-header__meta {
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .post-header--layout--classic .post-header__title {
    font-size: 30px;
    line-height: 32px;
  }
}

@media (max-width: 479px) {
  .post-header--layout--classic .post-header__title {
    font-size: 28px;
    line-height: 30px;
  }
}

.post-header--layout--full {
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0 50px;
}

.post-header--layout--full .post-header__categories {
  justify-content: center;
  margin-bottom: 24px;
}

.post-header--layout--full .post-header__title {
  line-height: 42px;
}

.post-header--layout--full .post-header__meta {
  margin-top: 24px;
  justify-content: center;
}

@media (max-width: 767px) {
  .post-header--layout--full {
    padding: 10px 0 24px;
  }
  .post-header--layout--full .post-header__categories {
    margin-bottom: 16px;
  }
  .post-header--layout--full .post-header__title {
    font-size: 30px;
    line-height: 32px;
  }
  .post-header--layout--full .post-header__meta {
    margin-top: 12px;
  }
}

@media (max-width: 575px) {
  .post-header--layout--full {
    padding-top: 0;
  }
  [dir=ltr] .post-header--layout--full {
    text-align: left;
  }
  [dir=rtl] .post-header--layout--full {
    text-align: right;
  }
  .post-header--layout--full .post-header__categories {
    justify-content: flex-start;
  }
  .post-header--layout--full .post-header__meta {
    margin-top: 8px;
    justify-content: flex-start;
  }
}

/*
// .posts-list
*/
.posts-list__body {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -15px;
}

.posts-list__item {
  margin: 32px 15px;
}

.posts-list--layout--classic .posts-list__item {
  width: 100%;
}

@media (max-width: 767px) {
  .posts-list--layout--classic .posts-list__body {
    margin: 0;
  }
  .posts-list--layout--classic .posts-list__item {
    margin: 0;
  }
  .posts-list--layout--classic .posts-list__item + .posts-list__item {
    margin-top: 36px;
  }
}

@media (max-width: 479px) {
  .posts-list--layout--classic .posts-list__item + .posts-list__item {
    margin-top: 30px;
  }
}

.posts-list--layout--list .posts-list__body {
  margin: -20px 0;
}

.posts-list--layout--list .posts-list__item {
  width: 100%;
  margin: 20px 0;
}

.posts-list--layout--grid2 .posts-list__item {
  width: calc(50% - 30px);
}

@media (max-width: 767px) {
  .posts-list--layout--grid2 .posts-list__body {
    margin: 0;
  }
  .posts-list--layout--grid2 .posts-list__item {
    margin: 0;
    width: 100%;
  }
  .posts-list--layout--grid2 .posts-list__item + .posts-list__item {
    margin-top: 36px;
  }
}

/*
// .posts-view
*/
.posts-view__pagination {
  padding-top: 48px;
}

@media (max-width: 991px) {
  .posts-view__pagination {
    padding-top: 32px;
    padding-bottom: 10px;
  }
}

/*
// .related-posts
*/
.related-posts__list {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.related-posts__item {
  width: calc(50% - 30px);
  margin: 15px;
}

@media (max-width: 479px) {
  .related-posts__item {
    width: calc(100% - 30px);
  }
}

/* ----------------------------------------
// Blocks
// ---------------------------------------- */
/*
// .block
*/
.block {
  margin-bottom: 50px;
}

.block--highlighted {
  padding: 50px 0 60px;
  background: #f9f9f9;
}

@media (max-width: 991px) {
  .block {
    margin-bottom: 30px;
  }
}

/*
// .block-banner
*/
.block-banner__body {
  position: relative;
  display: block;
  height: 170px;
  text-align: center;
  color: inherit;
}

.block-banner__body:hover {
  color: inherit;
}

.block-banner__image {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.block-banner__image--mobile {
  display: none;
}

.block-banner__title {
  padding-top: 28px;
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
}

.block-banner__text {
  padding-top: 8px;
}

.block-banner__button {
  padding-top: 20px;
}

.block-banner__mobile-br {
  display: none;
}

@media (max-width: 767px) {
  .block-banner__body {
    height: 390px;
  }
  .block-banner__image--desktop {
    display: none;
  }
  .block-banner__image--mobile {
    display: block;
  }
  .block-banner__title,
  .block-banner__text,
  .block-banner__button {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
  .block-banner__title {
    padding-top: 48px;
    line-height: 1.1;
  }
  .block-banner__text {
    padding-top: 12px;
  }
  .block-banner__button {
    padding-top: 28px;
  }
  .block-banner__mobile-br {
    display: block;
  }
}

/*
// .block-brands
*/
.block-brands__slider {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
}

.block-brands__item {
  padding: 32px 24px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .block-brands__item {
    padding: 28px 24px;
  }
}

@media (max-width: 767px) {
  .block-brands__item {
    padding: 20px 24px;
  }
}

/*
// .block-categories
*/
.block-categories__list {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}

.block-categories__item {
  margin: 6px;
  width: calc(33.3333% - 12px);
}

@media (min-width: 480px) and (max-width: 767px) {
  .block-categories--layout--classic .block-categories__item {
    width: calc(50% - 12px);
  }
}

@media (max-width: 479px) {
  .block-categories--layout--classic .block-categories__item {
    width: calc(100% - 12px);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .block-categories--layout--compact .block-categories__item {
    width: calc(50% - 12px);
  }
}

@media (max-width: 767px) {
  .block-categories--layout--compact .block-categories__item {
    width: calc(100% - 12px);
  }
}

/*
// .block-features
*/
.block-empty__body {
  text-align: center;
  padding: 18px 0 60px;
}

.block-empty__message {
  font-size: 17px;
}

.block-empty__actions {
  margin-top: 24px;
}

/*
// .block-features
*/
.block-features__list {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  display: flex;
}

.block-features__item {
  flex-basis: 0;
  flex-grow: 1;
  padding: 16px 24px;
  display: flex;
}

.block-features__divider {
  flex-shrink: 0;
}

.block-features__icon {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  fill: #3366cc;
}

[dir=ltr] .block-features__icon {
  margin-right: 20px;
}

[dir=rtl] .block-features__icon {
  margin-left: 20px;
}

.block-features__icon svg {
  display: block;
}

.block-features__content {
  padding-top: 2px;
}

.block-features__title {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
}

.block-features__subtitle {
  font-size: 14px;
}

.block-features--layout--boxed {
  margin-top: -36px;
}

.block-features--layout--boxed .block-features__list {
  border: none;
}

.block-features--layout--boxed .block-features__item {
  flex-direction: column;
  background: #f7f7f7;
  border-radius: 3px;
  text-align: center;
  align-items: center;
  padding: 30px 28px 28px;
}

.block-features--layout--boxed .block-features__divider {
  width: 14px;
}

.block-features--layout--boxed .block-features__icon {
  margin-bottom: 12px;
}

[dir=ltr] .block-features--layout--boxed .block-features__icon {
  margin-right: 0;
}

[dir=rtl] .block-features--layout--boxed .block-features__icon {
  margin-left: 0;
}

@media (min-width: 480px) and (max-width: 991px) {
  .block-features--layout--boxed .block-features__list {
    flex-wrap: wrap;
  }
  .block-features--layout--boxed .block-features__item {
    width: 50%;
  }
  .block-features--layout--boxed .block-features__divider:nth-child(4n) {
    width: 100%;
    height: 14px;
  }
}

@media (max-width: 479px) {
  .block-features--layout--boxed .block-features__list {
    flex-wrap: wrap;
  }
  .block-features--layout--boxed .block-features__item {
    width: 100%;
  }
  .block-features--layout--boxed .block-features__divider {
    width: 100%;
    height: 14px;
  }
}

.block-features--layout--classic {
  margin-top: -20px;
}

.block-features--layout--classic .block-features__divider {
  flex-shrink: 0;
  width: 2px;
  background: #f0f0f0;
}

@media (max-width: 1199px) and (min-width: 992px), (max-width: 767px) and (min-width: 576px) {
  .block-features--layout--classic .block-features__item {
    padding: 12px;
  }
  [dir=ltr] .block-features--layout--classic .block-features__icon {
    margin-right: 16px;
  }
  [dir=rtl] .block-features--layout--classic .block-features__icon {
    margin-left: 16px;
  }
  .block-features--layout--classic .block-features__content {
    padding-top: 0;
  }
}

@media (max-width: 991px) and (min-width: 420px) {
  .block-features--layout--classic .block-features__list {
    flex-wrap: wrap;
  }
  .block-features--layout--classic .block-features__item {
    flex-basis: auto;
    width: calc(50% - 1px);
    flex-shrink: 0;
  }
  .block-features--layout--classic .block-features__divider:nth-child(4n) {
    width: 100%;
    height: 2px;
  }
}

@media (max-width: 575px) and (min-width: 420px) {
  .block-features--layout--classic .block-features__item {
    text-align: center;
    flex-direction: column;
  }
  .block-features--layout--classic .block-features__icon {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  [dir=ltr] .block-features--layout--classic .block-features__icon {
    margin-right: 0;
  }
  [dir=rtl] .block-features--layout--classic .block-features__icon {
    margin-left: 0;
  }
}

@media (max-width: 419px) {
  .block-features--layout--classic .block-features__list {
    flex-wrap: wrap;
  }
  .block-features--layout--classic .block-features__item {
    flex-basis: auto;
    width: 100%;
    flex-shrink: 0;
  }
  [dir=ltr] .block-features--layout--classic .block-features__icon {
    margin-right: 24px;
  }
  [dir=rtl] .block-features--layout--classic .block-features__icon {
    margin-left: 24px;
  }
  .block-features--layout--classic .block-features__divider {
    width: 100%;
    height: 2px;
  }
}

/*
// .block-finder
*/
.block-finder {
  margin-bottom: 50px;
}

.block-finder--layout--full {
  margin-top: 20px;
}

.block-finder__body {
  padding: 0 52px 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 400px;
  background: #f5f5f5 no-repeat bottom center;
  color: #3d464d;
}

.block-finder__header {
  padding: 46px 0 36px;
}

.block-finder__title {
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
}

.block-finder__subtitle {
  margin-top: 2px;
  font-size: 17px;
  line-height: 1.25;
  color: #6c757d;
}

.block-finder__form {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
}

.block-finder__button {
  margin-top: 40px;
  height: 43px;
  padding-left: 44px;
  padding-right: 44px;
  font-size: 17px;
}

.block-finder__select,
.block-finder__select + .select2-container--default {
  width: 100%;
}

.block-finder__select,
.block-finder__select + .select2-container--default .select2-selection--single {
  height: 42px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.09), 0 0 0 1px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='rgba(0, 0, 0, 0.5)' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A") no-repeat;
  background-size: 5px 10px;
}

[dir=ltr] .block-finder__select, [dir=ltr]
.block-finder__select + .select2-container--default .select2-selection--single {
  background-position: right 12px center;
}

[dir=rtl] .block-finder__select, [dir=rtl]
.block-finder__select + .select2-container--default .select2-selection--single {
  background-position: left 12px center;
}

.block-finder__select:focus,
.block-finder__select + .select2-container--default .select2-selection--single:focus {
  outline: none;
}

.block-finder__select,
.block-finder__select + .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #3d464d;
}

[dir=ltr] .block-finder__select, [dir=ltr]
.block-finder__select + .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 13px;
  padding-right: 31px;
  text-align: left;
}

[dir=rtl] .block-finder__select, [dir=rtl]
.block-finder__select + .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-right: 13px;
  padding-left: 31px;
  text-align: right;
}

.block-finder__select:disabled,
.block-finder__select + .select2-container--disabled .select2-selection--single {
  background-color: #fafafa;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  opacity: 0.6;
  cursor: default;
}

.block-finder__select:disabled,
.block-finder__select + .select2-container--disabled .select2-selection--single .select2-selection__rendered {
  color: #6c757d;
}

.block-finder__select {
  transition: background-color .15s, box-shadow .15s, color .15s, opacity .15s;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.block-finder__select::-ms-expand {
  display: none;
}

.block-finder__select + .select2-container--default .select2-selection--single {
  transition: background-color .15s, box-shadow .15s, opacity .15s;
}

.block-finder__select + .select2-container--default .select2-selection--single .select2-selection__rendered {
  transition: color .15s;
}

.block-finder__select + .select2-container--default .select2-selection--single {
  display: flex;
  align-items: center;
}

.block-finder__select + .select2-container--default .select2-selection__arrow {
  display: none;
}

.block-finder--layout--with-departments .block-finder__body {
  margin-top: 15px;
}

@media (min-width: 992px) {
  .block-finder--layout--with-departments .block-finder__body {
    margin-top: 15px;
    height: 395px;
  }
  [dir=ltr] .block-finder--layout--with-departments .block-finder__body {
    margin-left: -15px;
  }
  [dir=rtl] .block-finder--layout--with-departments .block-finder__body {
    margin-right: -15px;
  }
  .block-finder--layout--with-departments .block-finder__select,
  .block-finder--layout--with-departments .block-finder__select + .select2-container--default .select2-selection--single {
    height: 40px;
  }
}

@media (min-width: 1200px) {
  .block-finder--layout--with-departments .block-finder__form {
    margin: -6px;
    width: 728px;
  }
  .block-finder--layout--with-departments .block-finder__form-item {
    width: calc(100% / 4 - 12px);
    margin: 6px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .block-finder--layout--with-departments .block-finder__form {
    margin: -6px;
    width: 424px;
  }
  .block-finder--layout--with-departments .block-finder__form-item {
    width: calc(100% / 2 - 12px);
    margin: 6px;
  }
  .block-finder--layout--with-departments .block-finder__header {
    padding-bottom: 32px;
  }
  .block-finder--layout--with-departments .block-finder__button {
    margin-top: 36px;
  }
}

@media (min-width: 992px) {
  .block-finder--layout--full .block-finder__form {
    margin: -7px;
    width: 816px;
  }
  .block-finder--layout--full .block-finder__form-item {
    width: calc(100% / 4 - 14px);
    margin: 7px;
  }
}

@media (max-width: 991px) {
  .block-finder__header {
    padding-bottom: 28px;
  }
  .block-finder__button {
    margin-top: 32px;
  }
  .block-finder__form {
    margin: -7px;
    width: 428px;
  }
  .block-finder__form-item {
    width: calc(100% / 2 - 14px);
    margin: 7px;
  }
}

@media (max-width: 767px) {
  .block-finder__header {
    padding-top: 40px;
  }
  .block-finder__title {
    font-size: 28px;
    line-height: 30px;
  }
  .block-finder__subtitle {
    margin-top: 8px;
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .block-finder__body {
    height: auto;
  }
  .block-finder__form {
    margin: -7px;
    width: 234px;
  }
  .block-finder__form-item {
    width: calc(100% / 1 - 14px);
    margin: 7px;
  }
  .block-finder__select,
  .block-finder__select + .select2-container--default .select2-selection--single {
    height: 40px;
  }
}

/*
// .block-map
*/
.block-map {
  margin-bottom: 10px;
}

.block-map__body {
  height: 540px;
  position: relative;
}

.block-map__body::before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  pointer-events: none;
}

.block-map__body::after {
  position: absolute;
  content: '';
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
  pointer-events: none;
}

.block-map__body iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1199px) {
  .block-map__body {
    height: 500px;
  }
}

@media (max-width: 991px) {
  .block-map__body {
    height: 440px;
  }
}

@media (max-width: 767px) {
  .block-map__body {
    height: 380px;
  }
}

@media (max-width: 575px) {
  .block-map__body {
    height: 300px;
  }
}

/*
// .block-posts
*/
.block-posts {
  position: relative;
  z-index: 1;
}

@media (min-width: 576px) {
  .block-posts[data-layout="list"] .post-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .block-posts[data-layout="list"] .post-card .post-card__image {
    width: 240px;
    flex-shrink: 0;
  }
  [dir=ltr] .block-posts[data-layout="list"] .post-card .post-card__image {
    margin-right: 22px;
  }
  [dir=rtl] .block-posts[data-layout="list"] .post-card .post-card__image {
    margin-left: 22px;
  }
  .block-posts[data-layout="list"] .post-card .post-card__name {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
    order: -1;
    height: 40px;
    overflow: hidden;
  }
  .block-posts[data-layout="list"] .post-card .post-card__date {
    margin-top: 6px;
  }
  .block-posts[data-layout="list"] .post-card .post-card__category {
    display: none;
  }
  .block-posts[data-layout="list"] .post-card .post-card__content {
    margin-top: 10px;
    font-size: 15px;
    line-height: 22px;
    height: 66px;
    overflow: hidden;
  }
  .block-posts[data-layout="list"] .post-card .post-card__read-more {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .block-posts[data-layout="list"] .post-card .post-card__image {
    width: 210px;
    flex-shrink: 0;
  }
  [dir=ltr] .block-posts[data-layout="list"] .post-card .post-card__image {
    margin-right: 18px;
  }
  [dir=rtl] .block-posts[data-layout="list"] .post-card .post-card__image {
    margin-left: 18px;
  }
  .block-posts[data-layout="list"] .post-card .post-card__name {
    margin-top: 5px;
    font-size: 15px;
    line-height: 19px;
    order: -1;
  }
  .block-posts[data-layout="list"] .post-card .post-card__date {
    margin-top: 4px;
  }
  .block-posts[data-layout="list"] .post-card .post-card__category {
    display: none;
  }
  .block-posts[data-layout="list"] .post-card .post-card__content {
    margin-top: 10px;
    font-size: 15px;
    line-height: 20px;
    height: 60px;
    overflow: hidden;
  }
  .block-posts[data-layout="list"] .post-card .post-card__read-more {
    display: none;
  }
}

@media (max-width: 575px) {
  .block-posts[data-layout="list"] .post-card .post-card__info {
    display: flex;
    flex-wrap: wrap;
  }
  .block-posts[data-layout="list"] .post-card .post-card__image {
    margin-bottom: 14px;
  }
  .block-posts[data-layout="list"] .post-card .post-card__category {
    display: none;
  }
  .block-posts[data-layout="list"] .post-card .post-card__date {
    order: -1;
  }
  .block-posts[data-layout="list"] .post-card .post-card__name {
    width: 100%;
    font-size: 18px;
    margin-top: 4px;
    line-height: 22px;
  }
  .block-posts[data-layout="list"] .post-card .post-card__content {
    width: 100%;
    margin-top: 12px;
  }
  .block-posts[data-layout="list"] .post-card .post-card__read-more {
    width: 100%;
    display: none;
  }
}

.block-posts[data-layout^="grid-"] .post-card .post-card__info {
  display: flex;
  flex-wrap: wrap;
}

.block-posts[data-layout^="grid-"] .post-card .post-card__image {
  margin-bottom: 14px;
}

.block-posts[data-layout^="grid-"] .post-card .post-card__category {
  display: none;
}

.block-posts[data-layout^="grid-"] .post-card .post-card__date {
  order: -1;
}

.block-posts[data-layout^="grid-"] .post-card .post-card__name {
  width: 100%;
  font-size: 18px;
  margin-top: 4px;
  line-height: 22px;
}

.block-posts[data-layout^="grid-"] .post-card .post-card__content {
  width: 100%;
  margin-top: 12px;
}

.block-posts[data-layout^="grid-"] .post-card .post-card__read-more {
  width: 100%;
  display: none;
}

@media (min-width: 460px) and (max-width: 767px) {
  .block-posts[data-layout="grid-3"][data-mobile-columns="2"] .post-card .post-card__image {
    margin-bottom: 10px;
  }
  .block-posts[data-layout="grid-3"][data-mobile-columns="2"] .post-card .post-card__date {
    font-size: 14px;
    margin: -1px 0;
  }
  .block-posts[data-layout="grid-3"][data-mobile-columns="2"] .post-card .post-card__name {
    font-size: 15px;
    margin-top: 4px;
    line-height: 20px;
  }
  .block-posts[data-layout="grid-3"][data-mobile-columns="2"] .post-card .post-card__content {
    margin-top: 6px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 459px) {
  .block-posts[data-layout="grid-3"][data-mobile-columns="2"] .post-card .post-card__image {
    margin-bottom: 7px;
  }
  .block-posts[data-layout="grid-3"][data-mobile-columns="2"] .post-card .post-card__date {
    font-size: 13px;
  }
  .block-posts[data-layout="grid-3"][data-mobile-columns="2"] .post-card .post-card__name {
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
  }
  .block-posts[data-layout="grid-3"][data-mobile-columns="2"] .post-card .post-card__content {
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
  }
}

@media (min-width: 460px) {
  .block-posts[data-layout="grid-4"] .post-card .post-card__image {
    margin-bottom: 10px;
  }
  .block-posts[data-layout="grid-4"] .post-card .post-card__date {
    font-size: 14px;
    margin: -1px 0;
  }
  .block-posts[data-layout="grid-4"] .post-card .post-card__name {
    font-size: 15px;
    margin-top: 4px;
    line-height: 20px;
  }
  .block-posts[data-layout="grid-4"] .post-card .post-card__content {
    margin-top: 6px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 459px) {
  .block-posts[data-layout="grid-4"][data-mobile-columns="2"] .post-card .post-card__image {
    margin-bottom: 7px;
  }
  .block-posts[data-layout="grid-4"][data-mobile-columns="2"] .post-card .post-card__date {
    font-size: 13px;
  }
  .block-posts[data-layout="grid-4"][data-mobile-columns="2"] .post-card .post-card__name {
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
  }
  .block-posts[data-layout="grid-4"][data-mobile-columns="2"] .post-card .post-card__content {
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
  }
}

/*
// .block-product-columns
*/
.block-product-columns__item {
  position: relative;
}

.block-product-columns__item + .block-product-columns__item {
  margin-top: -1px;
}

.block-product-columns__item:hover {
  z-index: 2;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .block-product-columns .product-card .product-card__image {
    width: 110px;
  }
  .block-product-columns .product-card .product-card__rating {
    display: none;
  }
  [dir=ltr] .block-product-columns .product-card .product-card__actions {
    left: 110px;
  }
  [dir=rtl] .block-product-columns .product-card .product-card__actions {
    right: 110px;
  }
}

/*
// .block-products
*/
.block-products__body {
  display: flex;
}

.block-products__featured {
  display: flex;
  flex-direction: column;
  width: 36%;
  flex-shrink: 0;
}

.block-products__featured-item {
  display: flex;
  flex-grow: 1;
}

.block-products__featured-item .product-card {
  width: 100%;
  flex-shrink: 0;
}

.block-products__list {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}

.block-products__list-item {
  display: flex;
  width: calc(33.3333% - 12px);
  margin: 6px;
}

.block-products__list-item .product-card {
  width: 100%;
  flex-shrink: 0;
}

.block-products__list-item .product-card .product-card__image {
  padding: 18px 18px 20px;
}

.block-products__list-item .product-card .product-card__info {
  padding: 0 24px 0;
}

.block-products__list-item .product-card .product-card__actions {
  padding: 0 24px 24px;
}

.block-products__list-item .product-card .product-card__description {
  display: none;
}

.block-products__list-item .product-card .product-card__availability {
  display: none;
}

.block-products__list-item .product-card .product-card__features-list {
  display: none;
}

@media (min-width: 992px) {
  .block-products__featured-item .product-card .product-card__image {
    padding: 18px 18px 20px;
  }
  .block-products__featured-item .product-card .product-card__info {
    padding: 0 24px 0;
  }
  .block-products__featured-item .product-card .product-card__actions {
    padding: 0 24px 24px;
  }
  .block-products__featured-item .product-card .product-card__description {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__availability {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__features-list {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__image {
    padding: 25px;
  }
  .block-products__featured-item .product-card .product-card__info {
    padding: 0 30px 0;
    flex-grow: 0;
  }
  .block-products__featured-item .product-card .product-card__name {
    font-size: 17px;
    line-height: 23px;
  }
  .block-products__featured-item .product-card .product-card__actions {
    padding: 0 30px 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .block-products__featured-item .product-card .product-card__rating {
    margin-top: 7px;
  }
  .block-products__featured-item .product-card .product-card__prices {
    margin-bottom: auto;
    font-size: 20px;
  }
  [dir=ltr] .block-products__featured + .block-products__list {
    margin-left: 6px;
  }
  [dir=rtl] .block-products__featured + .block-products__list {
    margin-right: 6px;
  }
  [dir=ltr] .block-products__list + .block-products__featured {
    margin-left: 12px;
  }
  [dir=rtl] .block-products__list + .block-products__featured {
    margin-right: 12px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .block-products__featured-item {
    display: block;
  }
  .block-products__featured-item .product-card {
    flex-direction: row;
  }
  .block-products__featured-item .product-card .product-card__image {
    width: 120px;
    padding: 16px;
  }
  .block-products__featured-item .product-card .product-card__badges-list {
    top: 10px;
  }
  [dir=ltr] .block-products__featured-item .product-card .product-card__badges-list {
    left: 10px;
  }
  [dir=rtl] .block-products__featured-item .product-card .product-card__badges-list {
    right: 10px;
  }
  .block-products__featured-item .product-card .product-card__badge {
    padding: 3px 5px 2px;
  }
  .block-products__featured-item .product-card .product-card__info {
    padding-top: 14px;
    padding-bottom: 42px;
    width: 100%;
  }
  [dir=ltr] .block-products__featured-item .product-card .product-card__info {
    padding-left: 0;
    padding-right: 30px;
  }
  [dir=rtl] .block-products__featured-item .product-card .product-card__info {
    padding-right: 0;
    padding-left: 30px;
  }
  .block-products__featured-item .product-card .product-card__name {
    overflow: hidden;
    max-height: 38px;
  }
  .block-products__featured-item .product-card .product-card__rating {
    white-space: nowrap;
  }
  .block-products__featured-item .product-card .product-card__description {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__availability {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__buttons {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__actions {
    position: absolute;
    bottom: 18px;
  }
  [dir=ltr] .block-products__featured-item .product-card .product-card__actions {
    left: 120px;
  }
  [dir=rtl] .block-products__featured-item .product-card .product-card__actions {
    right: 120px;
  }
  .block-products__featured-item .product-card .product-card__features-list {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__image {
    width: 216px;
  }
  [dir=ltr] .block-products__featured-item .product-card .product-card__image {
    padding-left: 24px;
    padding-right: 24px;
    margin-right: 16px;
  }
  [dir=rtl] .block-products__featured-item .product-card .product-card__image {
    padding-right: 24px;
    padding-left: 24px;
    margin-left: 16px;
  }
  .block-products__featured-item .product-card .product-card__info {
    padding-top: 20px;
    padding-bottom: 120px;
  }
  .block-products__featured-item .product-card .product-card__name {
    font-size: 17px;
    margin-bottom: 2px;
    flex-grow: 0;
  }
  .block-products__featured-item .product-card .product-card__actions {
    align-items: center;
    bottom: 26px;
  }
  [dir=ltr] .block-products__featured-item .product-card .product-card__actions {
    left: 232px;
  }
  [dir=rtl] .block-products__featured-item .product-card .product-card__actions {
    right: 232px;
  }
  .block-products__featured-item .product-card .product-card__prices {
    margin-top: 0;
    font-size: 20px;
  }
  [dir=ltr] .block-products__featured-item .product-card .product-card__prices {
    margin-right: 24px;
  }
  [dir=rtl] .block-products__featured-item .product-card .product-card__prices {
    margin-left: 24px;
  }
  .block-products__featured-item .product-card .product-card__buttons {
    display: block;
  }
}

@media (max-width: 991px) {
  .block-products__body {
    display: block;
  }
  .block-products__featured {
    width: 100%;
  }
  .block-products__featured + .block-products__list {
    margin-top: 6px;
  }
  .block-products__list + .block-products__featured {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .block-products__list-item {
    width: calc(50% - 12px);
  }
}

@media (max-width: 575px) {
  .block-products__featured-item .product-card .product-card__image {
    padding: 18px 18px 20px;
  }
  .block-products__featured-item .product-card .product-card__info {
    padding: 0 24px 0;
  }
  .block-products__featured-item .product-card .product-card__actions {
    padding: 0 24px 24px;
  }
  .block-products__featured-item .product-card .product-card__description {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__availability {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__features-list {
    display: none;
  }
  .block-products__featured-item .product-card .product-card__buttons .btn {
    font-size: .875rem;
    height: calc(1.875rem + 2px);
    line-height: 1.25;
    padding: .375rem 1rem;
    font-weight: 500;
  }
  .block-products__featured-item .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.875rem + 2px);
  }
}

@media (max-width: 575px) and (hover: hover) {
  .block-products__featured-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products__featured-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .block-products__featured-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 575px) and (-ms-high-contrast: active), (max-width: 575px) and (-ms-high-contrast: none) {
  .block-products__featured-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products__featured-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .block-products__featured-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (min-width: 480px) {
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__image {
    padding: 15px;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__badges-list {
    right: 16px;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (min-width: 480px) and (hover: hover) {
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (min-width: 480px) and (-ms-high-contrast: active), (min-width: 480px) and (-ms-high-contrast: none) {
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 479px) {
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__buttons .btn {
    font-size: .875rem;
    height: calc(1.875rem + 2px);
    line-height: 1.25;
    padding: .375rem 1rem;
    font-weight: 500;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.875rem + 2px);
  }
}

@media (max-width: 479px) and (hover: hover) {
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 479px) and (-ms-high-contrast: active), (max-width: 479px) and (-ms-high-contrast: none) {
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 479px) {
  .block-products:not([data-mobile-grid-columns="2"]) .block-products__list-item {
    width: calc(100% - 12px);
  }
}

@media (min-width: 375px) {
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__image {
    padding: 15px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__badges-list {
    right: 16px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (min-width: 375px) and (hover: hover) {
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (min-width: 375px) and (-ms-high-contrast: active), (min-width: 375px) and (-ms-high-contrast: none) {
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 374px) {
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__image {
    padding: 15px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__badges-list {
    right: 16px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__buttons {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -9px;
    margin-top: 16px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__buttons > * + * {
    margin-top: 6px;
  }
  [dir=ltr] .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__buttons > * + * {
    margin-left: 0;
    margin-right: 4px;
  }
  [dir=rtl] .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__buttons > * + * {
    margin-right: 0;
    margin-left: 4px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card .product-card__addtocart {
    width: 100%;
  }
}

@media (max-width: 374px) and (hover: hover) {
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -65px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 374px) and (-ms-high-contrast: active), (max-width: 374px) and (-ms-high-contrast: none) {
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -65px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 419px) {
  .block-products[data-mobile-grid-columns="2"] .block-products__featured + .block-products__list,
  .block-products[data-mobile-grid-columns="2"] .block-products__list + .block-products__featured {
    margin-top: -1px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list {
    margin: 0;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item {
    margin: 0;
    width: 50%;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item:nth-child(n + 3) {
    margin-top: -1px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__list-item:nth-child(2n) .product-card::before {
    width: calc(100% + 1px);
  }
  [dir=ltr] .block-products[data-mobile-grid-columns="2"] .block-products__list-item:nth-child(2n) .product-card::before {
    left: -1px;
  }
  [dir=rtl] .block-products[data-mobile-grid-columns="2"] .block-products__list-item:nth-child(2n) .product-card::before {
    right: -1px;
  }
  .block-products[data-mobile-grid-columns="2"] .block-products__body {
    margin: 0 -15px;
  }
  .block-products[data-mobile-grid-columns="2"] .product-card::before {
    border-radius: 0;
  }
  [dir=ltr] .block-products[data-mobile-grid-columns="2"] .product-card__quickview {
    border-top-right-radius: 0;
  }
  [dir=rtl] .block-products[data-mobile-grid-columns="2"] .product-card__quickview {
    border-top-left-radius: 0;
  }
}

/*
// .block-products-carousel
*/
.block-products-carousel {
  pointer-events: none;
}

.block-products-carousel .owl-stage {
  margin-bottom: 100px;
  display: flex;
  pointer-events: auto;
}

.block-products-carousel .owl-stage-outer {
  margin-bottom: -100px;
}

.block-products-carousel .owl-item {
  flex-shrink: 0;
  display: flex;
}

.block-products-carousel .block-header {
  pointer-events: auto;
}

.block-products-carousel__slider {
  position: relative;
  z-index: 2;
}

.block-products-carousel__preloader {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 56px;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility 0s .3s;
}

.block-products-carousel__preloader:after {
  display: block;
  content: '';
  position: absolute;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  transition: border-color .1s;
  animation-name: preloader-animation;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.block-products-carousel--loading .block-products-carousel__preloader {
  transition-delay: 0s, 0s;
  opacity: 1;
  visibility: visible;
}

.block-products-carousel__column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .block-products-carousel__column {
    display: block;
  }
}

.block-products-carousel__cell {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  flex-grow: 1;
}

.block-products-carousel__cell + .block-products-carousel__cell {
  margin-top: 14px;
}

.block-products-carousel__cell .product-card {
  width: 100%;
}

.block-products-carousel[data-layout^="grid-"] .product-card .product-card__image {
  padding: 18px 18px 20px;
}

.block-products-carousel[data-layout^="grid-"] .product-card .product-card__info {
  padding: 0 24px 0;
}

.block-products-carousel[data-layout^="grid-"] .product-card .product-card__actions {
  padding: 0 24px 24px;
}

.block-products-carousel[data-layout^="grid-"] .product-card .product-card__description {
  display: none;
}

.block-products-carousel[data-layout^="grid-"] .product-card .product-card__availability {
  display: none;
}

.block-products-carousel[data-layout^="grid-"] .product-card .product-card__features-list {
  display: none;
}

@media (max-width: 374px) {
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__image {
    padding: 15px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -9px;
    margin-top: 16px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons > * + * {
    margin-top: 6px;
  }
  [dir=ltr] .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons > * + * {
    margin-left: 0;
    margin-right: 4px;
  }
  [dir=rtl] .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons > * + * {
    margin-right: 0;
    margin-left: 4px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card .product-card__addtocart {
    width: 100%;
  }
}

@media (max-width: 374px) and (hover: hover) {
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -65px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 374px) and (-ms-high-contrast: active), (max-width: 374px) and (-ms-high-contrast: none) {
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -65px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 419px) {
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .block-products-carousel__slider {
    margin: 0 -16px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .block-products-carousel__cell + .block-products-carousel__cell {
    margin-top: -1px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card::before {
    border-radius: 0;
  }
  [dir=ltr] .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card__quickview {
    border-top-right-radius: 0;
  }
  [dir=rtl] .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .product-card__quickview {
    border-top-left-radius: 0;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .active + .active .product-card::before {
    width: calc(100% + 1px);
  }
  [dir=ltr] .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .active + .active .product-card::before {
    left: -1px;
  }
  [dir=rtl] .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .active + .active .product-card::before {
    right: -1px;
  }
  .block-products-carousel[data-layout^="grid-"][data-mobile-grid-columns="2"] .owl-item:hover {
    z-index: 1;
  }
}

.block-products-carousel[data-layout="grid-4"] .product-card .product-card__buttons .btn {
  font-size: .875rem;
  height: calc(1.875rem + 2px);
  line-height: 1.25;
  padding: .375rem 1rem;
  font-weight: 500;
}

.block-products-carousel[data-layout="grid-4"] .product-card .product-card__buttons .btn.btn-svg-icon {
  width: calc(1.875rem + 2px);
}

@media (hover: hover) {
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -56px;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .block-products-carousel[data-layout="grid-4"] .product-card .product-card__image {
    padding: 15px;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .block-products-carousel[data-layout="grid-4"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .block-products-carousel[data-layout="grid-4"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (min-width: 768px) and (max-width: 1199px) and (hover: hover) {
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) and (-ms-high-contrast: active), (min-width: 768px) and (max-width: 1199px) and (-ms-high-contrast: none) {
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 575px) and (min-width: 375px) {
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card .product-card__image {
    padding: 15px;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (max-width: 575px) and (min-width: 375px) and (hover: hover) {
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 575px) and (min-width: 375px) and (-ms-high-contrast: active), (max-width: 575px) and (min-width: 375px) and (-ms-high-contrast: none) {
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (max-width: 575px) {
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__image {
    padding: 15px;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list {
    right: 16px;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (max-width: 575px) and (hover: hover) {
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (max-width: 575px) and (-ms-high-contrast: active), (max-width: 575px) and (-ms-high-contrast: none) {
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media (min-width: 375px) {
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card .product-card__image,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card .product-card__image {
    padding: 15px;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    top: 16px;
  }
  [dir=ltr] .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list, [dir=ltr]
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    left: 16px;
  }
  [dir=rtl] .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list, [dir=rtl]
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card .product-card__badges-list {
    right: 16px;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card .product-card__info,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card .product-card__info {
    padding: 0 15px 0;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card .product-card__actions,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card .product-card__actions {
    padding: 0 15px 15px;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: 500;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn.btn-svg-icon,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card .product-card__buttons .btn.btn-svg-icon {
    width: calc(1.5rem + 2px);
  }
}

@media (min-width: 375px) and (hover: hover) {
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (min-width: 375px) and (-ms-high-contrast: active), (min-width: 375px) and (-ms-high-contrast: none) {
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4-sm"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons,
  .block-products-carousel[data-layout="grid-5"][data-mobile-grid-columns="2"] .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

.block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__image,
.block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__image {
  padding: 15px;
}

.block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list,
.block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list {
  top: 16px;
}

[dir=ltr] .block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list, [dir=ltr]
.block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list {
  left: 16px;
}

[dir=rtl] .block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list, [dir=rtl]
.block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__badges-list {
  right: 16px;
}

.block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__info,
.block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__info {
  padding: 0 15px 0;
}

.block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__actions,
.block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__actions {
  padding: 0 15px 15px;
}

.block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__buttons .btn,
.block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__buttons .btn {
  font-size: .8125rem;
  height: calc(1.5rem + 2px);
  line-height: 1.25;
  padding: .25rem .5625rem;
  font-weight: 500;
}

.block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__buttons .btn.btn-svg-icon,
.block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card .product-card__buttons .btn.btn-svg-icon {
  width: calc(1.5rem + 2px);
}

@media (hover: hover) {
  .block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions .product-card__buttons,
  .block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover,
  .block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover .product-card__buttons,
  .block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions .product-card__buttons,
  .block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions .product-card__buttons {
    display: none;
  }
  .block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover,
  .block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover {
    position: relative;
    z-index: 3;
    margin-bottom: -50px;
  }
  .block-products-carousel[data-layout="grid-4-sm"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover .product-card__buttons,
  .block-products-carousel[data-layout="grid-5"]:not([data-mobile-grid-columns="2"]) .product-card--hidden-actions:hover .product-card__buttons {
    display: flex;
  }
}

.block-products-carousel[data-layout="horizontal"] .product-card {
  flex-direction: row;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__image {
  width: 120px;
  padding: 16px;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__badges-list {
  top: 10px;
}

[dir=ltr] .block-products-carousel[data-layout="horizontal"] .product-card .product-card__badges-list {
  left: 10px;
}

[dir=rtl] .block-products-carousel[data-layout="horizontal"] .product-card .product-card__badges-list {
  right: 10px;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__badge {
  padding: 3px 5px 2px;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__info {
  padding-top: 14px;
  padding-bottom: 42px;
  width: 100%;
}

[dir=ltr] .block-products-carousel[data-layout="horizontal"] .product-card .product-card__info {
  padding-left: 0;
  padding-right: 30px;
}

[dir=rtl] .block-products-carousel[data-layout="horizontal"] .product-card .product-card__info {
  padding-right: 0;
  padding-left: 30px;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__name {
  overflow: hidden;
  max-height: 38px;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__rating {
  white-space: nowrap;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__description {
  display: none;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__availability {
  display: none;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__buttons {
  display: none;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__actions {
  position: absolute;
  bottom: 18px;
}

[dir=ltr] .block-products-carousel[data-layout="horizontal"] .product-card .product-card__actions {
  left: 120px;
}

[dir=rtl] .block-products-carousel[data-layout="horizontal"] .product-card .product-card__actions {
  right: 120px;
}

.block-products-carousel[data-layout="horizontal"] .product-card .product-card__features-list {
  display: none;
}

/*
// .block-sidebar
*/
.block-sidebar__item + .block-sidebar__item {
  margin-top: 30px;
}

[dir=ltr] .block-sidebar--position--start {
  padding-right: 20px;
}

[dir=rtl] .block-sidebar--position--start {
  padding-left: 20px;
}

@media (max-width: 1199px) {
  [dir=ltr] .block-sidebar--position--start {
    padding-right: 6px;
  }
  [dir=rtl] .block-sidebar--position--start {
    padding-left: 6px;
  }
}

@media (max-width: 991px) {
  [dir=ltr] .block-sidebar--position--start {
    padding-right: 0;
  }
  [dir=rtl] .block-sidebar--position--start {
    padding-left: 0;
  }
}

[dir=ltr] .block-sidebar--position--end {
  padding-left: 20px;
}

[dir=rtl] .block-sidebar--position--end {
  padding-right: 20px;
}

@media (max-width: 1199px) {
  [dir=ltr] .block-sidebar--position--end {
    padding-left: 6px;
  }
  [dir=rtl] .block-sidebar--position--end {
    padding-right: 6px;
  }
}

@media (max-width: 991px) {
  [dir=ltr] .block-sidebar--position--end {
    padding-left: 0;
  }
  [dir=rtl] .block-sidebar--position--end {
    padding-right: 0;
  }
}

.block-sidebar__header {
  height: 55px;
  flex-shrink: 0;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  display: none;
}

.block-sidebar__title {
  padding: 0 20px;
  font-weight: 700;
  flex-grow: 1;
}

.block-sidebar__close {
  border: none;
  background: transparent;
  height: 54px;
  width: 55px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: #999;
  transition: fill .2s;
}

[dir=ltr] .block-sidebar__close {
  border-left: 1px solid #ebebeb;
}

[dir=rtl] .block-sidebar__close {
  border-right: 1px solid #ebebeb;
}

.block-sidebar__close:focus {
  outline: none;
}

.block-sidebar__close:focus, .block-sidebar__close:hover {
  fill: #3d464d;
}

.block-sidebar--offcanvas--always {
  visibility: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: visibility 0s 0.3s;
}

[dir=ltr] .block-sidebar--offcanvas--always {
  left: 0;
}

[dir=rtl] .block-sidebar--offcanvas--always {
  right: 0;
}

.block-sidebar--offcanvas--always .block-sidebar__backdrop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #3d464d;
  opacity: 0;
  transition: opacity 0.3s;
}

[dir=ltr] .block-sidebar--offcanvas--always .block-sidebar__backdrop {
  left: 0;
}

[dir=rtl] .block-sidebar--offcanvas--always .block-sidebar__backdrop {
  right: 0;
}

.block-sidebar--offcanvas--always .block-sidebar__body {
  position: absolute;
  top: 0;
  width: 290px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
}

[dir=ltr] .block-sidebar--offcanvas--always .block-sidebar__body {
  left: 0;
  transform: translateX(-100%) translateX(-30px);
}

[dir=rtl] .block-sidebar--offcanvas--always .block-sidebar__body {
  right: 0;
  transform: translateX(100%) translateX(30px);
}

.block-sidebar--offcanvas--always .block-sidebar__header {
  display: flex;
}

.block-sidebar--offcanvas--always.block-sidebar--open {
  visibility: visible;
  transition-delay: 0s;
}

.block-sidebar--offcanvas--always.block-sidebar--open .block-sidebar__backdrop {
  opacity: .9;
}

.block-sidebar--offcanvas--always.block-sidebar--open .block-sidebar__body {
  transform: translateX(0);
}

@media (max-width: 991px) {
  .block-sidebar--offcanvas--mobile {
    visibility: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: visibility 0s 0.3s;
  }
  [dir=ltr] .block-sidebar--offcanvas--mobile {
    left: 0;
  }
  [dir=rtl] .block-sidebar--offcanvas--mobile {
    right: 0;
  }
  .block-sidebar--offcanvas--mobile .block-sidebar__backdrop {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #3d464d;
    opacity: 0;
    transition: opacity 0.3s;
  }
  [dir=ltr] .block-sidebar--offcanvas--mobile .block-sidebar__backdrop {
    left: 0;
  }
  [dir=rtl] .block-sidebar--offcanvas--mobile .block-sidebar__backdrop {
    right: 0;
  }
  .block-sidebar--offcanvas--mobile .block-sidebar__body {
    position: absolute;
    top: 0;
    width: 290px;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
  }
  [dir=ltr] .block-sidebar--offcanvas--mobile .block-sidebar__body {
    left: 0;
    transform: translateX(-100%) translateX(-30px);
  }
  [dir=rtl] .block-sidebar--offcanvas--mobile .block-sidebar__body {
    right: 0;
    transform: translateX(100%) translateX(30px);
  }
  .block-sidebar--offcanvas--mobile .block-sidebar__header {
    display: flex;
  }
  .block-sidebar--offcanvas--mobile.block-sidebar--open {
    visibility: visible;
    transition-delay: 0s;
  }
  .block-sidebar--offcanvas--mobile.block-sidebar--open .block-sidebar__backdrop {
    opacity: .9;
  }
  .block-sidebar--offcanvas--mobile.block-sidebar--open .block-sidebar__body {
    transform: translateX(0);
  }
}

/*
// .block-slideshow
*/
.block-slideshow {
  margin-bottom: 50px;
  position: relative;
}

.block-slideshow .owl-carousel .owl-dots {
  bottom: 16px;
  position: absolute;
  background: #fff;
  display: flex;
  padding: 4px;
  border-radius: 9px;
}

[dir=ltr] .block-slideshow .owl-carousel .owl-dots {
  left: 50%;
  transform: translateX(-50%);
}

[dir=rtl] .block-slideshow .owl-carousel .owl-dots {
  right: 50%;
  transform: translateX(50%);
}

.block-slideshow .owl-carousel .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #e0e0e0;
}

.block-slideshow .owl-carousel .owl-dot:focus {
  outline: none;
}

.block-slideshow .owl-carousel .owl-dot:hover {
  background: #d1d1d1;
}

.block-slideshow .owl-carousel .owl-dot.active {
  background: #3366cc;
}

[dir=ltr] .block-slideshow .owl-carousel .owl-dot + .owl-dot {
  margin-left: 6px;
}

[dir=rtl] .block-slideshow .owl-carousel .owl-dot + .owl-dot {
  margin-right: 6px;
}

.block-slideshow__slide {
  position: relative;
  display: block;
  color: inherit;
}

.block-slideshow__slide:hover {
  color: inherit;
}

.block-slideshow__slide-image {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.block-slideshow__slide-image--mobile {
  display: none;
}

.block-slideshow__slide-content {
  position: absolute;
  bottom: 46px;
}

[dir=ltr] .block-slideshow__slide-content {
  left: 46px;
}

[dir=rtl] .block-slideshow__slide-content {
  right: 46px;
}

.block-slideshow__slide-title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 12px;
  opacity: 0;
  transition: all 1s .2s;
}

.block-slideshow__slide-text {
  line-height: 1.625;
  opacity: 0;
  transform: translateY(15px);
  transition: all .8s .5s;
}

.block-slideshow__slide-button {
  margin-top: 40px;
  opacity: 0;
  transition: all 1s .4s;
}

.block-slideshow .active .block-slideshow__slide-title,
.block-slideshow .active .block-slideshow__slide-text,
.block-slideshow .active .block-slideshow__slide-button {
  opacity: 1;
  transform: none;
}

.block-slideshow--layout--full {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .block-slideshow--layout--full .block-slideshow__body,
  .block-slideshow--layout--full .block-slideshow__slide {
    height: 440px;
  }
  .block-slideshow--layout--full .block-slideshow__slide-content {
    bottom: 54px;
  }
  [dir=ltr] .block-slideshow--layout--full .block-slideshow__slide-content {
    left: 72px;
  }
  [dir=rtl] .block-slideshow--layout--full .block-slideshow__slide-content {
    right: 72px;
  }
  .block-slideshow--layout--full .block-slideshow__slide-title {
    margin-bottom: 16px;
    line-height: 36px;
  }
  .block-slideshow--layout--full .block-slideshow__slide-button {
    margin-top: 48px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .block-slideshow--layout--full .block-slideshow__slide-image--desktop {
    background-position: -70px top;
  }
  [dir=ltr] .block-slideshow--layout--full .block-slideshow__slide-content {
    left: 56px;
  }
  [dir=rtl] .block-slideshow--layout--full .block-slideshow__slide-content {
    right: 56px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .block-slideshow--layout--full .block-slideshow__slide-image--desktop {
    background-position: -190px top;
  }
  .block-slideshow--layout--full .block-slideshow__slide-content {
    bottom: 56px;
  }
  [dir=ltr] .block-slideshow--layout--full .block-slideshow__slide-content {
    left: 48px;
  }
  [dir=rtl] .block-slideshow--layout--full .block-slideshow__slide-content {
    right: 48px;
  }
  .block-slideshow--layout--full .block-slideshow__slide-title {
    margin-bottom: 8px;
  }
  .block-slideshow--layout--full .block-slideshow__slide-button {
    margin-top: 40px;
  }
}

.block-slideshow--layout--with-departments .block-slideshow__body {
  margin-top: 15px;
  height: 395px;
}

.block-slideshow--layout--with-departments .block-slideshow__slide {
  height: 395px;
}

@media (min-width: 992px) {
  [dir=ltr] .block-slideshow--layout--with-departments .block-slideshow__body {
    margin-left: -15px;
  }
  [dir=rtl] .block-slideshow--layout--with-departments .block-slideshow__body {
    margin-right: -15px;
  }
}

@media (max-width: 991px) {
  .block-slideshow--layout--with-departments .block-slideshow__slide-button .btn {
    font-size: .875rem;
    height: calc(1.875rem + 2px);
    line-height: 1.25;
    padding: .375rem 1rem;
    font-weight: 500;
  }
  .block-slideshow--layout--with-departments .block-slideshow__slide-button .btn.btn-svg-icon {
    width: calc(1.875rem + 2px);
  }
}

@media (max-width: 767px) {
  .block-slideshow__body,
  .block-slideshow__slide {
    height: 395px;
  }
  .block-slideshow__slide-image--mobile {
    background-position: top center;
    display: block;
  }
  .block-slideshow__slide-content {
    top: 30px;
    text-align: center;
  }
  [dir=ltr] .block-slideshow__slide-content {
    left: 5%;
    right: 5%;
  }
  [dir=rtl] .block-slideshow__slide-content {
    right: 5%;
    left: 5%;
  }
  .block-slideshow__slide-title {
    font-size: 26px;
    line-height: 32px;
  }
  .block-slideshow__slide-text {
    display: none;
  }
  .block-slideshow__slide-button {
    margin-top: 24px;
  }
  .block-slideshow__slide-button .btn {
    font-size: .875rem;
    height: calc(1.875rem + 2px);
    line-height: 1.25;
    padding: .375rem 1rem;
    font-weight: 500;
  }
  .block-slideshow__slide-button .btn.btn-svg-icon {
    width: calc(1.875rem + 2px);
  }
}

/* ----------------------------------------
// Widgets
// ---------------------------------------- */
/*
// .widget
*/
.widget__title {
  margin-bottom: 20px;
  font-size: 20px;
}

/*
// .widget-aboutus
*/
.widget-aboutus {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 28px;
}

.widget-aboutus__socials {
  display: block;
  margin-top: 20px;
}

/*
// .widget-categories
*/
.widget-categories {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 28px;
}

.widget-categories__list {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 18px;
  font-size: 16px;
}

.widget-categories__row {
  display: flex;
}

.widget-categories__row a {
  position: relative;
  display: block;
  color: inherit;
  padding-top: 7px;
  padding-bottom: 7px;
  fill: #ccc;
  transition: .15s color;
  flex-grow: 1;
}

[dir=ltr] .widget-categories__row a {
  padding-left: 16px;
  padding-right: 10px;
}

[dir=rtl] .widget-categories__row a {
  padding-right: 16px;
  padding-left: 10px;
}

.widget-categories__row a:hover {
  color: #3366cc;
}

.widget-categories__arrow {
  position: absolute;
  top: calc(50% - 5px);
}

[dir=ltr] .widget-categories__arrow {
  left: 0;
  transform: scaleX(1);
}

[dir=rtl] .widget-categories__arrow {
  right: 0;
  transform: scaleX(-1);
}

.widget-categories__expander {
  margin-top: 1px;
  width: 28px;
  height: 28px;
  position: relative;
  border: none;
  border-radius: 1.5px;
  cursor: pointer;
  padding: 0;
  background: transparent;
  color: #ccc;
  transition: all .2s;
}

[dir=ltr] .widget-categories__expander {
  margin-right: -10px;
}

[dir=rtl] .widget-categories__expander {
  margin-left: -10px;
}

.widget-categories__expander:focus, .widget-categories__expander:hover {
  background: #f2f2f2;
  color: #a6a6a6;
}

.widget-categories__expander:focus {
  outline: none;
}

.widget-categories__expander::before, .widget-categories__expander::after {
  display: block;
  position: absolute;
  content: '';
  background: currentColor;
}

.widget-categories__expander::before {
  width: 8px;
  height: 2px;
  top: calc(50% - 1px);
}

[dir=ltr] .widget-categories__expander::before {
  left: calc(50% - 4px);
}

[dir=rtl] .widget-categories__expander::before {
  right: calc(50% - 4px);
}

.widget-categories__expander::after {
  width: 2px;
  height: 8px;
  top: calc(50% - 4px);
  transition: transform 0.2s;
}

[dir=ltr] .widget-categories__expander::after {
  left: calc(50% - 1px);
}

[dir=rtl] .widget-categories__expander::after {
  right: calc(50% - 1px);
}

.widget-categories__subs {
  overflow: hidden;
  height: 0;
  opacity: 0;
  visibility: hidden;
  font-size: 15px;
  line-height: 26px;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.widget-categories__subs ul {
  list-style: none;
  padding: 6px 0 8px 0;
  margin: 0;
}

.widget-categories__subs li {
  position: relative;
}

[dir=ltr] .widget-categories__subs li {
  padding-left: 32px;
}

[dir=rtl] .widget-categories__subs li {
  padding-right: 32px;
}

.widget-categories__subs li::before {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  width: 6px;
  height: 6px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

[dir=ltr] .widget-categories__subs li::before {
  left: 16px;
}

[dir=rtl] .widget-categories__subs li::before {
  right: 16px;
}

.widget-categories__subs a {
  color: inherit;
  transition: .15s color;
}

.widget-categories__subs a:hover {
  color: #3366cc;
}

.widget-categories__item--open .widget-categories__expander::after {
  transform: rotateZ(90deg);
}

.widget-categories__item--open .widget-categories__subs {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
}

.widget-categories--location--shop {
  padding: 20px;
}

/*
// .widget-comments
*/
.widget-comments {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 28px;
}

.widget-comments a {
  color: inherit;
  transition: color .2s;
}

.widget-comments a:hover {
  color: #3366cc;
}

.widget-comments__list {
  list-style: none;
  margin: 0;
  padding: 8px 0 4px;
}

.widget-comments__item + .widget-comments__item {
  margin-top: 26px;
}

.widget-comments__author {
  font-size: 15px;
  line-height: 18px;
}

.widget-comments__author a {
  border-bottom: 2px solid #ebebeb;
  transition: border .2s;
}

.widget-comments__author a:hover {
  border-color: rgba(51, 102, 204, 0.8);
}

.widget-comments__content {
  margin-top: 12px;
  font-size: 15px;
  line-height: 20px;
}

.widget-comments__meta {
  margin-top: 4px;
  font-size: 13px;
  color: #999;
  display: flex;
  white-space: nowrap;
}

.widget-comments__date {
  flex-shrink: 0;
}

.widget-comments__name {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

[dir=ltr] .widget-comments__name {
  padding-left: 20px;
}

[dir=rtl] .widget-comments__name {
  padding-right: 20px;
}

.widget-comments__name::before {
  position: absolute;
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 2px;
  top: 8px;
}

[dir=ltr] .widget-comments__name::before {
  left: 8px;
}

[dir=rtl] .widget-comments__name::before {
  right: 8px;
}

/*
// .widget-filters
*/
.widget-filters {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 20px;
}

.widget-filters__item {
  padding: 8px 20px;
  margin: 0 -20px;
  border-bottom: 2px solid #f0f0f0;
}

.widget-filters__item:first-child {
  border-top: 2px solid #f0f0f0;
}

.widget-filters__actions {
  padding-top: 24px;
  padding-bottom: 16px;
}

[dir=ltr] .widget-filters__actions > .btn + .btn {
  margin-left: 8px;
}

[dir=rtl] .widget-filters__actions > .btn + .btn {
  margin-right: 8px;
}

.widget-filters--offcanvas--always {
  border: none;
  padding-top: 0;
}

.widget-filters--offcanvas--always .widget-filters__title {
  display: none;
}

.widget-filters--offcanvas--always .widget-filters__item:first-child {
  border-top: none;
}

.widget-filters--offcanvas--always .widget-filters__item {
  border-width: 1px;
}

@media (max-width: 991px) {
  .widget-filters--offcanvas--mobile {
    border: none;
    padding-top: 0;
  }
  .widget-filters--offcanvas--mobile .widget-filters__title {
    display: none;
  }
  .widget-filters--offcanvas--mobile .widget-filters__item:first-child {
    border-top: none;
  }
  .widget-filters--offcanvas--mobile .widget-filters__item {
    border-width: 1px;
  }
}

/*
// .widget-newsletter
*/
.widget-newsletter {
  background: #f5f5f5;
  border-radius: 2px;
  padding: 32px 30px 36px;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
}

.widget-newsletter__title {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 24px;
}

.widget-newsletter__text {
  margin-bottom: 22px;
}

.widget-newsletter__form {
  display: flex;
  flex-direction: column;
}

/*
// .widget-posts
*/
.widget-posts {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 28px;
}

.widget-posts__list {
  padding-top: 10px;
}

.widget-posts__item {
  display: flex;
}

.widget-posts__item + .widget-posts__item {
  margin-top: 22px;
}

.widget-posts__image {
  width: 90px;
  flex-shrink: 0;
  border-radius: 1.5px;
  overflow: hidden;
  align-self: self-start;
  position: relative;
}

[dir=ltr] .widget-posts__image {
  margin-right: 16px;
}

[dir=rtl] .widget-posts__image {
  margin-left: 16px;
}

.widget-posts__image ::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity .6s;
  z-index: 1;
}

.widget-posts__image img {
  max-width: 100%;
  display: block;
}

.widget-posts__image:hover ::before {
  opacity: 1;
}

.widget-posts__info {
  margin-top: -2px;
}

.widget-posts__name {
  font-size: 15px;
  line-height: 18px;
}

.widget-posts__name a {
  color: inherit;
  transition: color .2s;
}

.widget-posts__name a:hover {
  color: #3366cc;
}

.widget-posts__date {
  margin-top: 2px;
  font-size: 13px;
  color: #999;
}

/*
// .widget-products
*/
.widget-products {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 20px;
}

.widget-products__list {
  padding-top: 8px;
}

.widget-products__item {
  display: flex;
}

.widget-products__item + .widget-products__item {
  margin-top: 18px;
}

.widget-products__image {
  width: 50px;
  flex-shrink: 0;
}

[dir=ltr] .widget-products__image {
  margin-right: 12px;
}

[dir=rtl] .widget-products__image {
  margin-left: 12px;
}

.widget-products__image img {
  max-width: 100%;
}

.widget-products__name {
  margin-top: -2px;
  font-size: 14px;
  line-height: 17px;
}

.widget-products__name a {
  color: inherit;
  transition: .15s;
}

.widget-products__name a:hover {
  color: #3366cc;
}

.widget-products__prices {
  padding-top: 2px;
  font-size: 14px;
  font-weight: 700;
  color: #3d464d;
}

.widget-products__new-price {
  color: #ff2626;
}

.widget-products__old-price {
  color: #b3b3b3;
  font-weight: 400;
  font-size: 12px;
  text-decoration: line-through;
}

/*
// .widget-search
*/
.widget-search {
  height: 48px;
}

.widget-search__body {
  position: relative;
  height: 100%;
}

.widget-search__input {
  box-shadow: 0 0 0 2px #ededed inset;
  transition: box-shadow .2s;
  border-radius: 2px;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: inherit;
  appearance: none;
}

[dir=ltr] .widget-search__input {
  padding-left: 18px;
  padding-right: 48px;
}

[dir=rtl] .widget-search__input {
  padding-right: 18px;
  padding-left: 48px;
}

.widget-search__input:hover {
  box-shadow: 0 0 0 2px #d9d9d9 inset;
}

.widget-search__input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #3366cc inset;
}

.widget-search__input::placeholder {
  color: #999;
}

.widget-search__button {
  height: 48px;
  width: 48px;
  cursor: pointer;
  position: absolute;
  top: 0;
  background: transparent;
  border: none;
  padding: 0;
  fill: #ccc;
  transition: fill .2s;
}

[dir=ltr] .widget-search__button {
  right: 0;
}

[dir=rtl] .widget-search__button {
  left: 0;
}

.widget-search__button svg {
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
}

.widget-search__button:focus {
  outline: none;
}

.widget-search__button:focus, .widget-search__button:hover {
  fill: #3366cc;
}

/*
// .widget-tags
*/
.widget-tags {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 28px;
}
